import { Modal, Row } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { turnModalOff } from 'store/app/actions';
import { WindowType } from 'store/app/enums';
import { getModalData, getModalOpen } from 'store/app/selectors';
import styled from 'styled-components';

const ModalWindowContainerWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px !important;
    min-width: fit-content;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-header {
    margin-top: 25px;
  }
`;

export const ModalWindowContainer: React.FC = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const modalOpen = useSelector(getModalOpen);

  let modalStyles = {};

  if (modalData?.type === WindowType.VIDEO) {
    modalStyles = { content: { backgroundColor: 'transparent', padding: 0 } };
  }

  return (
    <ModalWindowContainerWrapper
      title={
        modalData?.type === WindowType.VIDEO ? null : (
          <>
            {modalData?.icon && (
              <Row justify={'center'} style={{ marginBottom: 15 }}>
                {modalData?.icon}
              </Row>
            )}{' '}
            <Row justify={'center'}>{modalData?.title}</Row>
          </>
        )
      }
      centered
      open={modalOpen}
      onCancel={() => dispatch(turnModalOff())}
      footer={false}
      styles={modalStyles}
      style={modalData?.type === WindowType.VIDEO ? { minWidth: '80vw' } : {}}
    >
      {modalData?.component}
    </ModalWindowContainerWrapper>
  );
};
