import { Currency } from 'store/api/enums';
import Features from 'store/billing/models/features.json';

//to be retrieved from API
export const calcCurrencyAmount = (
  selectedCurrency: Currency,
  amount,
  withSymbol = true
) => {
  switch (selectedCurrency) {
    case Currency.EUR:
      return `${withSymbol ? 'Є' : ''} ${amount * 1}`;
      break;
    case Currency.AED:
      return `${withSymbol ? 'د.إ' : ''} ${(amount * 0.83).toFixed(2)}`;
      break;
    case Currency.USD:
      return `${withSymbol ? '$' : ''} ${(amount * 1.04).toFixed(2)}`;
      break;
    default:
      break;
  }
};

export const getPriceByCurrency = (
  selectedCurrency: Currency,
  featureId,
  planType,
  withSymbol = true
) => {
  if (planType?.toLowerCase() != 'free') {
    const feature = Features.find((feature) => feature.featureId == featureId);
    const featurePlan = feature ? feature[planType] : null;
    let featurePrice = '';
    switch (selectedCurrency) {
      case Currency.EUR:
        featurePrice = featurePlan[Currency.EUR.toString()];
        return featurePrice != 'n/a'
          ? `${withSymbol ? 'Є' : ''} ${featurePrice}`
          : featurePrice;
        break;
      case Currency.AED:
        featurePrice = featurePlan[Currency.AED.toString()];
        return featurePrice != 'n/a'
          ? `${withSymbol ? 'د.إ' : ''} ${featurePrice}`
          : featurePrice;
        break;
      case Currency.USD:
        featurePrice = featurePlan[Currency.USD.toString()];
        return featurePrice != 'n/a'
          ? `${withSymbol ? '$' : ''} ${featurePrice}`
          : featurePrice;
        break;
      default:
        break;
    }
  }
};

export const calcPriceCurrency = (
  selectedCurrency: Currency,
  withSymbol = true
) => {
  switch (selectedCurrency) {
    case Currency.EUR:
      return `${withSymbol ? 'Є' : ''} 29`;
      break;
    case Currency.AED:
      return `${withSymbol ? 'د.إ' : ''} 25`;
      break;
    case Currency.USD:
      return `${withSymbol ? '$' : ''} 30`;
      break;
    default:
      break;
  }
};

export const getCurrencySymbol = (
  selectedCurrency: Currency
) => {
  switch (selectedCurrency) {
    case Currency.EUR:
      return 'Є';
      break;
    case Currency.AED:
      return 'د.إ';
      break;
    case Currency.USD:
      return '$';
      break;
    default:
      break;
  }
};
