// import React, { Suspense } from 'react';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
// @ts-ignore
import favicon from '../shared/assets/favicon.ico';
import routes from './routes';

import { NotFoundPage } from 'pages/not-found';

import { Layout } from 'core';
import { Col, ConfigProvider, Row } from 'antd';
import './assets/styles/index.scss';
import { LoginPage } from 'pages/login';
import { SignUpPage, SignupSuccessPage } from 'pages/sign-up';
import { ValidateUser } from 'pages/sign-up';
import { ResetPasswordPage } from 'pages/forgot-password';
import { TermsOfUse } from 'pages/terms-of-use';
import { PrivacyPolicy } from 'pages/privacy-policy';
import { PrivateRoute } from 'containers/PrivateRoute';
import { HomePage } from 'pages/home';
import { AddReturnPage } from 'pages/returns';
import { MessageBoxContainer } from 'containers/MessageBox';
import { UsersPage } from 'pages/users';
import { AccountPage } from 'pages/account';
import { ModalWindowContainer } from 'containers/ModalWindowContainer';
import { TempRedirector } from 'pages/temp-redirector';
import { PasswordEmailSuccessPage } from 'pages/forgot-password/email-success';
import { SignUpSSOPage } from 'pages/sign-up/sign-up-sso';
import { SidePanel } from 'containers/SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'store/auth/selectors';
import { VatReportPage } from 'pages/vat';
import { getMenuSize } from 'store/app/selectors';
import { MenuSize } from 'store/app/enums';
import { BillingPage } from 'pages/billing';
import { VIESPage } from 'pages/vies';
import { SupportPage } from 'pages/support';
import { OrganisationsPage } from 'pages/organisations';
import { OrganisationPage } from 'pages/organisations/organisation';
import { ReportingPage } from 'pages/reporting';
import { Header } from 'containers/Header';
import { getCurrentPage } from 'utils/getCurrentPage';
import { setCurrentPage } from 'store/app/actions';
import { DrawerContainer } from 'containers/DrawerContainer';
import { PicPage } from 'pages/account/pic-page';
import { CustomersSuppliersPage } from 'pages/vies/customers-suppliers';
import { ContactPage } from 'pages/vies/contact';
import { WhatsNewPage } from 'pages/whats-new';
import { PricingPage } from 'pages/billing/pricing-page';
import { CheckoutPage } from 'pages/billing/checkout-page';

const App: React.FC = () => {
  const { t } = useTranslation();
  const loggedUser = useSelector(getAuthUser);
  const menuSize = useSelector(getMenuSize);
  const location = useLocation();
  const dispatch = useDispatch();
  const hideHeader =
    location.pathname.includes(routes.login) ||
    location.pathname.includes(routes.signUp.root) ||
    location.pathname.includes(routes.resetPassword.root);

  if (location.pathname) {
    const currentPage = getCurrentPage(location.pathname);
    if (currentPage)
      dispatch(setCurrentPage({ route: location.pathname, name: currentPage }));
  }
  return (
    <div style={{ margin: '-8px' }}>
      <Helmet
        defaultTitle={t('common.site-title')}
        titleTemplate="%s"
        link={[{ rel: 'icon', type: 'image/png', href: favicon }]}
      />
      <ConfigProvider
        theme={{
          components: {
            // Old ones
            Tabs: {
              //cardBg: '#F3F7F8',
              itemSelectedColor: '#042A2A',
              itemColor: '#879494',
              itemHoverColor: '#042A2A',
              horizontalItemPaddingLG: '20px 0',
              horizontalMargin: '0px ',
            },
            Checkbox: {
              colorText: '#879494',
              fontFamily: 'Inter',
              fontSize: 12,
              fontWeightStrong: 400,
            },
            Table: {
              headerBg: 'white',
              rowExpandedBg: '#F3F7F8',
            },
          },
          token: {
            colorPrimary: '#41aaaf',
            fontFamily: 'Inter',
            fontSize: 14,
            colorTextPlaceholder: '#9CA7B0',
            colorLink: '#5e87b0',
            colorLinkHover: '#406182',
            colorBorder: '#cccccc',
            borderRadius: 4,
            colorError: '#b94a48',
            colorTextBase: '#2E2E2E',
            linkHoverDecoration: 'underline',
            colorBgLayout: '#EAF6F7',
          },
        }}
      >
        <MessageBoxContainer />
        <ModalWindowContainer />
        <DrawerContainer />
        <div style={{ display: 'flex' }}>
          {loggedUser ? (
            <div
              style={
                menuSize === MenuSize.WIDE ? { width: 212 } : { width: 75 }
              }
            >
              <SidePanel menuSize={menuSize} />
            </div>
          ) : (
            <></>
          )}
          <div>
            <Layout menuSize={menuSize}>
              {loggedUser && (
                <Row>
                  <Header menuSize={menuSize} />
                </Row>
              )}
              <div className={!hideHeader ? 'top-pad' : ''}>
                <Switch>
                  <Route exact path={routes.login} component={LoginPage} />
                  <Route
                    exact
                    path={routes.tempRedirector}
                    component={TempRedirector}
                  />
                  <Route
                    exact
                    path={routes.signUp.signupForm}
                    component={SignUpPage}
                  />
                  <Route
                    exact
                    path={routes.signUp.validateUser}
                    component={ValidateUser}
                  />
                  <Route
                    exact
                    path={routes.signUp.signupSuccess}
                    component={SignupSuccessPage}
                  />
                  <Route
                    exact
                    path={routes.signUp.signupSSO}
                    component={SignUpSSOPage}
                  />
                  <Route
                    exact
                    path={routes.resetPassword.request}
                    component={ResetPasswordPage}
                  />
                  <Route
                    exact
                    path={routes.resetPassword.confirm}
                    component={ValidateUser}
                  />
                  <Route
                    exact
                    path={routes.resetPassword.emailSent}
                    component={PasswordEmailSuccessPage}
                  />
                  <Route
                    exact
                    path={routes.termsOfUse}
                    component={TermsOfUse}
                  />
                  <Route
                    exact
                    path={routes.privacyPolicy}
                    component={PrivacyPolicy}
                  />
                  <PrivateRoute exact path={routes.main} component={HomePage} />
                  <PrivateRoute
                    exact
                    path={routes.returns.add}
                    component={AddReturnPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.returns.edit}
                    component={AddReturnPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.organisations.all}
                    component={OrganisationsPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.organisations.organisation}
                    component={OrganisationPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.users}
                    component={UsersPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.account.root}
                    component={AccountPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.account.details}
                    component={AccountPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.account.error}
                    component={AccountPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.billing.root}
                    component={BillingPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.pricing.root}
                    component={PricingPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.pricing.checkout}
                    component={CheckoutPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.reporting}
                    component={ReportingPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.vies.qualifiedEntities}
                    component={VIESPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.vies.customersAndSuppliers}
                    component={CustomersSuppliersPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.vies.contact}
                    component={ContactPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.support}
                    component={SupportPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.whatsNew}
                    component={WhatsNewPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.vat.viewReportLink}
                    component={VatReportPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.vat.report}
                    component={VatReportPage}
                  />
                  <PrivateRoute
                    exact
                    path={routes.pictureTest}
                    component={PicPage}
                  />

                  <Route path={routes.notFound} component={NotFoundPage} />

                  {/* <Redirect from={routes.home} to={routes.main} /> */}

                  <Redirect from={routes.vat.root} to={routes.main} />
                  <Redirect
                    from={routes.signUp.root}
                    to={routes.signUp.signupForm}
                  />
                  <Redirect to={routes.notFound} />
                </Switch>
              </div>
            </Layout>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default App;
