import * as React from 'react';
import { VatReportData } from 'store/vat/models';
import { TaxReportSubsectionDataResponse } from 'store/vat/models/vatReportData';

interface MaltaFormProps {
  data?: VatReportData;
  renderValueCell: (boxNumber: string) => void;
  finalTotals: boolean;
}

export const UAEForm: React.FC<MaltaFormProps> = ({
  data,
  renderValueCell,
  finalTotals,
}) => {
  React.useEffect(() => {
    const selectedSubsections: TaxReportSubsectionDataResponse[] | undefined =
      !finalTotals ? data?.subsections : data?.adjustedSubsections;
    if (data === undefined || data === null) return;

    const currencySignSpans = document.querySelectorAll('.currency-sign');
    currencySignSpans.forEach((currencySignSpan) => {
      if (data.currencySymbol !== null) {
        currencySignSpan.textContent = data.currencySymbol;
      }
    });

    data?.sections.forEach((section, i) => {
      const sectionNameCell = document.querySelector(`#section10${i}-name`);
      if (sectionNameCell) {
        sectionNameCell.textContent = section.name;
      }
    });
    selectedSubsections?.forEach((subsection) => {
      const boxNumber = subsection.code;

      const subsectionNameCell = document.getElementById(
        `subsection${boxNumber}-name`
      );
      const subsectionBoxNameCell = document.getElementById(
        `subsection${boxNumber}-box-name`
      );

      const subsectionVatCell = document.getElementById(
        `subsection${boxNumber}-vat`
      );
      const subsectionValueSpan = document.getElementById(
        `subsection${boxNumber}-value-span`
      );

      if (
        subsectionBoxNameCell !== null &&
        //subsectionValueCell !== null &&
        subsectionNameCell !== null &&
        subsectionValueSpan !== null
      ) {
        subsectionNameCell.textContent = subsection.name;
        subsectionBoxNameCell.textContent = subsection.code;
        const textContent: any = subsection.net;
        subsectionValueSpan.textContent = textContent.toFixed(2);
      }
      if (
        subsection.tax !== null &&
        subsectionVatCell !== null &&
        document.getElementById(`subsection${boxNumber}-vat`) !== null
      ) {
        subsectionVatCell.textContent = subsection.tax.toFixed(2);
      }
    });
  }, [data, finalTotals]);
  return (
    <>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section100-name">VAT on Sales and all other Outputs</th>
            <th></th>
            <th style={{ width: '130px', textAlign: 'center' }}>
              Amount - <span className="currency-sign"></span>
            </th>
            <th style={{ width: '130px', textAlign: 'center' }}>
              VAT Amount - <span className="currency-sign"></span>
            </th>
            <th style={{ width: '130px', textAlign: 'center' }}>
              Adjustments - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection1a-name">Standard rated supplies in Abu Dhabi</td>
            <td id="subsection1a-box-name" className="box">
              1a
            </td>
            <td
              id="subsection1a-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1a')}
            </td>
            <td id="subsection1a-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1a-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1b-name">Standard rated supplies in Dubai</td>
            <td id="subsection1b-box-name" className="box">
              1b
            </td>
            <td
              id="subsection1b-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1b')}
            </td>
            <td id="subsection1b-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1b-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1c-name">Standard rated supplies in Sharjah</td>
            <td id="subsection1c-box-name" className="box">
              1c
            </td>
            <td
              id="subsection1c-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1c')}
            </td>
            <td id="subsection1c-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1c-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1d-name">Standard rated supplies in Ajman</td>
            <td id="subsection1d-box-name" className="box">
              1d
            </td>
            <td
              id="subsection1d-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1d')}
            </td>
            <td id="subsection1d-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1d-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1e-name">
              Standard rated supplies in Umm Al Quwain
            </td>
            <td id="subsection1e-box-name" className="box">
              1e
            </td>
            <td
              id="subsection1e-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1e')}
            </td>
            <td id="subsection1e-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1e-adjustments"
              className="rightalign notavailable"
              tabIndex={11}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1f-name">
              Standard rated supplies in Ras Al Khaimah
            </td>
            <td id="subsection1f-box-name" className="box">
              1f
            </td>
            <td
              id="subsection1f-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1f')}
            </td>
            <td id="subsection1f-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1f-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1g-name">Standard rated supplies in Fujairah</td>
            <td id="subsection1g-box-name" className="box">
              1g
            </td>
            <td
              id="subsection1g-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1g')}
            </td>
            <td id="subsection1g-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection1g-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection2-name">
              Tax Refunds provided to Tourists under the Tax Refunds for
              Tourists Scheme
            </td>
            <td id="subsection2-box-name" className="box">
              2
            </td>
            <td
              id="subsection2-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('2')}
            </td>
            <td id="subsection2-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td className="nbr nbb"></td>
          </tr>
          <tr>
            <td id="subsection3-name">
              Supplies subject to the reverse charge provisions
            </td>
            <td id="subsection3-box-name" className="box">
              3
            </td>
            <td
              id="subsection3-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('3')}
            </td>
            <td id="subsection3-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td className="nbr nbb"></td>
          </tr>
          <tr>
            <td id="subsection4-name">Zero rated supplies</td>
            <td id="subsection4-box-name" className="box">
              4
            </td>
            <td
              id="subsection4-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('4')}
            </td>
            <td className="nbb nbr"></td>
            <td className="nbr nbb"></td>
          </tr>
          <tr>
            <td id="subsection5-name">Exempt supplies</td>
            <td id="subsection5-box-name" className="box">
              5
            </td>
            <td
              id="subsection5-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('5')}
            </td>
            <td className="nbr"></td>
            <td className="nbr nbb"></td>
          </tr>
          <tr>
            <td id="subsection6-name">Goods imported into the UAE</td>
            <td id="subsection6-box-name" className="box">
              6
            </td>
            <td
              id="subsection6-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('6')}
            </td>
            <td id="subsection6-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td className="nbr nbb"></td>
          </tr>
          <tr>
            <td id="subsection7-name">
              Adjustments and additions to goods imported into the UAE
            </td>
            <td id="subsection7-box-name" className="box">
              7
            </td>
            <td
              id="subsection7-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('7')}
            </td>
            <td id="subsection7-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td className="nbr"></td>
          </tr>
          <tr>
            <td id="subsection8-name" className="subtotal">
              Totals
            </td>
            <td id="subsection8-box-name" className="box">
              8
            </td>
            <td id="subsection8-value" className="rightalign" tabIndex={1}>
              {renderValueCell('8')}
            </td>
            <td id="subsection8-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection8-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
        </tbody>
      </table>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section101-name">VAT on Expenses and all other Inputs</th>
            <th></th>
            <th style={{ width: '130px', textAlign: 'center' }}>
              Amount - <span className="currency-sign"></span>
            </th>
            <th style={{ width: '130px', textAlign: 'center' }}>
              VAT Amount - <span className="currency-sign"></span>
            </th>
            <th style={{ width: '130px', textAlign: 'center' }}>
              Adjustments - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection9-name">Standard rated expenses</td>
            <td id="subsection9-box-name" className="box">
              9
            </td>
            <td
              id="subsection9-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('9')}
            </td>
            <td id="subsection9-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection9-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection10-name">Standard rated expenses</td>
            <td id="subsection10-box-name" className="box">
              10
            </td>
            <td
              id="subsection10-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('10')}
            </td>
            <td id="subsection10-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td className="nbr"></td>
          </tr>
          <tr>
            <td id="subsection11-name" className="subtotal">
              Totals
            </td>
            <td id="subsection11-box-name" className="box">
              11
            </td>
            <td id="subsection11-value" className="rightalign" tabIndex={1}>
              {renderValueCell('11')}
            </td>
            <td id="subsection11-vat" className="rightalign" tabIndex={1}>
              0.00
            </td>
            <td
              id="subsection11-adjustments"
              className="rightalign notavailable"
              tabIndex={1}
            >
              0.00
            </td>
          </tr>
        </tbody>
      </table>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section102-name">Net VAT due</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection12-name">
              Total value of due tax for the period
            </td>
            <td id="subsection12-box-name" className="box">
              12
            </td>
            <td
              id="subsection12-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('12')}
            </td>
          </tr>
          <tr>
            <td id="subsection13-name">
              Total value of recoverable tax for the period
            </td>
            <td id="subsection13-box-name" className="box">
              13
            </td>
            <td
              id="subsection13-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('13')}
            </td>
          </tr>
          <tr>
            <td id="subsection14-name">
              Net VAT payable (or reclaimable) for the period
            </td>
            <td id="subsection14-box-name" className="box">
              14
            </td>
            <td
              id="subsection14-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('14')}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
