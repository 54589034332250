import { call, delay, put, select } from 'redux-saga/effects';
import { fetchRunDataFailure, fetchRunDataSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { FetchViewRunDataRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { GetRunDataResponse, getViewRunData } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData, ProfilePicture } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { PICTURE_DELAY_TIME } from 'store/app/constants';
import { VatRunData } from '../models';
import { getProfilePictures } from 'store/app/selectors';
import { fetchProfilePictureRequest } from 'store/account/actions';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchViewRunDataSaga(action: FetchViewRunDataRequestAction) {
  try {
    let err: ApiError | null;
    const { viewRunRequestData } = action.payload;

    const response: GetRunDataResponse = yield call(
      getViewRunData,
      viewRunRequestData
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const vatRunData: VatRunData = response.data;
    yield put(fetchRunDataSuccess(vatRunData));

    ///Get pics
    if (vatRunData) {
      let pp: ProfilePicture[] = yield select(getProfilePictures);
      const activityNames = ['staff', 'clients'];

      for (let b = 0; b < activityNames.length; b++) {
        let newMembers = [...vatRunData[activityNames[b]]];
        for (let i = 0; i < vatRunData[activityNames[b]].length; i++) {
          const user = vatRunData[activityNames[b]][i];
          if (user.hasProfilePicture && !user.profilePicture) {
            const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
            if (ppIdx > -1) {
              const profilePicture = pp[ppIdx].profilePicture;
              newMembers[i] = { ...newMembers[i], profilePicture };
            } else {
              yield put(fetchProfilePictureRequest(user.userUuid));
              yield delay(PICTURE_DELAY_TIME);
              pp = yield select(getProfilePictures);
              const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
              if (ppIdx > -1) {
                const profilePicture = pp[ppIdx].profilePicture;
                newMembers[i] = { ...newMembers[i], profilePicture };
              }
            }
          }
        }

        vatRunData[activityNames[b]] = newMembers;
      }
    }

    yield put(fetchRunDataSuccess(vatRunData));
    //yield put(push(routes.vat.report));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchRunDataFailure());
  }
}
