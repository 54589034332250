import { call, put } from 'redux-saga/effects';
import {
  changeStatusAfterUpdate,
  clientApproveFailure,
  clientApproveSuccess,
  internallyApproveFailure,
  internallyApproveSuccess,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import {
  ClientApproveRequestAction,
  InternallyApproveRequestAction,
} from '../types';
import { checkResponse } from 'store/utils';

import { clientApprove, internallyApprove } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { DataLogStatus } from 'store/app/enums';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* clientApproveSaga(action: ClientApproveRequestAction) {
  try {
    let err: ApiError | null;
    const { dataLogId, reportUrl } = action.payload;

    const response: ApiResponse = yield call(
      clientApprove,
      dataLogId,
      reportUrl
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success!',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    yield put(changeStatusAfterUpdate(DataLogStatus.CLIENT_APPROVED));

    yield put(clientApproveSuccess());
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(clientApproveFailure());
  }
}
