import { BillingComponent } from 'components/Billing';
import { EditPlan } from 'components/Billing/edit-plan';
import { push } from 'connected-react-router';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes';
import { SubscriptionPlan } from 'store/api/enums';
import {
  turnDrawerOff,
  turnDrawerOn,
  turnMessageOn,
  turnModalOn,
} from 'store/app/actions';
import { DrawerType, WindowType } from 'store/app/enums';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { Plan } from 'store/billing/models';
import Features from 'store/billing/models/features.json';
import {
  getBillingLoading,
  getCurrentSubscription,
  getInvoices,
  getSelectedPlan,
  getSubscriptionPlanTypes,
} from 'store/billing/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';
import { ReactComponent as BreakingHeartIcon } from 'assets/images/icons/breakingHeartIcon.svg';
import {
  cancelSubscriptionRequest,
  changeSubscriptionPlanRequest,
  fetchSubscriptionTypesRequest,
  getCurrentSubscriptionRequest,
  getInvoicesRequest,
  updateCardRequest,
} from 'store/billing/actions';
import { useLocation, useParams } from 'react-router';
import { MessageStates } from 'containers/MessageBox';
import { fetchMeRequest } from 'store/auth/actions';
import { ContactUs } from 'components/Billing/contact-us';
import { useState } from 'react';

export const BillingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [messageValue, setMessageValue] = React.useState<any>('');
  const [showPage, setShowPage] = useState(false);
  const querpar = new URLSearchParams(location.search);

  const cardUpdated = querpar.get('cardUpdated');
  const successfulPayment = querpar.get('successfulPayment');
  const currentUser = useSelector(getAuthUser);
  const selectedPlan = useSelector(getSelectedPlan);
  const invoices = useSelector(getInvoices);
  const currentSubscription = useSelector(getCurrentSubscription);
  const isLoading = useSelector(getBillingLoading);
  const subscriptionPlanTypes = useSelector(getSubscriptionPlanTypes);

  React.useEffect(() => {
    setShowPage(true);
    currentUser && checkPageLoaded(location.pathname, routes.users);
    if (currentUser?.accountUuid) {
      dispatch(getInvoicesRequest(currentUser?.accountUuid));
      dispatch(getCurrentSubscriptionRequest(currentUser?.accountUuid));
    }
    if (subscriptionPlanTypes) {
      dispatch(fetchSubscriptionTypesRequest());
    }
  }, []);

  React.useEffect(() => {
    if (cardUpdated) {
      const message: MessageData = {
        title: 'Card updated !',
        description: 'Card updated successfully.',
        type: MessageStates.SUCCESS,
      };

      dispatch(turnMessageOn(message));
    }
  }, [cardUpdated]);

  React.useEffect(() => {
    if (successfulPayment) {
      dispatch(fetchMeRequest());
      const message: MessageData = {
        title: 'Payment successfully !',
        description: 'Payment has been successfully.',
        type: MessageStates.SUCCESS,
      };

      dispatch(turnMessageOn(message));
    }
  }, [successfulPayment]);

  const backToChangePlan = () => {
    dispatch(push(routes.pricing.root));
  };

  const getFeaturesOfPlans = () => {
    const connectedOrganisationsFeature = Features.find(
      (fet) => fet.feature?.toLowerCase() === 'connected entities'
    );

    const priceFeature = Features.find(
      (fet) => fet.feature?.toLowerCase() === 'price'
    );

    const plans = Object.values(SubscriptionPlan).filter(
      (plan) => plan != SubscriptionPlan.FREE_PLAN
    );

    return plans.map((planType) => {
      const planSize = planType.split(' ')[0]?.toLowerCase();
      const plan: Plan = {
        planName: planType,
        connectedOrganisations: connectedOrganisationsFeature
          ? connectedOrganisationsFeature[planSize]
          : undefined,
        price: priceFeature ? priceFeature[planSize] : undefined,
        vatNumberCheckerAddon: 2,
        additionalOrganisations: 2,
        totalAmount: priceFeature ? priceFeature[planSize] : undefined,
      };
      return plan;
    });
  };

  const updatePlan = (additionalSize: number) => {
    dispatch(
      currentSubscription &&
        changeSubscriptionPlanRequest(
          currentSubscription?.typeId,
          additionalSize
        )
    );
    dispatch(turnDrawerOff());
  };

  const updateCard = () => {
    currentUser && dispatch(updateCardRequest(currentUser?.accountUuid));
  };

  const closeDrawer = () => {
    dispatch(turnDrawerOff());
  };

  const downgradePlan = (typeId: string) => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title:
        'Are you sure you wish to downgrade your current plan? This action will have an impact on the billing and some functionality will be disabled.',
      component: (
        <ConfirmBoxContainer
          callback={
            () => checkoutToUpgradePlan(typeId)
            //navigateToExternalPage('https://generate.tax/contact/')
          }
          showCancelBtn={true}
          okText="Confirm Downgrade"
          description={<>This change will start from the next billing cycle.</>}
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const editPlan = () => {
    const modalData: DrawerData = {
      type: DrawerType.EDIT_PLAN,
      title: t('users.edit-details'),
      component: (
        <EditPlan //@ts-ignore
          currentSubscriptionPlan={currentSubscription}
          updatePlan={updatePlan}
          close={closeDrawer}
        />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const redirectPage = (url: string) => {
    dispatch(push(url));
  };

  const checkoutToUpgradePlan = (typeId: string) => {
    dispatch(push(`/pricing/checkout/${typeId}?changePlan=true`));
  };

  const renderCancelPlanWindow = (data: any) => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'Before you cancel, maybe we can help',
      icon: <BreakingHeartIcon />,
      component: (
        <ConfirmBoxContainer
          callback={() => {
            //navigateToExternalPage('https://generate.tax/contact/')
            dispatch(turnModalOn(modalData));
            renderContactUsWindow(false);
          }}
          callback2={() =>
            currentUser &&
            dispatch(cancelSubscriptionRequest(currentUser?.accountUuid))
          }
          showCancelBtn={false}
          //param2={data}
          okText="Chat with us"
          okText2="Cancel Plan"
          description={
            <>
              It looks like you’re considering canceling your account.
              <br /> Before proceeding, we’d love to hear from you! Is there
              anything we could improve, or would you like to chat with us to
              discuss your decision?
              <br /> <b>Please note</b>, this action is irreversible. All data,
              tax rate mappings, and tax submissions will be permanently
              deleted. Would you be open to exploring a solution that allows you
              to retain your work instead?
            </>
          }
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const renderContactUsWindow = (isEnterprise: boolean) => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: isEnterprise
        ? 'Thank you for your interest in the Enterprise plan. A product specialist will be in touch to discuss your exact requirements.\nWould you like to leave a note before sending the request?'
        : 'We are sorry to see that you wish to to cancel your account.  A product specialist will be in touch to discuss how we can help without deleting the account.  Would you like to leave a note before sending the request?',
      component: (
        <ConfirmBoxContainer
          callback={
            () => {}
            //navigateToExternalPage('https://generate.tax/contact/')
          }
          showCancelBtn={false}
          okText={isEnterprise ? 'Request More Info' : 'Request Call'}
          description={
            <>
              <ContactUs
                messageValue={messageValue}
                setMessageValue={setMessageValue}
              />
            </>
          }
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Billing | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for billing" />
          </Helmet>

          <BillingComponent
            backToChangePlan={backToChangePlan}
            getFeaturesOfPlans={getFeaturesOfPlans}
            editPlan={editPlan} //@ts-ignore
            currentSubscription={currentSubscription} //currentSubscription
            redirectPage={redirectPage} //@ts-ignore
            renderCancelPlanWindow={renderCancelPlanWindow}
            invoices={invoices}
            updateCard={updateCard}
            isLoading={isLoading}
            subscriptionPlanTypes={subscriptionPlanTypes}
            checkoutToUpgradePlan={checkoutToUpgradePlan}
            renderContactUsWindow={renderContactUsWindow}
            downgradePlan={downgradePlan}
          />
        </>
      )}
    </React.Fragment>
  );
};
