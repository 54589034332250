//import qs from 'query-string';
import { AuthTokenStorage } from 'store/auth/utils/authTokenStorage';
import { ALLOWED_BODY, DEFAULT_MESSAGE_CODE } from './constants';
import { ApiCommunicator, ApiError, ApiResponse, RequestParams } from './types';
import { getCookie } from 'utils/get-cookie';
import routes from 'routes';
import axios from 'axios';
//import FingerprintJS from '@fingerprintjs/fingerprintjs';
//import CookieManager from '@react-native-cookies/cookies';

export const api = {
  get: serve('GET', false),
  post: serve('POST', false),
  put: serve('PUT', false),
  delete: serve('DELETE', false),
  getExternalAPI: serve('GET', true),
};

function serve(method: string, isExternalApi: boolean): ApiCommunicator {
  return async function sendHttpRequest<T extends ApiResponse['data'], M>(
    path: string,
    params?: RequestParams
    //casinoApi? = false
  ): Promise<T | M> {
    params = {
      auth: true,
      ...params,
    };

    try {
      const body =
        ALLOWED_BODY.includes(method) && params.body
          ? params.formData
            ? params.body
            : JSON.stringify(params.body)
          : undefined;

      const query = params.query ? qs.stringify(params.query) : undefined;

      const apiBaseUrl = isExternalApi ? '' : process.env.API_URL;
      const url = query ? `${path}?${query}` : path;
      const totalUrl = apiBaseUrl + url;
      //const totalUrl = params.ignoreAppPath ? url :apiBaseUrl + url;

      const accessToken = AuthTokenStorage.getAccessToken();
      const auth =
        params.auth && accessToken ? `Bearer ${accessToken}` : undefined;

      const headers: HeadersInit = {
        Accept: 'application/json',
      };

      const xsrfToken = getCookie('XSRF-TOKEN');

      if (xsrfToken && method !== 'GET') {
        headers['X-XSRF-TOKEN'] = xsrfToken;
      }

      if (!params.formData) {
        headers['Content-Type'] = 'application/json';
      }

      if (auth && !isExternalApi) {
        headers['Authorization'] = auth;
      }

      if (isExternalApi) {
        headers['Accept'] = '*/*';
        headers['Access-Control-Allow-Origin'] = `${process.env.PUBLIC_URL}/, *`;
        headers['Access-Control-Allow-Headers'] = '*';
        headers['Access-Control-Allow-Methods'] = '*';
        //headers['mode'] = 'no-cors';
      }

      // if (typeof window !== 'undefined') {
      //   const fp = await FingerprintJS.load();
      //   const result = await fp.get();
      //   headers['Device-Fingerprint'] = result.visitorId;
      // }

      const request: RequestInit = {
        method,
        body,
        headers,
        credentials: isExternalApi ? 'omit' :'include',
       // mode: isExternalApi ? 'no-cors' : 'cors',
      };

      const response = await fetch(totalUrl, request);

      if (!response.ok) {
        const body = await (response.json() as Promise<ApiResponse>);
        const status = response.status;
        body.status = status;
        if (status === 401 && window.location.pathname != routes.login)
          window.location.replace(process.env.PUBLIC_URL + routes.login);
        else throw body;
      }

      const jsonRaw = await response.text();

      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      let json: any;

      try {
        json = JSON.parse(jsonRaw);
      } catch (error) {
        json = jsonRaw;
      }

      // if (json.hasOwnProperty('data')) {
      //   return json.data as T;
      // }

      return json as M;
    } catch (error) {
      throw createError(error as ApiResponse);
    }
  };
}

function createError(error: ApiResponse): ApiError {
  const INTERNAL_ERROR = 500;
  return {
    message: error.message,
    messageCode: error.messageCode || DEFAULT_MESSAGE_CODE,
    status: error.status || INTERNAL_ERROR,
  } as ApiError;
}
