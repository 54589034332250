import { call, put } from 'redux-saga/effects';
import {
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
} from '../actions';
import { ApiError } from 'store/api/types';
import { CancelSubscriptionRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import { cancelSubscription, CancelSubscriptionResponse } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* cancelSubscriptionSaga(
  action: CancelSubscriptionRequestAction
) {
  try {
    let err: ApiError | null;
    const { licenseId } = action.payload;

    const cancelSubscriptionResponse: CancelSubscriptionResponse = yield call(
      cancelSubscription,
      licenseId
    );

    err = checkResponse(cancelSubscriptionResponse?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Success !',
      description: cancelSubscriptionResponse.data.responseBody,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
    yield put(cancelSubscriptionSuccess());
    yield put(logoutRequest());
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(cancelSubscriptionFailure());
  }
}
