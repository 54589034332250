import { call, put } from 'redux-saga/effects';
import {
  fetchUserHistoryFailure,
  fetchUserHistorySuccess,
  fetchUsersRequest,
} from '../actions';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { FetchUserHistoryResponse, getUserHistory } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { FetchUserHistoryRequestAction } from '../types';
import { LoginDetails } from '../models';
import { MessageStatus } from 'store/api/enums';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchUserHistorySaga(action: FetchUserHistoryRequestAction) {
  try {
    let err: ApiError | null;
    const { historyUserId } = action.payload;
    const response: FetchUserHistoryResponse = yield call(
      getUserHistory,
      historyUserId
    );

    err = checkResponse(response?.responseMessage);
    if (err && response.responseMessage.status === MessageStatus.UNAUTHORIZED) {
      yield put(fetchUsersRequest());
    }
    if (err) throw err;
    const loginDetails: LoginDetails[] = response.data.loginDetails;
    yield put(fetchUserHistorySuccess(loginDetails));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchUserHistoryFailure());
  }
}
