import Axios from 'axios';
import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';
import { SubscriptionType } from './models/subscriptionType';
import { PlanPricingDetails } from './models/planPricingDetails';
import { BillingCheckout } from './models/billingCheckout';
import { CurrentSubscription } from './models/currentSubscription';
import { Invoice } from './models/invoice';

Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.withCredentials = true;

export interface ValidateVATNumberResponse extends ApiResponse {
  data: { isValid: string };
}

export interface SubscriptionTypeResponse extends ApiResponse {
  data: { licenseTypes: SubscriptionType[] };
}

export interface ChangeSubscriptionPlanResponse extends ApiResponse {
  data: { message: string; statusCode: number };
}

export interface FetchPlanPricingDetailsResponse extends ApiResponse {
  data: { priceDetails: PlanPricingDetails; statusCode: number };
}

export interface CheckoutResponse extends ApiResponse {
  data: { responseBody: string; statusCode: number };
}

export interface GetCurrentSubscriptionResponse extends ApiResponse {
  data: { subscription: CurrentSubscription; statusCode: number };
}

export interface GetInvoicesResponse extends ApiResponse {
  data: { invoices: Invoice[]; statusCode: number };
}

export interface UpdateCardResponse extends ApiResponse {
  data: { url: string};
}

export interface CancelSubscriptionResponse extends ApiResponse {
  data: { responseBody: string; statusCode: number };
}

export const validateVATNumber = (
  countryCode: string,
  taxNumber: string
): Promise<ValidateVATNumberResponse> =>
  api.get<ValidateVATNumberResponse>(`/vies/check/${taxNumber}`);

export const fetchSubscriptionPlans = (): Promise<SubscriptionTypeResponse> =>
  api.get<SubscriptionTypeResponse>('/plan/types');

export const changeSubscriptionPlan = (
  subscriptionTypeId: string,
  additionalSize: number
): Promise<ChangeSubscriptionPlanResponse> =>
  api.post<ChangeSubscriptionPlanResponse>(
    `/plan/change?typeId=${subscriptionTypeId}&additionalSize=${additionalSize}`
  );

export const fetchPlanPricingDetails = (
  subscriptionTypeId: string
): Promise<FetchPlanPricingDetailsResponse> =>
  api.get<FetchPlanPricingDetailsResponse>(
    `/plan/details?planUuid=${subscriptionTypeId}`
  );

export const checkout = (
  billingCheckout: BillingCheckout
): Promise<CheckoutResponse> =>
  api.post<CheckoutResponse>('/payment/checkout', {
    body: {
      ...billingCheckout,
    },
  });

export const getCurrentSubscription = (
  accountId: string
): Promise<GetCurrentSubscriptionResponse> =>
  api.get<GetCurrentSubscriptionResponse>(
    `/payment/subscription?accountId=${accountId}`
  );

export const getInvoices = (customerId: string): Promise<GetInvoicesResponse> =>
  api.get<GetInvoicesResponse>(`/payment/invoice-link?accountId=${customerId}`);

export const updateCard = (accountId: string): Promise<UpdateCardResponse> =>
  api.post<UpdateCardResponse>(`/payment/update-card?accountId=${accountId}`);

export const cancelSubscription = (accountId: string): Promise<CancelSubscriptionResponse> =>
  api.post<CancelSubscriptionResponse>(`/payment/cancel?accountId=${accountId}`);
