import * as React from 'react';
import { VatReportData } from 'store/vat/models';
import { TaxReportSubsectionDataResponse } from 'store/vat/models/vatReportData';

interface NetherlandsFormProps {
  data?: VatReportData;
  renderValueCell: (boxNumber: string) => void;
  finalTotals: boolean;
}

export const NetherlandsForm: React.FC<NetherlandsFormProps> = ({
  data,
  renderValueCell,
  finalTotals,
}) => {
  React.useEffect(() => {
    const selectedSubsections: TaxReportSubsectionDataResponse[] | undefined =
      !finalTotals ? data?.subsections : data?.adjustedSubsections;
    if (data === undefined || data === null) return;
    const currencySignSpans = document.querySelectorAll('.currency-sign');
    currencySignSpans.forEach((currencySignSpan) => {
      if (data.currencySymbol !== null) {
        currencySignSpan.textContent = data.currencySymbol;
      }
    });

    data.sections &&
      data.sections.map((section, i) => {
        const sectionNameCell = document.querySelector(`#section10${i}-name`);

        if (sectionNameCell) {
          sectionNameCell.textContent = section.name;
        }
      });
    selectedSubsections &&
      selectedSubsections.map((subsection) => {
        const boxNumber = subsection?.code.trim().replace(/^Box\s+/, '');

        const subsectionNameCell = document.getElementById(
          `subsection${boxNumber}-name`
        );

        const subsectionBoxNameCell = document.getElementById(
          `subsection${boxNumber}-box-name`
        );

        const subsectionVatCell = document.getElementById(
          `subsection${boxNumber}-vat`
        );

        const subsectionValueSpan = document.getElementById(
          `subsection${boxNumber}-value-span`
        );
        if (subsectionNameCell !== null) {
          subsectionNameCell.textContent = subsection.name;
        }

        if (
          subsectionBoxNameCell !== null && //subsectionValueCell !== null &&
          subsectionValueSpan !== null
        ) {
          subsectionBoxNameCell.textContent = subsection.code;
          const textContent: any = subsection.net;
          subsectionValueSpan.textContent = textContent.toFixed(2);
        }
        if (
          subsection.tax !== null &&
          subsectionVatCell !== null &&
          document.getElementById(`subsection${boxNumber}-vat`) !== null
        ) {
          subsectionVatCell.textContent = subsection.tax.toFixed(2);
        }
      });
  }, [data, finalTotals]);

  return (
    <>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section100-name">
              Section 1: Local supply of goods/services
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Taxable amount (excl. VAT) -{' '}
              <span className="currency-sign"></span>
            </th>
            {/* <th></th> */}
            <th style={{ width: 130, textAlign: 'center' }}>
              VAT - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection1a-name">
              Supply of goods/services taxed at high rate
            </td>
            <td id="subsection1a-box-name" className="box">
              Box 1a
            </td>
            <td
              id="subsection1a-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1a')}
            </td>

            <td id="subsection1a-vat" className="rightalign" tabIndex={2}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1b-name">
              Supply of goods/services taxed at low rate
            </td>
            <td id="subsection1b-box-name" className="box">
              Box 1b
            </td>
            <td
              id="subsection1b-value"
              className="rightalign value-field"
              tabIndex={3}
            >
              {renderValueCell('1b')}
            </td>

            <td id="subsection1b-vat" className="rightalign" tabIndex={4}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1c-name">
              Supply of goods/services charged with other rates except 0%
            </td>
            <td id="subsection1c-box-name" className="box">
              Box 1c
            </td>
            <td
              id="subsection1c-value"
              className="rightalign value-field"
              tabIndex={5}
            >
              {renderValueCell('1c')}
            </td>

            <td id="subsection1c-vat" className="rightalign" tabIndex={6}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1d-name">Correction for private-use</td>
            <td id="subsection1d-box-name" className="box">
              Box 1d
            </td>
            <td
              id="subsection1d-value"
              className="rightalign value-field"
              tabIndex={7}
            >
              {renderValueCell('1d')}
            </td>

            <td id="subsection1d-vat" className="rightalign" tabIndex={8}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection1e-name">Sub-Total</td>

            <td id="subsection1e-box-name" className="box">
              Box 1e
            </td>
            <td id="subsection1e-value" className="rightalign" tabIndex={9}>
              {renderValueCell('1e')}
            </td>

            <td className=""></td>
          </tr>
        </tbody>
      </table>

      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section101-name">
              Section 2: Domestic reverse charge arrangements
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Taxable amount (excl. VAT) -{' '}
              <span className="currency-sign"></span>
            </th>

            <th style={{ width: 130, textAlign: 'center' }}>
              VAT - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection2a-name">dd</td>
            <td id="subsection2a-box-name" className="box">
              Box 2a
            </td>
            <td
              id="subsection2a-value"
              className="rightalign value-field"
              tabIndex={10}
            >
              {renderValueCell('2a')}
            </td>
            <td id="subsection2a-vat" className="rightalign" tabIndex={11}>
              0.00
            </td>
          </tr>
        </tbody>
      </table>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section102-name">
              Section 3: Goods and services to or in foreign countries
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Taxable amount (excl. VAT) -{' '}
              <span className="currency-sign"></span>
            </th>

            <th style={{ width: 130, textAlign: 'center' }}>
              VAT - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection3a-name">
              Supplies to non-EU countries (export)
            </td>
            <td id="subsection3a-box-name" className="box">
              Box 3a
            </td>
            <td
              id="subsection3a-value"
              className="rightalign value-field"
              tabIndex={12}
            >
              {renderValueCell('3a')}
            </td>
            <td className="nbb"></td>
          </tr>
          <tr>
            <td id="subsection3b-name">
              Supplies to or services in EU countries
            </td>
            <td id="subsection3b-box-name" className="box">
              Box 3b
            </td>
            <td
              id="subsection3b-value"
              className="rightalign value-field"
              tabIndex={13}
            >
              {renderValueCell('3b')}
            </td>
            <td className="nbb"></td>
          </tr>
          <tr>
            <td id="subsection3c-name">hhh</td>
            <td id="subsection3c-box-name" className="box">
              Box 3c
            </td>
            <td
              id="subsection3c-value"
              className="rightalign value-field"
              tabIndex={14}
            >
              {renderValueCell('3c')}
            </td>
            <td className=""></td>
          </tr>
        </tbody>
      </table>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section103-name">
              Section 4: Services provided to you from abroad
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Taxable amount (excl. VAT) -{' '}
              <span className="currency-sign"></span>
            </th>
            <th style={{ width: 130, textAlign: 'center' }}>
              VAT - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection4a-name">
              Supplies/services from non-EU countries
            </td>
            <td id="subsection4a-box-name" className="box">
              Box 4a
            </td>
            <td
              id="subsection4a-value"
              className="rightalign value-field"
              tabIndex={15}
            >
              {renderValueCell('4a')}
            </td>
            <td id="subsection4a-vat" className="rightalign" tabIndex={16}>
              0.00
            </td>
          </tr>
          <tr>
            <td id="subsection4b-name">Supplies/services from EU countries</td>
            <td id="subsection4b-box-name" className="box">
              Box 4b
            </td>
            <td
              id="subsection4b-value"
              className="rightalign value-field"
              tabIndex={17}
            >
              {renderValueCell('4b')}
            </td>
            <td id="subsection4b-vat" className="rightalign" tabIndex={18}>
              0.00
            </td>
          </tr>
        </tbody>
      </table>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section104-name">
              Section 5: Input tax, small business scheme and grand total
            </th>
            <th></th>
            <th></th>
            {/* <th style={{ width: 130, textAlign: 'center' }}>
              Taxable amount (excl. VAT) -{' '}
              <span className="currency-sign"></span>
            </th> */}
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              VAT - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection5a-name" className="subtotal" colSpan={3}>
              Sales tax due (sections 1 to 4 inclusive)
            </td>
            <td id="subsection5a-box-name" className="box">
              Box 5a
            </td>
            <td
              id="subsection5a-value"
              className="rightalign value-field"
              tabIndex={19}
            >
              {renderValueCell('5a')}
            </td>
          </tr>
          <tr>
            <td id="subsection5b-name" className="subtotal" colSpan={3}>
              Input VAT
            </td>
            <td id="subsection5b-box-name" className="box">
              Box 5b
            </td>
            <td
              id="subsection5b-value"
              className="rightalign value-field"
              tabIndex={20}
            >
              {renderValueCell('5b')}
            </td>
          </tr>
          <tr>
            <td id="subsection5c-name" className="subtotal" colSpan={3}>
              Subtotal: (calculate 5a minus 5b)
            </td>
            <td id="subsection5c-box-name" className="box">
              Box 5c
            </td>
            <td
              id="subsection5c-value"
              className="rightalign value-field"
              tabIndex={21}
            >
              {renderValueCell('5c')}
            </td>
          </tr>
          <tr>
            <td id="subsection5d-name" colSpan={3}>
              Deduction available for small entrepreneurs
            </td>
            <td id="subsection5d-box-name" className="box">
              Box 5d
            </td>
            <td
              id="subsection5d-value"
              className="rightalign value-field"
              tabIndex={22}
            >
              {renderValueCell('5d')}
            </td>
          </tr>
          <tr>
            <td id="subsection5e-name" colSpan={3}>
              Estimate previous return
            </td>
            <td id="subsection5e-box-name" className="box">
              Box 5e
            </td>
            <td
              id="subsection5e-value"
              className="rightalign value-field"
              tabIndex={23}
            >
              {renderValueCell('5e')}
            </td>
          </tr>
          <tr>
            <td id="subsection5f-name" colSpan={3}>
              Estimate current return
            </td>
            <td id="subsection5f-box-name" className="box">
              Box 5f
            </td>
            <td
              id="subsection5f-value"
              className="rightalign value-field"
              tabIndex={24}
            >
              {renderValueCell('5f')}
            </td>
          </tr>
          <tr>
            <td id="subsection5g-name" className="subtotal" colSpan={3}>
              dfd
            </td>
            <td id="subsection5g-box-name" className="box">
              Box 5g
            </td>
            <td
              id="subsection5g-value"
              className="rightalign value-field"
              tabIndex={25}
            >
              {renderValueCell('5g')}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
