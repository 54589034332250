import { call, put } from 'redux-saga/effects';
import { loadReconFailure, loadReconSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { LoadReconRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { LoadReconResponse, loadRecon } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* loadReconSaga(action: LoadReconRequestAction) {
  try {
    let err: ApiError | null;
    const { tokenId, toDate } = action.payload;

    const response: LoadReconResponse = yield call(loadRecon, tokenId, toDate);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const reconMap: Map<string, string> = response.data;
    yield put(loadReconSuccess(reconMap));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(loadReconFailure());
  }
}
