import {
  Col,
  Divider,
  Form,
  Row,
  Space,
  Spin,
  Typography,
  Tabs,
  Popover,
  Switch,
  Image,
  List,
} from 'antd';
import React from 'react';
import { User } from 'store/auth/models';
import { Country, CountryFlag } from 'store/common/models';
import { LoginDetails } from 'store/user/models';
import moment from 'moment';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/editIcon.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/userIcon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/crossIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/checkIcon.svg';
import ImgWithXeroSignIn from 'assets/images/xero_icon.png';
import defaultUserPicture from '../../assets/images/default-image.svg';
import { useTranslation } from 'react-i18next';
import { AccountType, SubscriptionPlan } from 'store/api/enums';
import { ChangePasswordForm } from 'components/AccountComponent/change-password';
import { History } from 'components/UsersComponent/history';
import {
  ContextButton,
  IconContextButton,
  ScopeButton,
  YellowButton,
} from 'components/Custom/buttons';
import { StyledCard } from 'components/Custom/card';
import {
  BlackSpan,
  ColoredTag,
  GraySpan,
  CardTitleSpan,
  CardDescSpan,
  CardDescGreenSpan,
  CardDescBoldSpan,
  CardDescBoldGraySpan,
} from 'components/Custom/spans';
import { SSOImg } from 'components/Custom/images';
import { EyeOutlined } from '@ant-design/icons';
import { ProfileWrapper } from 'components/Custom/wrappers';
import { StyledPopconfirm } from 'components/Custom/Popconfirm';
import { BillingForm } from './billing-form';
import { ReactComponent as IconClose } from 'assets/images/icons/iconClose.svg';
import UpgradeImage from 'assets/images/upgrade-pic.svg';
import { SubscriptionType } from 'store/billing/models/subscriptionType';

interface AccountComponentProps {
  loading: boolean;
  currentUser?: User;
  selectedAccount?: User;
  history?: LoginDetails[];
  connectXeroAccount: () => void;
  disconnectXeroAccount: () => void;
  //disconnectGoogleAccount: () => void;
  openMFAWindow: () => void;
  removeMfa: () => void;
  deleteProfile: () => void;
  savePassword: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ) => void;
  openAccessRights: (accountId) => void;
  openEditImageWindow: () => void;
  onDeleteUser: (userToDeleteId: string) => void;
  countries: Country[];
  countriesExternalData: CountryFlag[];
  openEditDetails: () => void;
  openChangeOwnerForm: () => void;
  //connectMicrosoftAccount: () => void;
  //disconnectMicrosoftAccount: () => void;
  redirectPage: (url: string) => void;
  subscriptionPlanTypes: SubscriptionType[];
}
const { Paragraph } = Typography;
const pricingPageLink = '/pricing';
export const AccountComponent: React.FC<AccountComponentProps> = ({
  loading,
  currentUser,
  selectedAccount,
  history,
  connectXeroAccount,
  disconnectXeroAccount,
  //disconnectGoogleAccount,
  openMFAWindow,
  removeMfa,
  deleteProfile,
  savePassword,
  openAccessRights,
  openEditImageWindow,
  onDeleteUser,
  countries,
  countriesExternalData,
  openEditDetails,
  openChangeOwnerForm,
  //connectMicrosoftAccount,
  //disconnectMicrosoftAccount,
  redirectPage,
  subscriptionPlanTypes,
}) => {
  const { t } = useTranslation();
  const [formPassword] = Form.useForm();
  const onXeroAccountConnect = () => connectXeroAccount();
  const onXeroAccountDisconnect = () => disconnectXeroAccount();
  //const onGoogleAccountDisconnect = () => disconnectGoogleAccount();
  //const onMicrosoftAccountConnect = () => connectMicrosoftAccount();
  //const onMicrosoftAccountDisconnect = () => disconnectMicrosoftAccount();
  const onEnableMFAClick = () => openMFAWindow();
  const onRemoveMfaClick = () => removeMfa();
  const [showCard, setShowCard] = React.useState(true);

  const profilePicture: string = selectedAccount
    ? selectedAccount.profilePicture
      ? selectedAccount.profilePicture
      : defaultUserPicture
    : defaultUserPicture;

  const isCurrentUser = selectedAccount?.userUuid === currentUser?.userUuid;
  const renderAccountCard = () => {
    return (
      <StyledCard stopshadowbox width={'99%'} height={'104px'}>
        <Space style={{ columnGap: '35.5%' }}>
          <Space direction="vertical" style={{ rowGap: 4, minWidth: 422 }}>
            <CardTitleSpan>{t('common.account')}</CardTitleSpan>
            <Space style={{ columnGap: 15 }}>
              <CardDescSpan>{`${t('users.ip-address')}: ${
                history && history[0].loginIP
              }`}</CardDescSpan>
              <span>.</span>
              <CardDescSpan>{`${t('users.last-login')}: ${
                history &&
                moment(history[0]?.loginTimestamp).format('DD-MM-YYYY hh:mm:ss')
              }`}</CardDescSpan>
            </Space>
          </Space>
          <a
            target="_blank"
            href={`https://whatismyipaddress.com/ip/${
              history && history[0].loginIP
            }`}
            rel="noreferrer"
          >
            <ScopeButton
              type="default" //@ts-ignore
              width="154px"
              height="32px"
              //onClick={() => onClose()}
            >
              {t('users.verify-location')}
            </ScopeButton>
          </a>
        </Space>
      </StyledCard>
    );
  };

  const renderUserPermissionsCard = () => {
    return (
      <StyledCard stopshadowbox width={'99%'} height={'104'}>
        <Space style={{ columnGap: '31%' }}>
          <Space direction="vertical" style={{ rowGap: 4, minWidth: 446 }}>
            <CardTitleSpan>{t('users.user-permissions')}</CardTitleSpan>
            <Space style={{ columnGap: 15 }}>
              <CardDescSpan>
                You can assign which entities this user has access to view and
                use.
              </CardDescSpan>
            </Space>
          </Space>
          <ScopeButton
            type="default" //@ts-ignore
            width="154px"
            height="32px"
            onClick={() => openAccessRights(selectedAccount?.accountUuid)}
          >
            {t('users.access-rights')}
          </ScopeButton>
        </Space>
      </StyledCard>
    );
  };

  const renderAssociatedAccountsCard = () => {
    return (
      <StyledCard stopshadowbox width={'99%'} height={'104'}>
        <Space direction="vertical" style={{ rowGap: 30 }}>
          <Space style={{ columnGap: 100 }}>
            <Space direction="vertical" style={{ rowGap: 4 }}>
              <CardTitleSpan>{t('users.associated-accounts')}</CardTitleSpan>
              <Space style={{ columnGap: 15 }}>
                <CardDescSpan style={{ width: 460, display: 'block' }}>
                  {t('users.associated-accounts-desc')}
                </CardDescSpan>
              </Space>
            </Space>
          </Space>
          <Space direction="vertical">
            <Space style={{ columnGap: '141%' }}>
              <Space style={{ columnGap: 20, minWidth: 170 }}>
                <SSOImg src={ImgWithXeroSignIn} />
                <CardTitleSpan style={{ fontSize: '14px', fontWeight: 500 }}>
                  {t('users.xero-accounting')}
                </CardTitleSpan>
              </Space>
              {selectedAccount?.xeroEnabled ? (
                <StyledPopconfirm // @ts-ignore
                  title="Disconnect Account"
                  description={
                    <span>
                      Are you sure that you want to disconnect from Xero?
                    </span>
                  }
                  onConfirm={onXeroAccountDisconnect}
                  placement="top"
                  okText={t('common.yes')}
                  cancelText={t('common.cancel')}
                >
                  <ScopeButton
                    type="default" //@ts-ignore
                    width="154px"
                    height="32px"
                    //onClick={onXeroAccountDisconnect}
                  >
                    {t('common.disconnect')}
                  </ScopeButton>
                </StyledPopconfirm>
              ) : (
                <ScopeButton
                  type="primary" //@ts-ignore
                  width="154px"
                  height="32px"
                  onClick={onXeroAccountConnect}
                >
                  {t('common.connect')}
                </ScopeButton>
              )}
            </Space>
          </Space>
        </Space>
      </StyledCard>
    );
  };

  const renderMFACard = () => {
    return (
      <StyledCard stopshadowbox width={'99%'} height={'104'}>
        <Space style={{ columnGap: 110 }}>
          <Space direction="vertical" style={{ rowGap: 4 }}>
            <CardTitleSpan>{t('users.mfa-authentication')}</CardTitleSpan>
            <Space style={{ columnGap: 15 }}>
              <CardDescSpan> {t('users.mfa-authentication-desc')}</CardDescSpan>
            </Space>
          </Space>
          <Space style={{ marginRight: 8 }}>
            <GraySpan>
              {selectedAccount?.mfa ? t('common.on') : t('common.off')}
            </GraySpan>
            <Switch
              className={selectedAccount?.mfa ? 'switch-checked' : ''}
              checked={selectedAccount?.mfa}
              disabled={!isCurrentUser}
              onChange={(e) => {
                if (selectedAccount?.mfa) {
                  onRemoveMfaClick();
                } else {
                  onEnableMFAClick();
                }
              }}
            />
          </Space>
        </Space>
      </StyledCard>
    );
  };

  const renderPasswordCard = () => {
    return (
      <StyledCard stopshadowbox width={'99%'} height={'104'}>
        <Space style={{ columnGap: 410 }}>
          <Space direction="vertical" style={{ rowGap: 4 }}>
            <CardTitleSpan>{t('signup.password')}</CardTitleSpan>
            <Space style={{ columnGap: 15, marginTop: 25 }}>
              <ChangePasswordForm
                hasPassword={currentUser?.hasPassword}
                savePassword={savePassword}
                form={formPassword}
              />
            </Space>
          </Space>
        </Space>
      </StyledCard>
    );
  };

  const renderAccountAndSecurityTab = () => {
    return (
      <Space direction="vertical" style={{ rowGap: 24, marginTop: '25px' }}>
        <div>{renderAccountCard()}</div>
        {selectedAccount && <div> {renderUserPermissionsCard()}</div>}
        <div>{renderAssociatedAccountsCard()}</div>
        <div>{renderMFACard()}</div>
        <div>{isCurrentUser && renderPasswordCard()}</div>
      </Space>
    );
  };

  const renderCountryWithFlag = (country: Country): React.ReactNode => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          width={'24px'}
          height={'16px'}
          alt={''}
          src={country.flag}
          style={{
            marginLeft: '5px',
            marginRight: '10px',
            marginTop: '3px',
          }}
        />
        {country.name}
      </div>
    );
  };

  const renderBillingDetailsTab = () => {
    return (
      <BillingForm
        countries={countries}
        countriesExternalData={countriesExternalData}
        isCheckoutForm={false}
      />
    );
  };

  const items = [
    {
      label: t('users.account-security'),
      key: 'Account & Security',
      children: renderAccountAndSecurityTab(),
    },
    {
      label: t('users.billing-details'),
      key: 'Billing Details',
      children: renderBillingDetailsTab(),
    },
    {
      label: t('users.login-history'),
      key: 'Login History',
      children: (
        <StyledCard
          stopshadowbox
          width={'773px'}
          height={'640px'}
          style={{ marginTop: '25px' }}
        >
          <History history={history} profileMode={true} onClose={() => {}} />
        </StyledCard>
      ),
    },
  ];

  const renderRole = (content: string) => {
    switch (AccountType[content]) {
      case AccountType.OWNER:
        return (
          <ColoredTag
            color="#042A2A" //@ts-ignore
            bgcolor="#FBE3FF"
          >
            {AccountType[content]}
          </ColoredTag>
        );
        break;
      case AccountType.CLIENT:
        return (
          <ColoredTag
            color="#042A2A" //@ts-ignore
            bgcolor="#CCF5FE"
          >
            {AccountType[content]}
          </ColoredTag>
        );
        break;
      case AccountType.STAFF:
        return (
          <ColoredTag
            color="#042A2A" //@ts-ignore
            bgcolor="#F9EEC7"
          >
            {AccountType[content]}
          </ColoredTag>
        );
        break;
      default:
        break;
    }
  };

  const renderUserSubscription = () => {
    if (!currentUser?.typeId) {
      return (
        <ColoredTag
          color="#E9F6F7" //@ts-ignore
          bgcolor="#AF41AA"
        >
          {t('billing.free-user')}
        </ColoredTag>
      );
    } else {
      return (
        <ColoredTag
          color="#E9F6F7" //@ts-ignore
          bgcolor="#AF41AA"
        >
          {t('billing.subscribed')}
        </ColoredTag>
      );
      /* switch (SubscriptionPlan['FREE_PLAN']) {
        case SubscriptionPlan.FREE_PLAN:
          return (
            <ColoredTag
              color="#E9F6F7" //@ts-ignore
              bgcolor="#AF41AA"
            >
              {SubscriptionPlan['FREE_PLAN']}
            </ColoredTag>
          );
          break;
        default:
          break;
      } */
    }
  };

  const renderContextMenu = (record) => {
    const to = `/entities/organisation/${record?.organisationId}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          {isCurrentUser && (
            <IconContextButton
              type="text"
              icon={
                <EditIcon
                  style={{ width: '16px', height: '17px', marginRight: '7px' }}
                />
              }
              onClick={() => openEditDetails()}
            >
              {t('users.edit-details')}
            </IconContextButton>
          )}
          {currentUser?.accountType &&
            AccountType[currentUser.accountType] === AccountType.OWNER && (
              <IconContextButton
                type="text"
                icon={
                  <UserIcon
                    style={{
                      width: '18px',
                      height: '20px',
                      marginRight: '4px',
                    }}
                  />
                }
                onClick={() => openChangeOwnerForm()}
              >
                {t('users.change-owner')}
              </IconContextButton>
            )}
          {!record?.isLicenseOwner &&
          record?.userUuid !== currentUser?.userUuid ? (
            <>
              {currentUser?.canManageUsers && (
                <StyledPopconfirm // @ts-ignore
                  title={t('users.delete-user-title')}
                  description={
                    <span>
                      {t('users.delete-user-description').split('{br}')[0]}
                      <br />
                      {t('users.delete-user-description').split('{br}')[1]}
                    </span>
                  }
                  onConfirm={() => onDeleteUser(record.userUuid)}
                  placement="left"
                  okText={t('common.yes')}
                  cancelText={t('common.cancel')}
                >
                  <IconContextButton
                    type="text"
                    icon={
                      <CrossIcon
                        style={{
                          width: '10px',
                          height: '22px',
                          marginRight: '8px',
                          marginLeft: '5px',
                        }}
                      />
                    }
                  >
                    {t('users.delete-user')}
                  </IconContextButton>
                </StyledPopconfirm>
              )}
            </>
          ) : (
            <StyledPopconfirm // @ts-ignore
              placement="top"
              title="Delete account"
              description={
                <>
                  <Paragraph>
                    You can cancel your generate.TAX account whenever you want.
                    <br />
                    Your generate.TAX account will be terminated including all
                    <br />
                    your connected entities, users and billing
                    <br />
                    subscriptions. If you wish to pause the account instead of
                    <br />
                    deleting the account, you may delete the Organisations
                    <br />
                    without cancelling the subscription.
                  </Paragraph>
                  {'Are you sure that you want to delete account?'}
                </>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={deleteProfile}
            >
              <IconContextButton
                type="text"
                icon={
                  <CrossIcon
                    style={{
                      width: '10px',
                      height: '22px',
                      marginRight: '8px',
                      marginLeft: '5px',
                    }}
                  />
                }
              >
                {t('users.delete-user')}
              </IconContextButton>{' '}
            </StyledPopconfirm>
          )}
        </Space>
      </div>
    );
  };

  const renderExtraButtons = (record?: User) => {
    if (
      currentUser?.accountType &&
      AccountType[currentUser.accountType] === AccountType.OWNER &&
      currentUser.accountUuid === record?.accountUuid
    ) {
      return (
        <Popover
          placement="topLeft"
          arrow={false}
          trigger="click"
          content={renderContextMenu(record)}
          title={''}
          overlayStyle={{ zIndex: 999 }}
        >
          <ContextButton type="text">
            <EllipseIcon
              style={{
                height: '10px',
              }}
            />
          </ContextButton>
        </Popover>
      );
    }
  };

  const isEnterprisePlan = () => {
    const enterprise = subscriptionPlanTypes.find(
      (plan) =>
        plan.name.toLowerCase() ==
        SubscriptionPlan.ENTERPRISE_PLAN.toLowerCase()
    );
    return currentUser?.typeId == enterprise?.uuid;
  };

  return (
    <ProfileWrapper>
      <Spin size="large" spinning={loading}>
        <Row>
          <Col
            span={20}
            xs={{
              offset: 0,
            }}
            sm={{ offset: 0 }}
            md={{ offset: 0 }}
            lg={{
              offset: 0,
            }}
            xl={{
              offset: 0,
              span: 24,
            }}
            xxl={{
              offset: 2,
              span: 16,
            }}
          >
            <Space align="start" style={{ columnGap: '25px' }}>
              <div style={{ height: '100vh', position: 'fixed' }}>
                <StyledCard width="270px">
                  {isCurrentUser ? (
                    <>
                      <img
                        height={150}
                        width={150}
                        className="profile-rounded-image"
                        alt=""
                        src={
                          profilePicture.startsWith('data')
                            ? profilePicture
                            : `data:image/png;base64,${profilePicture}`
                        }
                      />
                      <div
                        className="mask"
                        onClick={() => openEditImageWindow()}
                      >
                        <EyeOutlined style={{ margin: '4px' }} />
                        <span>{t('common.preview')}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <Image
                        height={150}
                        width={150}
                        className="profile-rounded-image"
                        preview={
                          profilePicture === defaultUserPicture
                            ? false
                            : {
                                destroyOnClose: true,
                                toolbarRender: () => null,
                              }
                        }
                        alt=""
                        src={
                          profilePicture?.startsWith('data')
                            ? profilePicture
                            : `data:image/png;base64,${profilePicture}`
                        }
                      />
                    </>
                  )}
                  {/*  <Image
                height={150}
                width={150}
                className="profile-rounded-image"
                preview={{
                  destroyOnClose: true,
                  imageRender: ()=> openEditImageWindow(),
                  toolbarRender: () => null,
                }}
                //visible={}
                src={profilePicture}
              />*/}
                  <Typography
                    style={{
                      fontSize: '18px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      textAlign: 'center',
                      color: '#042A2A',
                      marginBottom: '2px',
                    }}
                  >
                    {`${selectedAccount?.firstName} ${selectedAccount?.lastName}`}
                  </Typography>
                  {isCurrentUser && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {renderUserSubscription()}
                    </div>
                  )}
                  <Typography
                    style={{
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '18px',
                      textAlign: 'center',
                      color: '#042A2A',
                      marginBottom: '10px',
                    }}
                  >
                    {selectedAccount?.email}
                  </Typography>
                  <div style={{ margin: ' 0 36%' }}>
                    {selectedAccount?.accountType &&
                      renderRole(selectedAccount?.accountType)}
                  </div>
                  <Divider />
                  <Space direction="vertical" style={{ marginBottom: '20px' }}>
                    {selectedAccount?.companyName && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('signup.company-name')}</GraySpan>
                        <BlackSpan>{selectedAccount?.companyName}</BlackSpan>
                      </Space>
                    )}

                    {selectedAccount?.phoneNumber && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('signup.phone')}</GraySpan>
                        <BlackSpan>{selectedAccount?.phoneNumber}</BlackSpan>
                      </Space>
                    )}

                    {selectedAccount?.website && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('common.website')}</GraySpan>
                        <BlackSpan>{selectedAccount?.website}</BlackSpan>
                      </Space>
                    )}

                    {selectedAccount?.timezone && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('common.timezone')}</GraySpan>
                        <BlackSpan>{selectedAccount?.timezone}</BlackSpan>
                      </Space>
                    )}
                  </Space>
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {isCurrentUser && (
                      <ScopeButton
                        type="primary" //@ts-ignore
                        width="100%"
                        height="32px"
                        onClick={() => redirectPage(pricingPageLink)}
                      >
                        {/*                         <Link
                          to={pricingPageLink}
                          style={{ textDecoration: 'none' }}
                        > */}
                        {t('common.pricing-plans')}
                        {/*  </Link> */}
                      </ScopeButton>
                    )}
                    {isCurrentUser && (
                      <ScopeButton
                        type="default" //@ts-ignore
                        width="100%"
                        height="32px"
                        onClick={() => openEditDetails()}
                      >
                        {t('common.edit')}
                      </ScopeButton>
                    )}
                  </Space>
                </StyledCard>
              </div>
              <div>
                {showCard &&
                  !isEnterprisePlan() && ( //@ts-ignore
                    <StyledCard
                      height="1090px"
                      width="851px"
                      nopadding
                      removeHeader
                      style={{
                        marginLeft: '300px',
                        maxWidth: '1000px',
                        minWidth: '900px',
                        marginBottom: '20px',
                      }}
                      extra={
                        <IconClose
                          style={{
                            top: '20',
                            position: 'absolute',
                            right: '25',
                            cursor: 'pointer',
                            filter:
                              'invert(71%) sepia(12%) saturate(247%) hue-rotate(131deg) brightness(80%) contrast(81%)',
                          }}
                          onClick={() => setShowCard(false)}
                        />
                      }
                    >
                      {/*                     <Space> */}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexFlow: 'row',
                          alignContent: 'normal',
                        }}
                      >
                        <div
                          style={{
                            padding: '20px 0px',
                            paddingTop: '48px',
                            paddingLeft: '95px',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '24px',
                              fontWeight: '600',
                              lineHeight: '26px',
                              textAlign: 'left',
                              color: '#042A2A',
                              marginBottom: '10px',
                            }}
                          >
                            <CardDescGreenSpan>Unlock</CardDescGreenSpan> All
                            Features.
                            <br />
                            Simplify VAT, Amplify Efficiency <br />
                            <CardDescBoldSpan>
                              from{' '}
                              <CardDescBoldSpan //@ts-ignore
                                fontSize="16px"
                                fontWeight="700"
                              >
                                29 Eur{' '}
                              </CardDescBoldSpan>
                              <CardDescBoldGraySpan>
                                per month
                              </CardDescBoldGraySpan>
                            </CardDescBoldSpan>
                          </Typography>
                          <List
                            style={{ marginBottom: '15px' }}
                            dataSource={[
                              'More VAT Functionality',
                              'Connect more entities',
                              'Integrations with Xero, Excel and more!',
                            ]}
                            split={false}
                            size="small"
                            renderItem={(item) => (
                              <List.Item style={{ padding: '0px 3px' }}>
                                <CheckIcon></CheckIcon>{' '}
                                <BlackSpan>{item}</BlackSpan>
                              </List.Item>
                            )}
                          />

                          <YellowButton
                            type="primary" //@ts-ignore
                            width="139px"
                            height="32"
                            fontSize="13px"
                            className="yellow-btn"
                            color="#042A2A"
                            borderColor="#FFBC3E"
                            onClick={() => redirectPage(pricingPageLink)}
                          >
                            {/*                           <Link
                            to={pricingPageLink}
                            style={{ textDecoration: 'none' }}
                          > */}
                            {t('billing.upgrade-now')}
                            {/* </Link> */}
                          </YellowButton>
                        </div>
                        <img
                          alt="upgrade-pic"
                          style={{ width: '404px' }}
                          src={UpgradeImage}
                        />
                      </div>
                      {/*                     </Space> */}
                    </StyledCard>
                  )}
                <StyledCard //@ts-ignore
                  height="1090px"
                  width="851px"
                  style={{
                    marginLeft: '300px',
                    maxWidth: '1000px',
                    minWidth: '900px',
                  }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    items={
                      isCurrentUser
                        ? items
                        : items.filter((item) => item.key != 'Billing Details')
                    }
                    tabBarExtraContent={{
                      right: renderExtraButtons(selectedAccount),
                    }}
                  />
                </StyledCard>
              </div>
            </Space>
          </Col>
        </Row>
      </Spin>
    </ProfileWrapper>
  );
};
