import { call, put } from 'redux-saga/effects';
import {
  billingCheckoutFailure,
  changeSubscriptionPlanFailure,
} from '../actions';
import { ApiError } from 'store/api/types';
import {
  BillingCheckoutRequestAction,
  ChangeSubscriptionPlanRequestAction,
} from '../types';
import { checkResponse } from 'store/utils';
import {
  ChangeSubscriptionPlanResponse,
  CheckoutResponse,
  changeSubscriptionPlan,
  checkout,
} from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { navigateToExternalPage } from 'utils/validation/common';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* CheckoutSaga(action: BillingCheckoutRequestAction) {
  try {
    let err: ApiError | null;
    const { billingCheckout } = action.payload;

    const checkoutResponse: CheckoutResponse = yield call(
      checkout,
      billingCheckout
    );

    err = checkResponse(checkoutResponse?.responseMessage);
    if (err) throw err;

    yield call(navigateToExternalPage, checkoutResponse?.data?.responseBody);

    /*     const message: MessageData = {
      title: 'Success !',
      description: checkoutResponse.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message)); */
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    console.log('message', message, error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(billingCheckoutFailure());
  }
}
