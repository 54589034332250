import { MessageStates } from 'containers/MessageBox';
import { MessageData } from 'store/app/types';
const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export function renderErrorMessage(error: any) {
  console.log('error', error);
  let status = error?.status;
  if (
    typeof error.status === 'string' &&
    error.status === INTERNAL_SERVER_ERROR
  ) {
    status = 500;
  }
  const description =
    status === 500
      ? 'An unexpected error occurred, please contact support if problem persists.'
      : error.message;
  if (error?.status !== 401 && error?.status !== 403) {
    const message: MessageData = {
      title: 'Server error',
      description,
      type: MessageStates.ERROR,
    };
    return message;
  } else {
    return null;
  }
}
