import { call, put } from 'redux-saga/effects';
import { updateCardFailure, updateCardSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { UpdateCardRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import { updateCard, UpdateCardResponse } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { navigateToExternalPage } from 'utils/validation/common';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* updateCardSaga(action: UpdateCardRequestAction) {
  try {
    let err: ApiError | null;
    const { accountId } = action.payload;

    const updateCardResponse: UpdateCardResponse = yield call(
      updateCard,
      accountId
    );

    err = checkResponse(updateCardResponse?.responseMessage);
    if (err) throw err;

    yield call(navigateToExternalPage, updateCardResponse?.data?.url);
    /*     yield put(
      updateCardSuccess(
        updateCardResponse?.data
      )
    ); */
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(updateCardFailure());
  }
}
