import { takeLatest } from 'redux-saga/effects';
import { BILLING_CHECKOUT_REQUEST, CANCEL_SUBSCRIPTION_REQUEST, CHANGE_SUBSCRIPTION_PLAN_REQUEST, FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST, FETCH_SUBSCRIPTION_TYPES_REQUEST, GET_CURRENT_SUBSCRIPTION_REQUEST, GET_INVOICES_REQUEST, UPDATE_CARD_REQUEST, VALIDATE_VAT_NUMBER_REQUEST } from '../constants';
import { validateVATNumberSaga } from './validateVATNumberSaga';
import { fetchSubscriptionTypesSaga } from './fetchSubscriptionTypes';
import { changeSubscriptionPlanSaga } from './changeSubscriptionPlanSaga';
import { fetchSubscriptionPlanPricingDetailsSaga } from './fetchSubscriptionPlanPricingDetailsSaga';
import { CheckoutSaga } from './checkoutSaga';
import { getCurrentSubscriptionSaga } from './getCurrentSubscriptionSaga';
import { getInvoicesSaga } from './getInvoicesSaga';
import { updateCardSaga } from './updateCardSaga';
import { cancelSubscriptionSaga } from './cancelSubscriptionSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* billingSaga() {
  yield takeLatest(VALIDATE_VAT_NUMBER_REQUEST, validateVATNumberSaga);
  yield takeLatest(FETCH_SUBSCRIPTION_TYPES_REQUEST, fetchSubscriptionTypesSaga);
  yield takeLatest(CHANGE_SUBSCRIPTION_PLAN_REQUEST, changeSubscriptionPlanSaga);
  yield takeLatest(FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST, fetchSubscriptionPlanPricingDetailsSaga);
  yield takeLatest(GET_CURRENT_SUBSCRIPTION_REQUEST, getCurrentSubscriptionSaga);
  yield takeLatest(GET_INVOICES_REQUEST, getInvoicesSaga);
  yield takeLatest(UPDATE_CARD_REQUEST, updateCardSaga);
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionSaga);
  yield takeLatest(BILLING_CHECKOUT_REQUEST, CheckoutSaga);
}
