import { call, put } from 'redux-saga/effects';
import { fetchCurrenciesFailure, fetchCurrenciesSuccess } from '../actions';
import { CurrenciesResponse, getCurrencies } from '../api';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnDrawerOff, turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchCurrenciesSaga() {
  try {
    let err: ApiError | null;

    const response: CurrenciesResponse = yield call(getCurrencies);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const currencies: string[] = response?.data?.currencies;
    yield put(fetchCurrenciesSuccess(currencies));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(turnDrawerOff());
      yield put(logoutRequest());
    }
    yield put(fetchCurrenciesFailure());
  }
}
