import { VatReport } from 'components/Vat/Report';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import routes from 'routes';
import {
  addNoteRequest,
  awaitingApprovalRequest,
  cancelReturnRequest,
  changeStatusAfterUpdate,
  clientApproveRequest,
  exceptionsActionRequest,
  fetchActivityLogsRequest,
  fetchExcelFileRequest,
  fetchRunDataRequest,
  fetchVatLogsRequest,
  fetchViewRunDataRequest,
  generateReportRequest,
  internallyApproveRequest,
  loadReconRequest,
  loadReportRequest,
  lockReturnRequest,
  notApproveRequest,
  saveReturnRequest,
  sendClientApprovalRequest,
  setUpdateFromPageRequest,
  submitReturnRequest,
  updateActivityRoleRequest,
} from 'store/vat/actions';
import {
  SaveReturnRequestData,
  VatGenerateRequestData,
  VatRunData,
} from 'store/vat/models';
import {
  getExcelFileData,
  getUpdateFromPage,
  getVatActivityLogs,
  getVatLoading,
  getVatNoDataFound,
  getVatPrepLoading,
  getVatReconciliation,
  getVatReportData,
  getVatReturnLogs,
  getVatRunData,
  getVatSuccess,
} from 'store/vat/selectors';
import { getAuthUser } from 'store/auth/selectors';
import {
  DataLogActions,
  DataLogStatus,
  DrawerType,
  TaxReportCountry,
  VatTaxReportType,
  WindowType,
} from 'store/app/enums';
import {
  ActivityRoleUpdateRequest,
  JSONModifiedTrn,
  JSONTotals,
  JSONTotalsRecapCyprus,
  JSONTotalsRecapMalta,
  TransactionType,
  VatLoadRequestData,
  VatRunRequestData,
  ViewRunRequestData,
} from 'store/vat/models/requestData';
import {
  turnDrawerOn,
  turnMessageOn,
  turnModalOff,
  turnModalOn,
} from 'store/app/actions';
import { MessageStates } from 'containers/MessageBox';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import { ClientApproveForm } from 'components/Vat/Report/client-approve-form';
import { EmailDataParametersRequest } from 'store/vat/models/emailDataParametersRequest';
import { NotApproveForm } from 'components/Vat/Report/not-approve-form';
import {
  getOrgMappedOrganisations,
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import { useTranslation } from 'react-i18next';
import { getUserMembers } from 'store/user/selectors';
import { ConnectEntity } from 'components/ConnectEntity';
import { Organisation } from 'store/organisation/models';
import { AccountType } from 'store/api/enums';
import { checkPageLoaded } from 'utils/check-page-loaded';
import { AdjustmentForm } from 'components/Vat/Report/adjustments-form';
import { DataJournal } from 'store/vat/models/vatReportData';
import {
  Preference,
  PreferenceActions,
  preferences,
  PreferenceSections,
} from 'utils/preferences';
import {
  ExceptionsActionType,
  ExceptionsWrapper,
} from 'store/vat/models/exceptionWrapper';
import { getMenuSize } from 'store/app/selectors';

export const PAGE_SIZE_TRANSACTIONS = 'page-size-transactions';
export const PAGE_SIZE_EXCEPTIONS = 'page-size-exceptions';
export const FILTERS_TRANSACTIONS = 'filters-transactions';
export const FILTERS_EXCEPTIONS = 'filters-exceptions';

interface Params {
  connectionId: string;
  mappingId: string;
  dataLogId: string;
}

export const VatReportPage: React.FC = () => {
  const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
  const vatRunData = useSelector(getVatRunData);
  const vatReportData = useSelector(getVatReportData);
  const members = useSelector(getUserMembers);
  const reconciliation = useSelector(getVatReconciliation);
  const activityLogs = useSelector(getVatActivityLogs);
  const prepLoading = useSelector(getVatPrepLoading);
  const noDataFound = useSelector(getVatNoDataFound);
  const loading = useSelector(getVatLoading);
  const success = useSelector(getVatSuccess);
  const currentUser = useSelector(getAuthUser);
  const menuSize = useSelector(getMenuSize);
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const mappings = useSelector(getOrgMappings);
  const excelFileData = useSelector(getExcelFileData);
  const organisations = useSelector(getOrgOrganisations);
  const selectedVatReturnLogs = useSelector(getVatReturnLogs);
  const updatingFromPage = useSelector(getUpdateFromPage);
  const dispatch = useDispatch();
  const location = useLocation();
  const params: Params = useParams();
  const connectionId = params.connectionId;
  const mappingId = params.mappingId;
  const dataLogId = params.dataLogId;
  const { t } = useTranslation();
  const history = useHistory();
  const [percent, setPercent] = React.useState<number>(0);
  const [resetValues, setResetValues] = React.useState(false);
  const [pages, setPages] = React.useState<number>(4);
  const [generatedDate, setGeneratedDate] = React.useState<string>(
    moment().format(DATE_FORMAT)
  );
  const [generatedDateUTC, setGeneratedDateUTC] = React.useState<string>(
    moment().utc().format(DATE_FORMAT)
  );
  const [cannotSave, setCannotSave] = React.useState<boolean>(false);
  const [handledExceptions, setHandledExceptions] = React.useState<
    ExceptionsWrapper[]
  >([]);
  const [orginalStatus, setOriginalStatus] = React.useState<any>();

  const handlePreferences = (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => {
    if (currentUser)
      return preferences(
        action,
        preferenceSection,
        preference,
        currentUser?.userUuid
      );
    return false;
  };
  const setUpdatingFromPage = (value: boolean) =>
    dispatch(setUpdateFromPageRequest(value));
  const resetFinished = () => setResetValues(false);
  const unblockRef = React.useRef();

  const selectedMapping = mappings?.find(
    (m) => m.mappingId === vatRunData?.mappingId
  );

  const retFreq = selectedMapping?.returnFrequency
    ? selectedMapping?.returnFrequency
    : '';

  const returnTypeId = selectedMapping?.returnTypeId
    ? selectedMapping?.returnTypeId
    : '';

  const goBack = () => {
    history.goBack();
  };

  const reloadReportData = (data: VatRunData, isSaved: boolean) => {
    const fromDateFormat = data.fromDate.includes('-')
      ? 'YYYY-MM-DD'
      : 'DD/MM/YYYY';
    const vatGenerateRequestData: VatGenerateRequestData = {
      connectionId: data.tokenId,
      mappingId: data.mappingId,
      fromDate: moment(data.fromDate, fromDateFormat).format('YYYY-MM-DD'),
      toDate: data.toDate,
      currency: data.currency,
      dataLogId: data.dataLogId,
    };

    setResetValues(true);
    setPercent(0);

    let pages = 0;
    if (
      currentUser &&
      AccountType[currentUser?.accountType] === AccountType.CLIENT
    ) {
      pages = 2;
    } else {
      //const additonalPages = data.dataLogId ? 1 : 0;
      //pages = 4 + additonalPages;
      pages = 5;

      if (data.taxType === VatTaxReportType.RECAP) pages = 3;
    }
    setPages(pages);
    if (
      data.dataLogId === undefined ||
      data.dataLogId === null ||
      (isSaved && !!data.dataLogId)
    ) {
      const journalsTotal =
        !vatReportData || !vatReportData.journals
          ? 0
          : vatReportData.journals.length;
      const exceptionsTotal =
        !vatReportData || !vatReportData.exceptions
          ? 0
          : vatReportData.exceptions.length;
      const totals = !isSaved ? 0 : journalsTotal + exceptionsTotal;

      dispatch(generateReportRequest(vatGenerateRequestData, totals));
    } else {
      const vatLoadRequestData: VatLoadRequestData = {
        ...vatGenerateRequestData,
        dataLogId: data.dataLogId,
      };
      dispatch(loadReportRequest(vatLoadRequestData));
      dispatch(fetchActivityLogsRequest(data.dataLogId));
    }
    const connectedProperly = mappedOrganisations?.find(
      (mo) => mo.connectionId === data.tokenId
    )?.connectedProperly;

    data.taxType !== VatTaxReportType.RECAP &&
      connectedProperly &&
      dispatch(loadReconRequest(data.tokenId, data.toDate));
  };

  const saveReturn = (data: VatRunData) => {
    if (
      data.status &&
      DataLogStatus[data.status] === DataLogStatus.EXCEPTIONS_MODIFIED
    ) {
      saveExceptionAction();
    } else {
      const jsonTotals: Array<
        JSONTotals | JSONTotalsRecapMalta | JSONTotalsRecapCyprus | any
      > = [];
      let jsonModifiedTrns: JSONModifiedTrn[] = [];
      if (data.taxType !== VatTaxReportType.RECAP) {
        vatReportData?.subsections.forEach((ss) =>
          jsonTotals.push({
            boxName: ss.code,
            boxValue: ss.net.toFixed(2),
            boxTaxValue: ss.tax.toFixed(2),
          })
        );
      } else {
        if (
          data.taxReportCountry === TaxReportCountry.MALTA ||
          data.taxReportCountry === TaxReportCountry.IRELAND_RECAP
        ) {
          vatReportData?.contacts.forEach((c) => {
            const isTriangularTrade = c.isTriangularTrade;
            const name = c.name;
            const country = c.country;
            const taxNumber = c.taxNumber;
            const viesStatus = c.viesStatus;
            const goodsSum = c.goodsSum;
            const servicesSum = c.servicesSum;
            jsonTotals.push({
              isTriangularTrade,
              name,
              country,
              taxNumber,
              viesStatus,
              goodsSum,
              servicesSum,
            });
          });
        }

        if (data.taxReportCountry === TaxReportCountry.CYPRUS) {
          vatReportData?.contacts.forEach((c) => {
            const country = c.country;
            const taxNumber = c.taxNumber;
            const viesStatus = c.viesStatus;
            const goodsSum = c.goodsSum;
            const triangularTradeSum = c.triangularTradeSum;
            const servicesSum = c.servicesSum;
            jsonTotals.push({
              country,
              taxNumber,
              viesStatus,
              goodsSum,
              triangularTradeSum,
              servicesSum,
            });
          });
        }
      }

      const transactionsMap = new Map();
      getModifiedTransactions(vatReportData?.journals, TransactionType.TRANSACTION, transactionsMap);
      getModifiedTransactions(vatReportData?.exceptions, TransactionType.EXCEPTION, transactionsMap);

      jsonModifiedTrns = Array.from(transactionsMap.values());

      const saveReturnRequestData: SaveReturnRequestData = {
        connectionId: data.tokenId,
        mappingId: data.mappingId,
        fromDate: data.fromDate,
        toDate: data.toDate,
        generatedDate: generatedDateUTC,
        currency: data.currency,
        reportingTypeId: data.reportingTypeId,
        dataLogId: data.dataLogId,
        jsonTotals,
        jsonModifiedTrns,
      };

      setUpdatingFromPage(true);

      dispatch(saveReturnRequest(saveReturnRequestData));
    }
  };

  const getModifiedTransactions = (journals, type, transactionsMap) => {
    journals.forEach((j) => {
      const uuid = j.uuid;
      const modifiedNetAmount = j.modifiedNetAmount;
      const modifiedTaxAmount = j.modifiedTaxAmount;
    
      if (!transactionsMap.has(uuid)) {
        transactionsMap.set(uuid, {
          uuid,
          modifiedNetAmount,
          modifiedTaxAmount,
          type,
        });
      } else if (!j.modifiedNetAmount || !j.modifiedTaxAmount) {
        let existing = transactionsMap.get(uuid);
        if (!j.modifiedNetAmount && j.modifiedTaxAmount) {
          transactionsMap.set(uuid, {
            ...existing,
            modifiedTaxAmount,
          });
        }
        if (j.modifiedNetAmount && !j.modifiedTaxAmount) {
          transactionsMap.set(uuid, {
            ...existing,
            modifiedNetAmount,
          });
        }
      }
    });
  }

  const cancelNotSaved = (data: VatRunData) => {
    const orgId = mappedOrganisations?.find(
      (mo) => mo.connectionId === data.tokenId
    )?.organisationId;
    orgId &&
      dispatch(push(routes.organisations.organisation.replace(':id', orgId)));
  };

  const revertData = (data: VatRunData) => {
    const viewRunRequestData: ViewRunRequestData = {
      connectionId: data.tokenId,
      mappingId: data.mappingId,
      dataLogId: data.dataLogId,
    };
    dispatch(fetchViewRunDataRequest(viewRunRequestData));
  };

  const cancelReturn = (data: VatRunData) => {
    const connectionId = data.tokenId;
    const mappingId = data.mappingId;
    const dataLogId = data.dataLogId;

    dispatch(cancelReturnRequest(dataLogId, connectionId, mappingId));
  };
  const getReportUrl = (data: VatRunData) =>
    process.env.PUBLIC_URL +
    routes.vat.viewReportLink
      .replace(':connectionId', data.tokenId)
      .replace(':mappingId', data.mappingId)
      .replace(':dataLogId', data.dataLogId);

  const setAsAwaitingApproval = (data: VatRunData) => {
    if (!data.reviewerId) {
      const message: MessageData = {
        title: 'Reviewer not set !',
        description:
          'Setting the status of the report as Awaiting Approval has failed because the reviewer has not been set. Please set the reviewer in order to do this action.',
        type: MessageStates.ERROR,
      };

      dispatch(turnMessageOn(message));
    } else {
      setUpdatingFromPage(true);
      dispatch(
        awaitingApprovalRequest(
          data.dataLogId,
          getReportUrl(data),
          data.mappingId
        )
      );
    }
  };

  const setInternallyApproved = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(
      internallyApproveRequest(
        data.dataLogId,
        getReportUrl(data),
        data.mappingId
      )
    );
  };

  const sendForClientApproval = (values: EmailDataParametersRequest) => {
    if (vatRunData) {
      const emailUrl = getReportUrl(vatRunData);
      const emailData: EmailDataParametersRequest = { ...values, emailUrl };
      setUpdatingFromPage(true);
      dispatch(
        sendClientApprovalRequest(
          vatRunData.dataLogId,
          emailData,
          vatRunData.mappingId
        )
      );
      dispatch(turnModalOff());
    }
  };
  const sendForClientApprovalWindow = (data: VatRunData) => {
    if (canLock()) {
      if (!data.clientId) {
        const message: MessageData = {
          title: 'Client is not set !',
          description:
            'Client has not been assigned yet. Please assign a client in order to proceed.',
          type: MessageStates.ERROR,
        };

        dispatch(turnMessageOn(message));
      } else {
        const drawerData: DrawerData = {
          title: t('common.send-to-client'),
          type: DrawerType.NOT_APPROVED_FORM,
          component: (
            <ClientApproveForm sendForClientApproval={sendForClientApproval} />
          ),
        };
        dispatch(turnDrawerOn(drawerData));
      }
    } else {
      const message: MessageData = {
        title: 'Exceptions not handled!',
        description: "You can't continue until all exceptions are handled.",
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
    }
  };
  const setClientApproved = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(
      clientApproveRequest(data.dataLogId, getReportUrl(data), data.mappingId)
    );
  };

  const updateActivityRole = (requestData: ActivityRoleUpdateRequest) => {
    setUpdatingFromPage(true);
    dispatch(updateActivityRoleRequest(requestData));
  };

  const setNotApprovedWindow = () => {
    const drawerData: DrawerData = {
      title: t('common.add-note'),
      type: DrawerType.NOT_APPROVED_FORM,
      component: <NotApproveForm setNotApproved={setNotApproved} />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const setNotApproved = (values: EmailDataParametersRequest) => {
    if (vatRunData) {
      const emailUrl = getReportUrl(vatRunData);
      const emailData: EmailDataParametersRequest = { ...values, emailUrl };
      setUpdatingFromPage(true);
      dispatch(
        notApproveRequest(vatRunData.dataLogId, emailData, vatRunData.mappingId)
      );
      dispatch(turnModalOff());
    }
  };

  const markReturnAsSubmitted = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(submitReturnRequest(data.dataLogId, data.mappingId));
  };

  const markReturnAsLocked = (data: VatRunData) => {
    setUpdatingFromPage(true);
    dispatch(lockReturnRequest(data.dataLogId, data.mappingId));
  };

  const updateNote = (noteText: string, dataLogId: string) => {
    if (!noteText || noteText === '') {
      const message: MessageData = {
        title: 'Empty note text !',
        description: 'You cannot save an empty field',
        type: MessageStates.WARNING,
      };

      dispatch(turnMessageOn(message));
    } else {
      dispatch(addNoteRequest(noteText, dataLogId));
    }
  };

  const cancelWarningWindow = (data: VatRunData) => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title: 'Cancel report!',
      component: (
        <ConfirmBoxContainer
          callback={cancelReturn}
          param={data}
          description="This will cancel current report. Continue?"
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const canLock = () => {
    let canLock = true;
    const exceptions = vatReportData?.exceptions;
    if (
      exceptions &&
      exceptions?.findIndex(
        (e) =>
          e.exceptionActionType &&
          (ExceptionsActionType[e.exceptionActionType] ===
            ExceptionsActionType.UNRESOLVED ||
            ExceptionsActionType[e.exceptionActionType] ===
              ExceptionsActionType.DEFERRED_PREVIOUSLY)
      ) > -1
    )
      canLock = false;
    return canLock;
  };

  const loackWarningWindow = (
    vatRunData: VatRunData,
    actionType: DataLogActions
  ) => {
    if (canLock()) {
      let callback;
      if (actionType == DataLogActions.LOCK_AS_APPROVED) {
        callback = () => markReturnAsLocked(vatRunData);
      } else if (actionType == DataLogActions.MARK_AS_SUBMITTED) {
        callback = () => markReturnAsSubmitted(vatRunData);
      }

      const modalData: ModalData = {
        type: WindowType.WARNING,
        title: 'This action will permanently lock the tax submission',
        component: (
          <ConfirmBoxContainer
            callback={callback}
            showCancelBtn={true}
            param2={vatRunData}
            okText="Proceed"
            description="Please confirm you have done all necessary reviews and the data can be locked."
          />
        ),
      };

      dispatch(turnModalOn(modalData));
    } else {
      const message: MessageData = {
        title: 'Exceptions not handled!',
        description: `You can't ${
          actionType == DataLogActions.LOCK_AS_APPROVED ? 'lock' : 'submit'
        } the report until all exceptions are handled.`,
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
    }
  };

  const goBackWarningWindow = (data: VatRunData, location: any) => {
    const navigate = () => {
      if (unblockRef) {
        //@ts-ignore
        unblockRef.current();
      }
      dispatch(turnModalOff());
      sessionStorage.removeItem('last-created-report');
      history.push(location.pathname);
    };

    if (
      (!data.status ||
        DataLogStatus[data.status] === DataLogStatus.NOT_SAVED ||
        DataLogStatus[data.status] === DataLogStatus.EXCEPTIONS_MODIFIED) &&
      !cannotSave
    ) {
      const modalData: ModalData = {
        type: WindowType.WARNING,
        title: 'Your report is not saved, do you want to save report?',
        component: (
          <ConfirmBoxContainer
            callback={navigate}
            callback2={saveReturn}
            showCancelBtn={false}
            param2={data}
            okText="Continue without Saving"
            okText2="Save Submission"
            description="Are you sure you want to go back? This generated tax report is not saved. Once you click ‘Continue’, you will loose this generated report."
          />
        ),
      };

      dispatch(turnModalOn(modalData));
    } else goBack();
  };

  const getExcelFileForExport = () => {
    //@ts-ignore
    dispatch(fetchExcelFileRequest(vatRunData?.reportingTypeId));
  };

  const onReconnect = () => {
    const drawerData: DrawerData = {
      title: 'Connect entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  useEffect(() => {
    if (
      vatRunData &&
      organisations &&
      (!vatRunData.status ||
        DataLogStatus[vatRunData.status] !== DataLogStatus.EXCEPTIONS_MODIFIED)
    ) {
      const selectedOrg = organisations?.find(
        (o) => o.connectionId === vatRunData?.tokenId
      ) as Organisation;
      if (
        !vatRunData.status ||
        DataLogStatus[vatRunData.status] !== DataLogStatus.NOT_SAVED
      ) {
        if (updatingFromPage) {
          setUpdatingFromPage(false);
        } else {
          reloadReportData(vatRunData, false);
        }

        if (vatRunData.dataLogId) {
          setGeneratedDate(
            moment
              .utc(vatRunData.generatedDate, DATE_FORMAT)
              .local()
              .format(DATE_FORMAT)
          );
        }
      } else {
        setGeneratedDate(moment().format(DATE_FORMAT));
        setGeneratedDateUTC(moment().utc().format(DATE_FORMAT));
      }

      const noSave =
        (!vatRunData.isPeriodClosed && !vatRunData.dataLogId) ||
        (vatRunData.status !== DataLogStatus.CANCELLED &&
          vatRunData.status !== DataLogStatus.SUBMITTED &&
          vatRunData.isFirstTime === false &&
          vatRunData.validRange !== null &&
          vatRunData.validRange === false); //||
      // (vatRunData.isFirstTime === true &&
      //   vatRunData.validRange !== null &&
      //   vatRunData.validRange === false);
      setCannotSave(noSave);
      // selectedOrg?.connectedProperly &&
      //   dispatch(fetchAddReturnDataRequest(vatRunData?.tokenId));
      vatRunData &&
        dispatch(
          fetchVatLogsRequest(vatRunData?.tokenId, [vatRunData?.mappingId])
        );
    } //else if (!loading) dispatch(push(routes.main));
  }, [vatRunData, members, organisations]);

  useEffect(() => {
    if (vatReportData) {
      if (vatReportData.totalPages === 0 || !vatReportData.totalPages) {
        setPercent(100);
      } else {
        const singlePage = Math.round(100 / vatReportData.totalPages);
        const percentage =
          vatReportData.totalPages === vatReportData.page
            ? 100
            : singlePage * vatReportData.page;
        setPercent(percentage);
      }
    } else {
      setPercent(0);
    } //@ts-ignore
    unblockRef.current = history.block((location: any, _action: any) => {
      if (
        (!vatRunData?.status ||
          DataLogStatus[vatRunData.status] === DataLogStatus.NOT_SAVED ||
          DataLogStatus[vatRunData.status] ===
            DataLogStatus.EXCEPTIONS_MODIFIED) &&
        !cannotSave &&
        vatReportData
      ) {
        vatRunData && goBackWarningWindow(vatRunData, location);
        return false;
      }
      return true;
    });

    return () => {
      //@ts-ignore
      unblockRef.current && unblockRef.current();
    };
  }, [JSON.stringify(vatReportData), JSON.stringify(vatRunData)]);

  // const prepareTaxRateOptions = () => {
  //   const opts: TaxRateOption[] = [];
  //   returnData?.taxRates?.map((tr) =>
  //     opts.push({
  //       value: tr.taxType,
  //       label: `${tr.name} [${tr.taxRate.toString()}%]`,
  //     })
  //   );
  //   return opts;
  // };

  // const sortSelectionsByReturnType = (returnType: string) => {
  //   //setSelectedReturnType(returnType);
  //   // const hasCA = returnData?.returnTypesEdit?.find(
  //   //   (rt) => rt.id === returnType
  //   // )?.cashAccountingEnabled;

  //   //hasCA ? setCashAccounting(true) : setCashAccounting(false);
  //   // const sec = returnData?.sections?.filter((s) => {
  //   //   return (
  //   //     s.returnTypeId === returnType ||
  //   //     (hasCA && s.name === s.subsections[0].code)
  //   //   );
  //   // });

  //   //sec?.sort((a, b) => a.sortOrder - b.sortOrder);
  //   //sec && setSections(sec);
  //   //const ooc = sec?.find((s) => s.subsections[0].outOfScope)?.subsections[0]
  //   //  .code;
  //   //ooc && setOutOfScopeCode(ooc);
  // };

  // const setMappingData = (data: MappingData) => {
  //   dispatch(updateMappingData(data));
  // };

  // const loadExistingMapping = (mapId: string, isTemplate = false) => {
  //   const savingMapId = isTemplate ? vatRunData?.mappingId : mapId;
  //   const selMap = mappings?.find((m) => m.mappingId === mapId);

  //   const savingPeriodFromDate = isTemplate
  //     ? mappingData?.periodFromDate
  //     : selMap?.periodFromDate;
  //   const savingReturnDueDate = isTemplate
  //     ? mappingData?.returnDueDate
  //     : selMap?.returnDueDate;
  //   const savingReturnFrequency = isTemplate
  //     ? mappingData?.returnFrequency
  //     : selMap?.returnFrequency;
  //   let jsonMapping;
  //   if (selMap?.jsonMapping) {
  //     try {
  //       const parsedMapping = JSON.parse(selMap.jsonMapping);

  //       jsonMapping = parsedMapping?.mapping;
  //     } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //     }
  //   }
  //   if (
  //     returnData?.taxRates &&
  //     returnData.taxRates.length < jsonMapping.length
  //   ) {
  //     const toDelete = jsonMapping.filter(
  //       (jm) =>
  //         returnData.taxRates?.findIndex((tr) => tr.taxType === jm.taxType) ===
  //         -1
  //     );
  //     toDelete.map((td) => {
  //       jsonMapping.splice(
  //         jsonMapping.findIndex((jm) => jm.taxType === td.taxType),
  //         1
  //       );
  //     });
  //   }

  //   const mapData = {
  //     mappingId: savingMapId,
  //     templateMappingId: mapId,
  //     typeId: returnData?.returnTypesEdit?.find(
  //       (rt) => rt.name === selMap?.mappingName
  //     )?.id,
  //     countryId: returnData?.returnTypesEdit?.find(
  //       (rt) => rt.name === selMap?.mappingName
  //     )?.countryId,
  //     mappedCashAccounting: selMap?.cashAccounting,
  //     periodFromDate: moment(savingPeriodFromDate).format('YYYY-MM-DD'),
  //     returnDueDate: moment(savingReturnDueDate).format('YYYY-MM-DD'),
  //     returnFrequency: savingReturnFrequency,
  //     jsonMapping,
  //   };
  //   setMappingData(mapData);
  // };

  // useEffect(() => {
  //   if (!preLoading) {
  //     const retTypes = returnData?.returnTypesEdit;
  //     if (retTypes && retTypes.length > 0) {
  //       vatRunData?.mappingId && loadExistingMapping(vatRunData?.mappingId);
  //     }
  //   }
  // }, [preLoading, mappings]);

  // useEffect(() => {
  //   if (returnData?.taxRates) {
  //     const opts: TaxRateOption[] = prepareTaxRateOptions();
  //     //setTaxRateOptions(opts);
  //     //setOutOfScopeOptions(opts);
  //     mappingData?.typeId && sortSelectionsByReturnType(mappingData?.typeId);
  //   }
  // }, [returnData]);

  useEffect(() => {
    if (dataLogId) {
      const viewRunRequestData: ViewRunRequestData = {
        connectionId,
        mappingId,
        dataLogId,
      };
      dispatch(fetchViewRunDataRequest(viewRunRequestData));
    } else {
      const lastCreatedReport = sessionStorage.getItem('last-created-report');
      if (!lastCreatedReport) {
        dispatch(push(routes.main));
      } else {
        const vatRunRequestData: VatRunRequestData =
          JSON.parse(lastCreatedReport);
        !vatRunData && dispatch(fetchRunDataRequest(vatRunRequestData));
      }
    }
    currentUser && checkPageLoaded(location.pathname, routes.vat.root);
  }, []);

  const openAdjustmentPanal = (
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void
  ) => {
    const drawerData: DrawerData = {
      title: 'Adjustment',
      type: DrawerType.ADJUSTMENT_FORM,
      component: (
        <AdjustmentForm
          selectedRowKeys={selectedRowKeys}
          addExceptionAction={addExceptionAction}
          selectedVatReturnLogs={selectedVatReturnLogs}
          setSelectedRowKey={setSelectedRowKey}
        />
      ),
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const addExceptionAction = (
    exceptionActionType: string,
    selectedRowKeys: DataJournal[],
    setSelectedRowKey: (data: DataJournal[]) => void,
    adjustmentPeriodId: string | null = null,
    adjustDate: any = null
  ) => {
    const tempExceptions = [...handledExceptions];

    selectedRowKeys.forEach((srk) => {
      const procExeption: ExceptionsWrapper = {
        exceptionUuid: srk.uuid,
        exceptionActionType,
        adjustmentPeriodId,
        adjustDate: adjustDate ? adjustDate : null,
      };
      const idx = tempExceptions.findIndex(
        (te) => te.exceptionUuid === srk.uuid
      );

      if (
        ExceptionsActionType[exceptionActionType] ===
        ExceptionsActionType.UNRESOLVED
      ) {
        tempExceptions.splice(idx, 1);
      } else {
        if (idx === -1) tempExceptions.push(procExeption);
        else tempExceptions.splice(idx, 1, procExeption);
      }

      vatReportData?.exceptions.map((vrd) => {
        const idx = selectedRowKeys.findIndex((srt2) => srt2.uuid === vrd.uuid);
        const idx2 = handledExceptions.findIndex(
          (he) => he.exceptionUuid === vrd.uuid
        );

        if (vrd.uuid === srk.uuid)
          vrd.exceptionActionType = exceptionActionType;
        else if (idx === -1 && idx2 === -1)
          if (!vrd.exceptionActionType) vrd.exceptionActionType = 'UNRESOLVED';
      });
    });
    setHandledExceptions(tempExceptions);
    setSelectedRowKey([]);
    if (
      ExceptionsActionType[exceptionActionType] !==
      ExceptionsActionType.UNRESOLVED
    ) {
      let actionString = '';
      if (
        ExceptionsActionType[exceptionActionType] ===
        ExceptionsActionType.ADJUSTMENT
      )
        actionString = 'an Adjustment to a prior period';
      if (
        ExceptionsActionType[exceptionActionType] ===
        ExceptionsActionType.CURRENT_RETURN
      )
        actionString = 'Included in this submission';
      if (
        ExceptionsActionType[exceptionActionType] === ExceptionsActionType.DEFER
      )
        actionString = 'Deferred to the next submission';
      const message: MessageData = {
        title: 'Action done',
        description: `The selected exceptions have been marked as 
                    ${actionString}. 
                    You need to save your changes to process this request.`,
        type: MessageStates.SUCCESS,
        duration: 5,
      };
      dispatch(turnMessageOn(message));
    }
    if (vatRunData) {
      if (vatRunData.status)
        DataLogStatus[vatRunData.status] !==
          DataLogStatus.EXCEPTIONS_MODIFIED &&
          setOriginalStatus(DataLogStatus[vatRunData.status]);
      dispatch(changeStatusAfterUpdate(DataLogStatus.EXCEPTIONS_MODIFIED));
    }
  };

  const saveExceptionAction = () => {
    const exceptionsWrapper = [...handledExceptions];
    exceptionsWrapper.map((ew) => {
      if (ew.adjustmentPeriodId === 'OOS') ew.adjustmentPeriodId = null;
    });
    vatRunData &&
      dispatch(
        exceptionsActionRequest(
          vatRunData?.dataLogId,
          vatRunData?.mappingId,
          exceptionsWrapper
        )
      );
    setHandledExceptions([]);
    setUpdatingFromPage(true);
    dispatch(changeStatusAfterUpdate(orginalStatus));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Tax return | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for users" />
      </Helmet>

      {vatRunData && currentUser && (
        <VatReport
          loading={loading || prepLoading}
          success={success}
          vatRunData={vatRunData}
          vatReportData={vatReportData}
          generatedDate={generatedDate}
          reloadReportData={reloadReportData}
          currentUser={currentUser}
          loadingPercent={percent}
          resetValues={resetValues}
          resetFinished={resetFinished}
          reconciliation={reconciliation}
          pages={pages}
          saveReturn={saveReturn}
          revertData={revertData}
          cancelReturn={cancelWarningWindow}
          setAwaitingApproval={setAsAwaitingApproval}
          setInternallyApproved={setInternallyApproved}
          updateActivityRole={updateActivityRole}
          sendForClientApproval={sendForClientApprovalWindow}
          setClientApproved={setClientApproved}
          setNotApproved={setNotApprovedWindow}
          cancelNotSaved={cancelNotSaved}
          activityLogs={activityLogs}
          updateNote={updateNote}
          cannotSave={cannotSave}
          menuSize={menuSize}
          currentOrganisation={mappedOrganisations?.find(
            (mo) => mo.connectionId === vatRunData.tokenId
          )}
          retFreq={retFreq}
          returnTypeId={returnTypeId}
          onReconnect={onReconnect}
          getExcelFileForExport={getExcelFileForExport}
          excelFileData={excelFileData}
          loackWarningWindow={loackWarningWindow}
          openAdjustmentPanal={openAdjustmentPanal}
          handlePreferences={handlePreferences}
          addExceptionAction={addExceptionAction}
          noDataFound={noDataFound}
        />
      )}
    </React.Fragment>
  );
};
