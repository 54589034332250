import { all, call, delay, put, select } from 'redux-saga/effects';
import { ApiError } from 'store/api/types';
import { FetchViesInfoRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import moment from 'moment';
import { GetViesInfoResponse, getViesInfo } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { fetchViesInfoFailure, fetchViesInfoSuccess } from '../actions';
import {
  getOrgLoading,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import { Organisation } from 'store/organisation/models';
import {
  fetchOrganisationsRequest,
  setGettingTaxes,
} from 'store/organisation/actions';
import { ViesInfo } from '../models';
import { getViesInfos } from '../selectors';
import { getAuthUser } from 'store/auth/selectors';
import { User } from 'store/auth/models';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

interface SavedInfos {
  timestamp: string;
  viesInfos: ViesInfo[];
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchViesInfoSaga(action: FetchViesInfoRequestAction) {
  const REFRESH_TIMEOUT = 15;
  let currentUser: User = yield select(getAuthUser);
  do {
    yield delay(500);
    currentUser = yield select(getAuthUser);
  } while (!currentUser);

  const fileName = `vies-infos-${currentUser.userUuid}`;
  try {
    let err: ApiError | null;
    const { connectionId } = action.payload;
    let orgs: Organisation[] = yield select(getOrgOrganisations);

    if (!orgs) {
      yield put(fetchOrganisationsRequest());
      let orgsLoading: boolean = yield select(getOrgLoading);
      do {
        yield delay(500);
        orgs = yield select(getOrgOrganisations);
        orgsLoading = yield select(getOrgLoading);
      } while (!orgs && orgsLoading);

      //orgs = yield select(getOrgOrganisations);
    }

    if (orgs) {
      if (connectionId) {
        const response: GetViesInfoResponse = yield call(
          getViesInfo,
          connectionId
        );
        err = checkResponse(response?.responseMessage);
        if (err) throw err;
        const selOrg = orgs.find((o) => o.connectionId === connectionId);

        if (selOrg) {
          const organisationName = selOrg.friendlyName;
          const vatCheckerActive = selOrg.vatCheckerActive;
          const providerName = selOrg.providerName;
          const shortCode = selOrg.shortCode;
          yield put(
            fetchViesInfoSuccess([
              {
                ...response.data,
                organisationName,
                vatCheckerActive,
                providerName,
                shortCode,
              },
            ])
          );
        } else {
          const apiError = {
            message: 'Problem loading the entity',
          };
          throw apiError;
        }
      } else {
        const allCalls: any[] = [];
        for (let i = 0; i < orgs.length; i++) {
          const connId = orgs[i].connectionId;
          allCalls.push(call(getViesInfo, connId));
        }
        const savedViesInfos = localStorage.getItem(fileName);
        const savedInfos: SavedInfos = savedViesInfos
          ? JSON.parse(savedViesInfos)
          : null;

        const infos = savedInfos?.viesInfos;
        const timestamp = savedInfos?.timestamp
          ? moment(savedInfos?.timestamp)
          : null;

        if (!timestamp) {
          localStorage.removeItem(fileName);
        }

        const timeToRefresh = timestamp
          ? moment().isAfter(timestamp.add(REFRESH_TIMEOUT, 'minutes'))
          : true;
        if (!timeToRefresh) yield put(infos && fetchViesInfoSuccess(infos));
        else {
          yield put(setGettingTaxes(true));
          const newResponse: GetViesInfoResponse[] = yield all(allCalls);
          for (let i = 0; i < orgs.length; i++) {
            //const connectionId = orgs[i].connectionId;
            const response = newResponse.find(
              (r) => r.data.connectionId === orgs[i].connectionId
            );
            const organisationName = orgs[i].friendlyName;
            const vatCheckerActive = orgs[i].vatCheckerActive;
            const providerName = orgs[i].providerName;
            const shortCode = orgs[i].shortCode;
            // const response: GetViesInfoResponse = yield call(
            //   getViesInfo,
            //   connectionId
            // );
            if (response) {
              err = checkResponse(response?.responseMessage);
              if (err) throw err;

              yield put(
                fetchViesInfoSuccess([
                  {
                    ...response.data,
                    organisationName,
                    vatCheckerActive,
                    providerName,
                    shortCode,
                  },
                ])
              );
            }
          }
          const viesInfos = yield select(getViesInfos);
          viesInfos.length > 0
            ? localStorage.setItem(
                fileName,
                JSON.stringify({ timestamp: moment(), viesInfos })
              )
            : localStorage.removeItem(fileName);
          yield put(setGettingTaxes(false));
        }
      }
    }
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchViesInfoFailure());
  }
}
