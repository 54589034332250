import React from 'react';
import { Collapse, CollapseProps, List, Space, Spin, Typography } from 'antd';
import { StyledCard } from 'components/Custom/card';
import {
  BlackSpan,
  CardDescBoldGraySpan,
  CardDescBoldSpan,
  CardDescGreenSpan,
  CardDescSpan,
  TableItemSpan,
  TableTitleSpan,
} from 'components/Custom/spans';
import { ReactComponent as CheckIcon } from 'assets/images/icons/checkIcon.svg';
import { ReactComponent as CheckDisabledIcon } from 'assets/images/icons/checkDisabledIcon.svg';
import UpgradeImage from 'assets/images/upgrade-pic.svg';
import { ScopeButton, SelectButton } from 'components/Custom/buttons';
import { Currency, SubscriptionPlan } from 'store/api/enums';
import { CustomTable } from 'components/Custom/custom-table';
import { Feature } from 'store/billing/models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { navigateToExternalPage } from 'utils/validation/common';
import Features from 'store/billing/models/features.json';
import {
  calcPriceCurrency,
  getPriceByCurrency,
} from 'utils/calcAmountCurrency';
import { SelectedPlan } from 'store/billing/types';
import { ReactComponent as SmallIcon } from 'assets/images/icons/iconSmall.svg';
import { ReactComponent as MediumIcon } from 'assets/images/icons/iconMedium.svg';
import { ReactComponent as LargeIcon } from 'assets/images/icons/iconLarge.svg';
import { ReactComponent as EnterpriseIcon } from 'assets/images/icons/iconEnterprise.svg';
import { SubscriptionType } from 'store/billing/models/subscriptionType';
import { User } from 'store/auth/models';

const QuestionCollapseWrapper = styled.div`
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #dbeae3;
  }

  .ant-collapse-header {
    padding: 16px 0px 16px 0px !important;
  }

  .ant-collapse-content-box {
    padding: 0px 0px 35px 0px !important;
  }
`;

interface PricingComponentProps {
  selectedPlan: SelectedPlan;
  activePlane: (
    plan: SubscriptionPlan,
    selectedCurrency: Currency,
    price: string,
    licenseId: string
  ) => void;
  subscriptionPlanTypes: SubscriptionType[];
  currentUser: User;
  isLoading: boolean;
  renderContactUsWindow: () => void;
}

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
  {
    key: '2',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
  {
    key: '3',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
  {
    key: '4',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
  {
    key: '5',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
  {
    key: '6',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
  {
    key: '7',
    label: (
      <CardDescBoldSpan fontSize="16px" lineHeight="28px">
        {'Lorem ipsum question?'}
      </CardDescBoldSpan>
    ),
    children: (
      <CardDescSpan>
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum venenatis nunc non ex congue condimentum. Quisque eu risus id tortor consequat suscipit a aliquet risus. Quisque eget elementum enim. Aliquam accumsan in quam ac elementum. Sed lorem dui, viverra et arcu vitae, auctor ultrices ligula.'
        }
      </CardDescSpan>
    ),
  },
];

export const PricingComponent: React.FC<PricingComponentProps> = ({
  selectedPlan,
  activePlane,
  subscriptionPlanTypes,
  currentUser,
  isLoading,
  renderContactUsWindow,
}) => {
  const { t } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = React.useState(
    selectedPlan.selectedCurrency
  );
  const [subscriptionTypes, setSubscriptionTypes] = React.useState<
    SubscriptionType[]
  >([]);

  React.useEffect(() => {
    if (subscriptionPlanTypes) {
      setSubscriptionTypes(subscriptionPlanTypes);
    }
  }, [subscriptionPlanTypes]);

  const dataSource = Features;

  const renderFeature = (content, record: Feature) => {
    if (content) {
      if (record.isCategoryFeature && !record.hasPrice) {
        return (
          <TableItemSpan fontWeight="600" lineHeight="35px">
            {content}
          </TableItemSpan>
        );
      } else if (record.isCategoryFeature && record.hasPrice) {
        return (
          <TableItemSpan fontWeight="600" fontSize="18px" lineHeight="87px">
            {content}
          </TableItemSpan>
        );
      } else {
        return <TableItemSpan>{content}</TableItemSpan>;
      }
    }
  };

  const getPlanFeature = (record: Feature, planType: SubscriptionPlan) => {
    if (!record.hasPrice) {
      switch (planType) {
        case SubscriptionPlan.SMALL_PLAN:
          return record.small;
          break;
        case SubscriptionPlan.MEDIUM_PLAN:
          return record.medium;
          break;
        case SubscriptionPlan.LARGE_PLAN:
          return record.large;
          break;
        case SubscriptionPlan.ENTERPRISE_PLAN:
          return record.enterprise;
          break;
        default:
          break;
      }
    }
  };

  const renderFeatureValue = (
    content,
    record: Feature,
    planType: SubscriptionPlan
  ) => {
    const currentSubscriptionType = subscriptionTypes.find(
      (plan) => plan.name.toLowerCase() == planType.toLowerCase()
    );
    if (record.hasPrice && !record.isCategoryFeature) {
      const featurePrice = getPriceByCurrency(
        selectedCurrency,
        record.featureId,
        planType.replace(' Plan', '').toLowerCase()
      );
      return (
        <TableItemSpan fontWeight="600">
          {featurePrice}
          {record.hasPerEntity && featurePrice != 'n/a' ? ' / Entity' : ''}
        </TableItemSpan>
      );
    } else if (typeof content == 'boolean') {
      if (content) {
        return <CheckIcon />;
      } else {
        return <CheckDisabledIcon />;
      }
    } else if (record.isCategoryFeature && record.hasPrice) {
      if (
        planType.toLowerCase() == SubscriptionPlan.ENTERPRISE_PLAN.toLowerCase()
      ) {
        return (
          <Space direction="vertical">
            <TableItemSpan fontWeight="600">POR</TableItemSpan>
            <ScopeButton
              disabled={currentSubscriptionType?.uuid == currentUser?.typeId}
              type="default"
              width={'90px'}
              height="25px"
              lineheight="12px"
              fontSize="12px"
              onClick={() => {
                renderContactUsWindow();
              }}
            >
              {currentSubscriptionType?.uuid == currentUser?.typeId
                ? t('billing.subscribed')
                : t('billing.contact-us')}
            </ScopeButton>
          </Space>
        );
      } else {
        return (
          <Space direction="vertical">
            <TableItemSpan fontWeight="600">
              {getPriceByCurrency(
                selectedCurrency,
                record.featureId,
                planType.replace(' Plan', '').toLowerCase()
              )}
            </TableItemSpan>
            <ScopeButton
              disabled={currentSubscriptionType?.uuid == currentUser?.typeId}
              type="default"
              width={'90px'}
              height="25px"
              lineheight="12px"
              fontSize="12px"
              onClick={() => {
                const plan = planType.split(' ')[0]?.toLowerCase();
                const priceFeature = Features.find(
                  (item) => item.feature?.toLowerCase() == 'price'
                );
                const currentSubscriptionType = subscriptionTypes.find(
                  (planType) => planType.name.toLowerCase() == plan
                );
                activePlane(
                  planType,
                  selectedCurrency,
                  priceFeature[plan],
                  currentSubscriptionType?.uuid
                );
              }}
            >
              {currentSubscriptionType?.uuid == currentUser?.typeId
                ? t('billing.subscribed')
                : t('billing.activate')}
            </ScopeButton>
          </Space>
        );
      }
    } else if (record.hasPerEntity && record.hasPrice) {
    } else {
      return (
        <TableItemSpan fontWeight="600">
          {getPlanFeature(record, planType)}
        </TableItemSpan>
      );
    }
  };

  const columns = [
    {
      title: (
        <CardDescBoldSpan fontSize="24px" fontWeight="400">
          Our Pricing
        </CardDescBoldSpan>
      ),
      dataIndex: 'feature',
      //key: 'organisation',
      width: '200px',
      render: renderFeature,
    },
    {
      title: (
        <TableTitleSpan>
          <Space direction="vertical">
            <SmallIcon />
            SMALL
          </Space>
        </TableTitleSpan>
      ),
      dataIndex: 'small',
      //key: 'organisation',
      width: '80px',
      textWrap: 'word-break',
      align: 'center',
      //ellipsis: true,
      render: (content, record: Feature) =>
        renderFeatureValue(content, record, SubscriptionPlan.SMALL_PLAN),
    },
    {
      width: '10px',
    },
    {
      title: (
        <TableTitleSpan>
          <Space direction="vertical">
            <MediumIcon />
            MEDIUM
          </Space>
        </TableTitleSpan>
      ),
      dataIndex: 'medium',
      //key: 'organisation',
      width: '80px',
      textWrap: 'word-break',
      align: 'center',
      render: (content, record: Feature) =>
        renderFeatureValue(content, record, SubscriptionPlan.MEDIUM_PLAN),
    },
    {
      width: '10px',
    },
    {
      title: (
        <TableTitleSpan>
          <Space direction="vertical">
            <LargeIcon />
            LARGE
          </Space>
        </TableTitleSpan>
      ),
      dataIndex: 'large',
      //key: 'organisation',
      width: '80px',
      textWrap: 'word-break',
      align: 'center',
      render: (content, record: Feature) =>
        renderFeatureValue(content, record, SubscriptionPlan.LARGE_PLAN),
    },
    {
      width: '10px',
    },
    {
      title: (
        <TableTitleSpan>
          <Space direction="vertical">
            <EnterpriseIcon />
            ENTERPRISE
          </Space>
        </TableTitleSpan>
      ),
      dataIndex: 'enterprise',
      //key: 'organisation',
      width: '80px',
      textWrap: 'word-break',
      align: 'center',
      render: (content, record: Feature) =>
        renderFeatureValue(content, record, SubscriptionPlan.ENTERPRISE_PLAN),
    },
    {
      width: '10px',
    },
  ];

  return (
    <Spin spinning={isLoading} size="large">
      <div style={{ padding: '20px 80px' }}>
        <Space
          style={{ columnGap: '82px', width: '100%', marginBottom: '60px' }}
        >
          <StyledCard //@ts-ignore
            height="1090px"
            width="851px"
            borderless
            stopShadowBox
            nopadding
            removeHeader
            bg={'linear-gradient(180deg, #EDFAF6 0%, #E1F4FE 100%)'}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexFlow: 'row',
                alignContent: 'normal',
              }}
            >
              <div
                style={{
                  padding: '20px 0px',
                  paddingTop: '48px',
                  paddingLeft: '95px',
                }}
              >
                <Typography
                  style={{
                    fontSize: '24px',
                    fontWeight: '600',
                    lineHeight: '26px',
                    textAlign: 'left',
                    color: '#042A2A',
                    marginBottom: '32px',
                  }}
                >
                  <CardDescBoldSpan>
                    from{' '}
                    <CardDescBoldSpan fontWeight="700" fontSize="45px">
                      {calcPriceCurrency(selectedCurrency, false)}{' '}
                    </CardDescBoldSpan>
                    <CardDescBoldSpan fontWeight="700">
                      {Currency[selectedCurrency]}{' '}
                    </CardDescBoldSpan>
                    <CardDescBoldGraySpan>per month</CardDescBoldGraySpan>
                  </CardDescBoldSpan>
                </Typography>
                <List
                  style={{ marginBottom: '15px' }}
                  dataSource={[
                    'More VAT Functionality',
                    'Connect more entities',
                    'Unlimited users',
                    'Integrations with Xero, Excel and more!',
                  ]}
                  split={false}
                  size="small"
                  renderItem={(item) => (
                    <List.Item style={{ padding: '2px 3px' }}>
                      <CheckIcon></CheckIcon> <BlackSpan>{item}</BlackSpan>
                    </List.Item>
                  )}
                />
              </div>
              <img
                alt="upgrade-pic"
                style={{ width: '404px' }}
                src={UpgradeImage}
              />
            </div>
          </StyledCard>
          <Typography
            style={{
              fontSize: '32px',
              fontWeight: '600',
              lineHeight: '34px',
              textAlign: 'left',
              color: '#042A2A',
              marginBottom: '10px',
              minWidth: 200,
            }}
          >
            <CardDescGreenSpan fontSize="32px" fontWeight="700">
              Unlock All Features.
            </CardDescGreenSpan>
            <br />
            Simplify VAT, <br />
            Amplify Efficiency <br />
          </Typography>
        </Space>
        <Space
          align="center"
          style={{
            width: '100%',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
        >
          {Object.keys(Currency).map((key) => {
            return (
              <SelectButton
                key={key}
                type="default" //@ts-ignore
                width="55px"
                height="30px"
                color="#042A2A"
                border_color="#DBEAE3"
                lineheight="12px"
                fontSize="12px"
                selected={selectedCurrency == key}
                onClick={() => setSelectedCurrency(key)}
              >
                {Currency[key]}
              </SelectButton>
            );
          })}
        </Space>
        <div style={{ marginBottom: '90px' }}>
          <CustomTable
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
        <TableItemSpan fontSize="24px" fontWeight="400" lineHeight="34px">
          FAQs
        </TableItemSpan>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            columnGap: '120px',
            paddingTop: '30px',
          }}
        >
          <StyledCard //@ts-ignore
            height="315px"
            width="265px"
            borderless
            stopShadowBox
            nopadding
            removeHeader
            bg_color={'#E9F6F7'}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                columnGap: '150px',
              }}
            >
              <div
                style={{
                  padding: '110px 48px 35px 44px',
                }}
              >
                <Space direction="vertical" style={{ rowGap: 20 }}>
                  <CardDescBoldSpan fontSize="24px" lineHeight="28px">
                    {t('billing.have-a-question')}
                  </CardDescBoldSpan>

                  <a
                    href="https://generate.tax/contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ScopeButton
                      type="primary"
                      width="111px"
                      height="30px"
                      fontWeight="700"
                      fontSize="13px"
                      lineheight="20px"
                    >
                      {t('billing.contact-us')}
                    </ScopeButton>
                  </a>
                </Space>
              </div>
            </div>
          </StyledCard>
          <QuestionCollapseWrapper>
            <Collapse
              style={{ width: '60vw', backgroundColor: 'white' }}
              defaultActiveKey={['1']}
              bordered={false}
              //onChange={onChange}
              expandIconPosition={'end'}
              items={items}
            />
          </QuestionCollapseWrapper>
        </div>
      </div>
    </Spin>
  );
};
