import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { List, Popover, Space, Spin, Typography } from 'antd';
import {
  ContextButton,
  GrayButton,
  IconContextButton,
  LinkButton,
  ScopeButton,
  YellowButton,
} from 'components/Custom/buttons';
import { StyledCard } from 'components/Custom/card';
import { RoundInput } from 'components/Custom/inputs';
import {
  BlackSpan,
  CardDescBoldGraySpan,
  CardDescBoldSpan,
  CardDescGreenSpan,
  CardTitleLargeSpan,
  ColoredTag,
  GrayItalicSpan,
  ListItemSpan,
  TableItemSpan,
} from 'components/Custom/spans';
import { ScopeTable } from 'components/Custom/table';
import { CardInfo } from 'components/OrganisationDetails/card-info';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Currency, SubscriptionPlan } from 'store/api/enums';
import { BillingLog, Plan } from 'store/billing/models';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as SmallIcon } from 'assets/images/icons/iconSmall.svg';
import { ReactComponent as MediumIcon } from 'assets/images/icons/iconMedium.svg';
import { ReactComponent as LargeIcon } from 'assets/images/icons/iconLarge.svg';
import { ReactComponent as EnterpriseIcon } from 'assets/images/icons/iconEnterprise.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/checkIcon.svg';
import UpgradeImage from 'assets/images/upgrade-pic.svg';
import VisaIcon from 'assets/images/icons/visaIcon.svg';
import MasterCardIcon from 'assets/images/icons/mastercardIcon.svg';
import { calcPriceCurrency, getCurrencySymbol } from 'utils/calcAmountCurrency';
import { SelectedPlan } from 'store/billing/types';
import { Invoice } from 'store/billing/models/invoice';
import { PaymentMethod } from 'store/app/enums';
import { CurrentSubscription } from 'store/billing/models/currentSubscription';
import moment from 'moment';
import { SubscriptionType } from 'store/billing/models/subscriptionType';

interface BillingComponentProps {
  backToChangePlan: () => void;
  getFeaturesOfPlans: () => Plan[];
  editPlan: () => void;
  redirectPage: (url: string) => void;
  renderCancelPlanWindow: () => void;
  updateCard: () => void;
  currentSubscription: CurrentSubscription;
  invoices: Invoice[];
  isLoading: boolean;
  subscriptionPlanTypes: SubscriptionType[];
  checkoutToUpgradePlan: (typeId: string) => void;
  renderContactUsWindow: (isEnterprise: boolean) => void;
  downgradePlan: (plan: string) => void;
}

const billingLogs: BillingLog = [
  {
    InvoiceId: '1',
    invoiceNumber: 'INV 123456',
    invoiceDate: '14/02/2025',
    status: 'billed',
    amount: '€300.00',
  },
  {
    InvoiceId: '2',
    invoiceNumber: 'INV 123789',
    invoiceDate: '18/08/2025',
    status: 'cancelled',
    amount: '€500.00',
  },
];
const pricingPageLink = '/pricing';

export const BillingComponent: React.FC<BillingComponentProps> = ({
  backToChangePlan,
  getFeaturesOfPlans,
  editPlan,
  currentSubscription,
  redirectPage,
  renderCancelPlanWindow,
  invoices,
  updateCard,
  isLoading,
  subscriptionPlanTypes,
  checkoutToUpgradePlan,
  renderContactUsWindow,
  downgradePlan,
}) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = React.useState<Invoice[]>();
  const [paymentMethod, setPaymentMethod] = React.useState(
    PaymentMethod.MASTERCARD
  );

  const getPlanIcon = (currentPlan) => {
    switch (currentPlan) {
      case SubscriptionPlan.SMALL_PLAN:
        return (
          <SmallIcon
            style={{
              borderRadius: '50px',
            }}
          />
        );
        break;
      case SubscriptionPlan.MEDIUM_PLAN:
        return (
          <MediumIcon
            style={{
              borderRadius: '50px',
            }}
          />
        );
        break;
      case SubscriptionPlan.LARGE_PLAN:
        return (
          <LargeIcon
            style={{
              borderRadius: '50px',
            }}
          />
        );
        break;
      case SubscriptionPlan.ENTERPRISE_PLAN:
        return (
          <EnterpriseIcon
            style={{
              borderRadius: '50px',
            }}
          />
        );
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    setDataSource(invoices);
  }, [invoices]);

  const upgradePlan = (planName: string) => {
    const currentPlan = subscriptionPlanTypes.find(
      (type) => type.name.toLowerCase() == planName.toLowerCase()
    );
    checkoutToUpgradePlan(currentPlan?.uuid);
  };

  const renderPaymentMethod = () => {
    let defaultPaymentMethod = currentSubscription?.paymentMethodDetails.find(
      (method) => method.default
    );
    defaultPaymentMethod = defaultPaymentMethod
      ? defaultPaymentMethod
      : currentSubscription?.paymentMethodDetails[0];
    if (
      defaultPaymentMethod?.brand?.toLowerCase() ==
      PaymentMethod.MASTERCARD.toLowerCase()
    ) {
      return (
        <div
          style={{
            display: 'flex',
            columnGap: 4,
            marginTop: 3,
            flexWrap: 'wrap',
          }}
        >
          <img
            alt="master-card-card"
            style={{ height: '25px', marginRight: 2 }}
            src={MasterCardIcon}
          />
          <TableItemSpan fontSize="14px" fontWeight="500" lineHeight="29px">
            {t('billing.master-card')}
          </TableItemSpan>{' '}
          <GrayItalicSpan lineHeight="29px"> ending in </GrayItalicSpan>
          <TableItemSpan fontSize="14px" fontWeight="500" lineHeight="29px">
            {defaultPaymentMethod?.last4}
          </TableItemSpan>
          <EditOutlined
            style={{ marginLeft: 32, color: '#879494' }}
            onClick={updateCard}
          />
          <ListItemSpan
            style={{ marginLeft: 0 }}
            fontSize="11px"
            lineHeight="29px"
          >
            Expires{' '}
            {`${defaultPaymentMethod?.expMonth}/${defaultPaymentMethod?.expYear}`}
          </ListItemSpan>
        </div>
      );
    } else {
      return (
        <>
          <div
            style={{
              display: 'flex',
              //justifyContent: 'space-between',
              columnGap: 80,
            }}
          >
            <div
              style={{
                display: 'flex',
                columnGap: 4,
                marginTop: 3,
                flexWrap: 'nowrap',
              }}
            >
              <img
                alt="visa-card"
                style={{ height: '25px', marginRight: 2 }}
                src={VisaIcon}
              />
              <TableItemSpan fontSize="14px" fontWeight="500" lineHeight="29px">
                {t('billing.visa-card')}{' '}
              </TableItemSpan>{' '}
              <GrayItalicSpan lineHeight="29px"> ending in </GrayItalicSpan>{' '}
              <TableItemSpan fontSize="14px" fontWeight="500" lineHeight="29px">
                {defaultPaymentMethod?.last4}
              </TableItemSpan>
            </div>
            <EditOutlined style={{ color: '#879494' }} onClick={updateCard} />
          </div>
          <ListItemSpan
            style={{ marginLeft: 0 }}
            fontSize="11px"
            lineHeight="29px"
          >
            Expires{' '}
            {`${defaultPaymentMethod?.expMonth}/${defaultPaymentMethod?.expYear}`}
          </ListItemSpan>
        </>
      );
    }
  };

  const renderPlanOverviewCard = () => {
    return (
      <StyledCard //@ts-ignore
        //width={'fit-content'}
        width="320px"
        style={{ marginTop: '30px' }}
      >
        <div style={{ margin: '10px 0px 20px 0px' }}>
          <TableItemSpan fontSize="18px" fontWegiht="400" lineHeight="24px">
            {t('billing.plan-overview')}
          </TableItemSpan>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '25px',
            marginBottom: '20px',
            borderBottom: '1px solid #DBEAE3',
            flexDirection: 'column',
            rowGap: 5,
            overflow: 'auto',
            maxWidth: 1000,
            width: '100%' /*minWidth: 916.6*/,
          }}
        >
          <CardInfo
            label={t('billing.plan-name')}
            data={currentSubscription?.description + ' Plan'}
          />
          <CardInfo
            label={t('billing.billing-frequency')}
            data={currentSubscription?.billingFrequency}
          />
          <CardInfo
            label={t('billing.next-billing-date')}
            data={
              currentSubscription?.endDate
                ? moment(currentSubscription?.endDate).format('DD/MM/YYYY')
                : 'N/A'
            }
          />
          <CardInfo
            label={t('billing.amount')}
            data={`${getCurrencySymbol(
              currentSubscription?.currency?.toUpperCase()
            )} ${currentSubscription?.totalAmount}`}
          />
          <CardInfo
            label={t('billing.payment-method')}
            data={renderPaymentMethod()}
          />
        </div>
        <div
          style={{
            paddingBottom: '10px',
            marginBottom: '10px',
            //borderBottom: '1px solid #DBEAE3',
            overflow: 'auto',
            maxWidth: 1000,
            width: '100%' /*minWidth: 916.6*/,
          }}
        >
          <TableItemSpan fontSize="16px" fontWeight="500" lineHeight="22px">
            {t('billing.add-ons')}
          </TableItemSpan>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <ListItemSpan lineHeight="22px">
              {' '}
              {t('billing.vat-Number-checker')}{' '}
              <TableItemSpan fontWeight="600" lineHeight="22px">
                x{currentSubscription?.vatNumberChecker}
              </TableItemSpan>
            </ListItemSpan>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemSpan lineHeight="22px">
              {' '}
              {t('billing.additional-organisations')}{' '}
              <TableItemSpan fontWeight="600" lineHeight="22px">
                x{currentSubscription?.additionalOrganisations}
              </TableItemSpan>
            </ListItemSpan>
          </div>
        </div>
        {/*<div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableItemSpan fontSize="14px" fontWeight="700" lineHeight="30px">
            {t('billing.total')}
          </TableItemSpan>
          <TableItemSpan fontSize="14px" fontWeight="700" lineHeight="30px">
            {'Є 123'}
          </TableItemSpan>
        </div>*/}
        <ScopeButton
          type="primary" //@ts-ignore
          width="246px"
          height="32px"
          style={{ margin: '0 12px', marginTop: '18px' }}
          onClick={editPlan}
        >
          {t('billing.edit-plan')}
        </ScopeButton>
        <ScopeButton
          type="default" //@ts-ignore
          width="246px"
          height="32px"
          style={{ margin: '0 12px', marginTop: '18px' }}
          onClick={renderCancelPlanWindow}
        >
          {t('billing.cancel-plan')}
        </ScopeButton>
      </StyledCard>
    );
  };

  const renderUpgradeButton = (
    plan: Plan,
    currentPlanIndex: number,
    index: number
  ) => {
    if (
      plan.planName?.toLowerCase() ==
      SubscriptionPlan.ENTERPRISE_PLAN.toLowerCase()
    ) {
      return (
        <ScopeButton //@ts-ignore
          type="primary"
          height="32px"
          width="123px"
          fontSize="13px"
          lineheight="22px"
          onClick={() => renderContactUsWindow(true)}
        >
          {t('billing.contact-us')}
        </ScopeButton>
      );
    } else {
      if (currentPlanIndex > index) {
        return (
          <ScopeButton //@ts-ignore
            type="primary"
            height="32px"
            width="130px"
            fontSize="13px"
            lineheight="22px"
            onClick={() => {
              const currentPlan = subscriptionPlanTypes.find(
                (type) => type.name.toLowerCase() == plan.planName.toLowerCase()
              );
              downgradePlan(currentPlan?.uuid);
            }}
          >
            {t('billing.downgrade-plan')}
          </ScopeButton>
        );
      } else if (currentPlanIndex < index) {
        return (
          <ScopeButton //@ts-ignore
            type="primary"
            height="32px"
            width="123px"
            fontSize="13px"
            lineheight="22px"
            onClick={() => upgradePlan(plan.planName)}
          >
            {t('billing.upgrade-plan')}
          </ScopeButton>
        );
      }
    }
  };

  const renderPlanTypeCard = () => {
    const currentPlanIndex = getFeaturesOfPlans().findIndex(
      (plan) =>
        plan.planName.toLowerCase() ==
        currentSubscription?.description?.toLowerCase()
    );
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '25px',
          columnGap: 20,
        }}
      >
        {getFeaturesOfPlans().map((plan, index) => (
          <StyledCard //@ts-ignore
            key={plan.planName}
            className={
              plan.planName
                .toLowerCase()
                .includes(currentSubscription?.description?.toLowerCase())
                ? 'selected-plan-container'
                : 'non-selected-container'
            }
            style={{
              overflow: 'auto',
              width: '270px',
              height: '320px',
              //maxWidth: "180px",
              //minWidth: "165px",
            }}
          >
            <Space
              direction="vertical"
              style={{
                width: '100%',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              {getPlanIcon(plan.planName)}
              <TableItemSpan fontSize="16px" fontWegiht="400" lineHeight="24px">
                {plan.planName}
              </TableItemSpan>
              <ListItemSpan lineHeight="24px">
                {t('billing.connected-organisations')}
              </ListItemSpan>
              <TableItemSpan fontSize="26px" fontWegiht="400" lineHeight="40px">
                {plan.connectedOrganisations}
              </TableItemSpan>
            </Space>
            <Space
              direction="vertical"
              style={{ width: '100%', alignItems: 'center' }}
            >
              {plan.planName
                .toLowerCase()
                .includes(currentSubscription?.description?.toLowerCase()) ? (
                <GrayButton //@ts-ignore
                  height="32px"
                  width="123px"
                  fontSize="13px"
                  lineheight="22px"
                  disabled
                >
                  {t('billing.current-plan')}
                </GrayButton>
              ) : (
                renderUpgradeButton(plan, currentPlanIndex, index)
              )}
              <LinkButton
                type="link"
                color="#41AAAF"
                onClick={backToChangePlan}
              >
                {t('billing.more-details')}
              </LinkButton>
            </Space>
          </StyledCard>
        ))}
      </div>
    );
  };

  const renderStatus = (content, record) => {
    if (content == 'cancelled') {
      return (
        <ColoredTag
          color="#D13131" //@ts-ignore
          bgcolor="White"
          bordered={true}
          bordercolor="#DBEAE3"
          smaller={false}
        >
          {t('billing.cancelled')}
        </ColoredTag>
      );
    } else if (content == 'paid') {
      return (
        <ColoredTag
          color="#41AAAF" //@ts-ignore
          bgcolor="White"
          bordered={true}
          bordercolor="#DBEAE3"
          smaller={false}
        >
          {t('billing.billed')}
        </ColoredTag>
      );
    }
  };

  const renderAmountWithCurrency = (content, record: Invoice) => {
    return (
      <span>{`${getCurrencySymbol(
        record.currency.toUpperCase()
      )} ${content}`}</span>
    );
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const renderContextMenu = (record: Invoice) => {
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            /*icon={
                <EditIcon
                  style={{ width: '16px', height: '17px', marginRight: '7px' }}
                />
              }*/
            //onClick={() => {}}
          >
            <a
              href={record.hostedInvoiceUrl}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              {t('billing.view-invoice')}
            </a>
          </IconContextButton>

          <IconContextButton type="text">
            <a
              href={record.invoicePdf}
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              {t('billing.download-pdf')}
            </a>
          </IconContextButton>
        </Space>
      </div>
    );
  };

  const columns = [
    {
      title: t('billing.invoice-number'),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a, b) => a.invoiceNumber?.localeCompare(b.invoiceNumber),
      sortIcon: renderSortIcon,
    },
    {
      title: t('billing.invoice-period'),
      dataIndex: 'period_start',
      key: 'period_start',
      render: (content, record: Invoice) => {
        return content && `${moment(record.period_start).format('DD/MM/YYYY')} - ${moment(record.period_end).format('DD/MM/YYYY')}`;
      },
      sorter: (a, b) => a.invoiceDate?.localeCompare(b.invoiceDate),
      sortIcon: renderSortIcon,
    },
    {
      title: t('billing.invoice-date'),
      dataIndex: 'created',
      key: 'created',
      render: (content, record: Invoice) => {
        return content && moment(content).format('DD/MM/YYYY');
      },
      sorter: (a, b) => a.invoiceDate?.localeCompare(b.invoiceDate),
      sortIcon: renderSortIcon,
    },
    {
      title: t('main.status'),
      dataIndex: 'status',
      key: 'status',
      render: renderStatus,
      sorter: (a, b) => a.status?.localeCompare(b.status),
      sortIcon: renderSortIcon,
    },
    {
      title: t('billing.amount'),
      dataIndex: 'amountDue',
      key: 'amountDue',
      render: renderAmountWithCurrency,
      sorter: (a, b) => a.amountDue?.localeCompare(b.amountDue),
      sortIcon: renderSortIcon,
    },

    {
      //width: '140px',
      render: (_, record) => (
        <Popover
          placement="topLeft"
          arrow={false}
          trigger="click"
          content={renderContextMenu(record)}
          title={''}
          overlayStyle={{ zIndex: 999 }}
        >
          <ContextButton type="text">
            <EllipseIcon
              style={{
                height: '10px',
              }}
            />
          </ContextButton>
        </Popover>
      ),
    },
  ];

  const search = (e) => {
    const value = e.target.value;
    if (value != '') {
      const filterTable = invoices?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );

      setDataSource(filterTable);
    } else {
      setDataSource(invoices);
    }
  };

  const renderBillingHistoryCard = () => {
    return (
      <StyledCard
        width="100%"
        style={{
          overflow: 'auto',
          marginBottom: '25px',
        }}
      >
        <ScopeTable //@ts-ignore
          filters={[]}
          columns={columns}
          mainLocaleKey="users"
          originalDataSource={billingLogs} //@ts-ignore
          tableDataSource={dataSource}
          pagination={true}
          rowsPerPage={8}
          enableColumnsCustomization={false}
          rowHeight={45}
          titleComponent={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <CardTitleLargeSpan style={{ fontSize: 18, lineHeight: '35px' }}>
                {t('billing.billing-history')}
              </CardTitleLargeSpan>

              <RoundInput
                width={'200px'}
                height={'35px'}
                prefix={<SearchOutlined />}
                size="large"
                placeholder={'Search Bills'}
                onChange={search}
              />
            </div>
          }
        />
      </StyledCard>
    );
  };

  const renderFeaturesCard = () => {
    return (
      <StyledCard //@ts-ignore
        height="1090px"
        width="100%"
        borderless
        stopShadowBox
        nopadding
        removeHeader
        style={{ marginBottom: '25px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexFlow: 'row',
            alignContent: 'normal',
          }}
        >
          <div
            style={{
              padding: '20px 0px',
              paddingTop: '48px',
              paddingLeft: '105px',
            }}
          >
            <Typography
              style={{
                fontSize: '24px',
                fontWeight: '600',
                lineHeight: '26px',
                textAlign: 'left',
                color: '#042A2A',
                marginBottom: '10px',
              }}
            >
              <CardDescGreenSpan>Unlock All Features.</CardDescGreenSpan>
              <br />
              Simplify VAT, Amplify Efficiency <br />
              <CardDescBoldGraySpan>
                from{' '}
                <CardDescBoldSpan fontSize="16px" fontWeight="700">
                  29 Eur{' '}
                </CardDescBoldSpan>
                per month
              </CardDescBoldGraySpan>
            </Typography>
            <List
              style={{ marginBottom: '15px' }}
              dataSource={[
                'More VAT Functionality',
                'Connect more entities',
                'Integrations with Xero, Excel and more!',
              ]}
              split={false}
              size="small"
              renderItem={(item) => (
                <List.Item style={{ padding: '0px 3px' }}>
                  <CheckIcon></CheckIcon> <BlackSpan>{item}</BlackSpan>
                </List.Item>
              )}
            />

            <YellowButton
              type="primary" //@ts-ignore
              width="139px"
              height="32"
              fontSize="13px"
              className="yellow-btn"
              color="#042A2A"
              borderColor="#FFBC3E"
              onClick={() => redirectPage(pricingPageLink)}
            >
              {t('billing.upgrade-now')}
            </YellowButton>
          </div>
          <img
            alt="upgrade-pic"
            style={{ width: '404px', marginRight: 35 }}
            src={UpgradeImage}
          />
        </div>
      </StyledCard>
    );
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div className="entity-overview-tab">
        <div
          //style={{ float: 'left', width: '60%', margin: '0 10%' }}
          style={{ width: 'calc(100vw - 450px)' /*height: '100vh'*/ }}
        >
          <div
            style={{
              padding: '0 40px',
              width: '100%',
              margin: 'auto',
              //maxWidth: 1600,
              //minWidth: 1320,
              maxWidth: 1250,
              marginTop: '30px',
            }}
          >
            {currentSubscription?.description !=
              SubscriptionPlan.ENTERPRISE_PLAN && renderFeaturesCard()}
            {renderPlanTypeCard()}
            {renderBillingHistoryCard()}
          </div>
        </div>

        <div
          style={{
            height: '100vh',
            position: 'fixed',
            float: 'right',
            right: 40,
            //width: '25%',
          }}
        >
          {renderPlanOverviewCard()}
        </div>
      </div>
    </Spin>
  );
};
