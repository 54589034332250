import { CheckoutComponent } from 'components/Billing/checkout';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import routes from 'routes';
import { Currency, SubscriptionPlan } from 'store/api/enums';
import { getAuthUser } from 'store/auth/selectors';
import {
  billingCheckoutRequest,
  changeSubscriptionPlanRequest,
  fetchPlanPricingDetailsRequest,
  setCurrentPlan,
  validateVATNumberRequest,
  validateVATNumberSuccess,
} from 'store/billing/actions';
import {
  getBillingLoading,
  getIsVATNumberValid,
  getSelectedPlan,
  getSubscriptionPlanPricingDetails,
} from 'store/billing/selectors';
import { fetchCountriesExternalDataRequest } from 'store/common/actions';
import {
  getCommonCountries,
  getCountriesExternalData,
} from 'store/common/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';

export const CheckoutPage: React.FC = () => {
  const parameters: any = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const querpar = new URLSearchParams(location.search);

  const changePlan = querpar.get('changePlan');
  const licenseId = parameters.licenseId;
  const selectedPlan = useSelector(getSelectedPlan);
  const countriesExternalData = useSelector(getCountriesExternalData);
  const countries = useSelector(getCommonCountries);
  const currentUser = useSelector(getAuthUser);
  const isVATNumberValid = useSelector(getIsVATNumberValid);
  const isLoading = useSelector(getBillingLoading);
  const subscriptionPlanPricingDetails = useSelector(
    getSubscriptionPlanPricingDetails
  );
  const [hideBillingDetails, setHideBillingDetails] =
    React.useState<boolean>(false);
  const [showPage, setShowPage] = useState(false);

  React.useEffect(() => {
    setShowPage(true);
    currentUser && checkPageLoaded(location.pathname, routes.users);
    if (!subscriptionPlanPricingDetails) {
      dispatch(fetchPlanPricingDetailsRequest(licenseId));
    }
    dispatch(fetchCountriesExternalDataRequest());
  }, []);

  React.useEffect(() => {
    dispatch(fetchPlanPricingDetailsRequest(licenseId));
  }, [licenseId]);

  React.useEffect(() => {
    if (changePlan || currentUser?.typeId != null) {
      setHideBillingDetails(true);
    }
  }, [currentUser, changePlan]);

  const backToChangePlan = () => {
    dispatch(push(routes.pricing.root));
  };

  const changeCurrency = (newCurrency: Currency) => {
    dispatch(
      setCurrentPlan(selectedPlan.currentPlan, newCurrency, selectedPlan.price)
    );
  };

  const validateVatNumber = (countryCode: string, taxNumber: string) => {
    dispatch(validateVATNumberRequest(countryCode, taxNumber));
  };

  const resetValidationVatNumber = () => {
    dispatch(validateVATNumberSuccess(false));
  };

  const continueToCheckout = (billing: any) => {
    if (hideBillingDetails || currentUser?.typeId != null) {
      dispatch(
        changeSubscriptionPlanRequest(licenseId, billing?.additionalEntities)
      );
    } else {
      billing = { ...billing, accountId: currentUser?.accountUuid };
      billing = { ...billing, typeId: licenseId };
      dispatch(billingCheckoutRequest(billing));
    }
  };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Checkout | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for checkout" />
          </Helmet>
          <CheckoutComponent
            selectedPlan={selectedPlan}
            backToChangePlan={backToChangePlan}
            continueToCheckout={continueToCheckout}
            countriesExternalData={countriesExternalData}
            countries={countries}
            changeCurrency={changeCurrency}
            validateVatNumber={validateVatNumber}
            isVATNumberValid={isVATNumberValid}
            isLoading={isLoading}
            resetValidationVatNumber={resetValidationVatNumber}
            subscriptionPlanPricingDetails={subscriptionPlanPricingDetails}
            hideBillingDetails={hideBillingDetails}
          />
        </>
      )}
    </React.Fragment>
  );
};
