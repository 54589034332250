import { call, put } from 'redux-saga/effects';
import {
  fetchUserRightsFailure,
  fetchUserRightsSuccess,
  fetchUsersRequest,
} from '../actions';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { FetchUserRightsResponse, getUserRights } from '../api';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { FetchUserRightsRequestAction } from '../types';
import { UserRights } from '../models';
import { MessageStatus } from 'store/api/enums';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchUserRightsSaga(action: FetchUserRightsRequestAction) {
  try {
    let err: ApiError | null;
    const { accountId, userId } = action.payload;
    const response: FetchUserRightsResponse = yield call(
      getUserRights,
      accountId
    );

    err = checkResponse(response?.responseMessage);
    if (err && response.responseMessage.status === MessageStatus.UNAUTHORIZED) {
      yield put(fetchUsersRequest());
    }
    if (err) throw err;
    const userRights: UserRights[] = response.data.userRights.map(function (x) {
      x.userUuid = userId;
      return x;
    });

    yield put(fetchUserRightsSuccess(userRights));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchUserRightsFailure());
  }
}
