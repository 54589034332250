import { all, fork } from 'redux-saga/effects';
import { authSaga } from './auth/sagas';
import { accountSaga } from './account/sagas';
import { commonSaga } from './common/sagas';
import { signupSaga } from './signup/sagas';
import { organisationSaga } from './organisation/sagas';
import { returnSaga } from './returns/sagas';
import { userSaga } from './user/sagas';
import { vatSaga } from './vat/sagas';
import { vatCheckerSaga } from './vat-checker/sagas';
import { billingSaga } from './billing/sagas';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(accountSaga),
    fork(signupSaga),
    fork(commonSaga),
    fork(organisationSaga),
    fork(returnSaga),
    fork(userSaga),
    fork(vatSaga),
    fork(vatCheckerSaga),
    fork(billingSaga),
  ]);
}
