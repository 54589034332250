import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { BillingState } from './types';

export const billingSelector = (state: StoreState): BillingState =>
  state.billing;

export const getSelectedPlan = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['selectedPlan'] => billing.selectedPlan
);

export const getIsVATNumberValid = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['isVATNumberValid'] =>
    billing.isVATNumberValid
);

export const getBillingLoading = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['loading'] => billing.loading
);

export const getSubscriptionPlanTypes = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['licenseTypes'] => billing.licenseTypes
);

export const getSubscriptionPlanPricingDetails = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['priceDetails'] => billing.priceDetails
);

export const getInvoices = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['invoices'] => billing.invoices
);

export const getCurrentSubscription = createSelector(
  billingSelector,
  (billing: BillingState): BillingState['currentSubscription'] => billing.currentSubscription
);
