import * as React from 'react';
import { Select, Space } from 'antd';
import {
  ActivityLog,
  ActivityRoleUpdateRequest,
  VatRunData,
} from 'store/vat/models';
import { ReactComponent as IconNote } from 'assets/images/icons/iconNote.svg';
import { ReactComponent as IconBell } from 'assets/images/icons/iconBell.svg';
import { ReactComponent as GreenDot } from 'assets/images/greenDot.svg';
import { ActivityRoles, DataLogStatus, DrawerType } from 'store/app/enums';
import { Input } from 'antd';
import styled from 'styled-components';
import { ExportButton, ScopeButton } from 'components/Custom/buttons';
import { FormSelect } from 'components/Custom/selects';
import { DrawerData } from 'store/app/types';
import { ActivityLogs } from './activity-logs';
import { useDispatch } from 'react-redux';
import { turnDrawerOn } from 'store/app/actions';
import { RenderUserDetail } from 'utils/render-user-details';
import { AccountType } from 'store/api/enums';
import { User } from 'store/auth/models';

const ActivityWrapper = styled.div`
  border-top: 1px solid #dbeae3;
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  align-items: center;

  .activity-settings-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .selects-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .selects-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .linethru {
    width: 1px;
    height: 10px;
    background: red;
    background: #dbeae3;
  }
  .linethru-100 {
    height: 100%;
  }
  .column-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10px;
    //justify-content: end;
  }
  .cell-1-1 {
    padding-top: 5px;
    height: 22px;
  }

  .cell-label {
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.05em;
    padding-left: 15px;
  }

  .cell-select {
    padding-left: 15px;
    width: 100%;
  }
`;

const { TextArea } = Input;
interface ActivityTabProps {
  data: VatRunData;
  updateActivityRole: (
    activityRoleUpdateRequest: ActivityRoleUpdateRequest
  ) => void;
  activityLogs?: ActivityLog[];
  updateNote: (noteText: string, dataLogId: string) => void;
  currentUser: User;
}

//const { Text, Title } = Typography;
export const ActivityTab: React.FC<ActivityTabProps> = ({
  data,
  updateActivityRole,
  activityLogs,
  updateNote,
  currentUser,
}) => {
  const dispatch = useDispatch();

  const [editNote, setEditNote] = React.useState(false);
  const [noteText, setNoteText] = React.useState<string>('');

  const openActivityLogs = () => {
    const drawerData: DrawerData = {
      title: 'Activity',
      //subtitle: 'Connect one of the below',
      type: DrawerType.ACTIVITY_LOGS,
      component: (
        <ActivityLogs
          data={data}
          activityLogs={activityLogs}
          updateNote={updateNote}
          connectionId={data.tokenId}
          mappingId={data.mappingId}
        />
      ),
    };
    dispatch(turnDrawerOn(drawerData));
  };
  const [selectedPreparer, setSelectedPreparer] = React.useState(
    data.preparerId
  );

  React.useEffect(() => {
    if (!selectedPreparer) setSelectedPreparer(data.preparerId);
    if (data) {
      const isClientApproval =
        currentUser &&
        AccountType[currentUser.accountType] === AccountType.CLIENT &&
        data.status &&
        DataLogStatus[data.status] === DataLogStatus.AWAITING_CLIENT_APPROVAL;

      setEditNote(!!isClientApproval);
    }
  }, [data]);

  const handleChange = (activityRole: ActivityRoles, accountId: string) => {
    activityRole === ActivityRoles.PREPARER && setSelectedPreparer(accountId);
    const memberUser =
      activityRole === ActivityRoles.CLIENT ||
      activityRole === ActivityRoles.REMOVE_CLIENT
        ? data.clients && data.clients.find((c) => c.accountUuid === accountId)
        : data.staff && data.staff.find((s) => s.accountUuid === accountId);

    const request: ActivityRoleUpdateRequest = {
      activityRole,
      memberUser,
      dataLogId: data.dataLogId,
      mappingId: data.mappingId,
    };

    updateActivityRole(request);
  };

  const onNoteChange = (e) => {
    setNoteText(e.target.value);
  };

  const onNoteSave = () => {
    updateNote(noteText, data.dataLogId);
    noteText !== '' && setEditNote(false);
    setNoteText('');
  };
  return (
    <ActivityWrapper>
      <div className="activity-settings-buttons">
        <span
          style={{
            fontSize: 14,
            fontWeight: 600,
            marginTop: 5,
            marginBottom: 25,
          }}
        >
          {data?.status &&
          DataLogStatus[data?.status] !== DataLogStatus.SUBMITTED &&
          currentUser?.accountType &&
          AccountType[currentUser.accountType] !== AccountType.CLIENT
            ? 'Process'
            : ''}
        </span>

        <Space>
          {data?.status &&
            DataLogStatus[data?.status] !== DataLogStatus.SUBMITTED &&
            currentUser?.accountType &&
            AccountType[currentUser.accountType] !== AccountType.CLIENT && (
              <ExportButton
                icon={<IconNote />}
                style={{ marginBottom: 20 }}
                onClick={() => setEditNote(!editNote)} //@ts-ignore
                // disabled={
                //   currentUser?.accountType &&
                //   AccountType[currentUser.accountType] === AccountType.CLIENT
                // }
              >
                {!editNote ? 'Add Note' : 'Close Note'}
              </ExportButton>
            )}
          {currentUser?.accountType &&
            AccountType[currentUser.accountType] !== AccountType.CLIENT && (
              <ExportButton
                icon={<IconBell />}
                style={{ marginBottom: 20 }}
                onClick={openActivityLogs}
                disabled={
                  AccountType[currentUser.accountType] === AccountType.CLIENT
                }
              >
                Activity
              </ExportButton>
            )}
        </Space>
      </div>
      {editNote && (
        <>
          <TextArea rows={4} onChange={onNoteChange} value={noteText} />
          <div className="divider-20" />
          <ScopeButton
            type={'primary'}
            onClick={onNoteSave} //@ts-ignore
            width="100%"
          >
            Save Note
          </ScopeButton>
          <div className="divider-20" />
        </>
      )}

      {data?.status && (
        // DataLogStatus[data?.status] !== DataLogStatus.SUBMITTED &&
        <>
          <div className="selects-wrapper">
            {currentUser &&
              AccountType[currentUser.accountType] !== AccountType.CLIENT && (
                <>
                  <div className="selects-row">
                    <div className=" column-1 cell-1-1">
                      <GreenDot />
                      <div className="linethru" />
                    </div>
                    <div className="cell-label">PREPARER</div>
                  </div>
                  <div className="selects-row">
                    <div className=" column-1 cell-3-1">
                      <div className="linethru linethru-100" />
                    </div>
                    <div className="cell-select">
                      <FormSelect //@ts-ignore
                        width="100%"
                        height="55px"
                        radius={'10px'}
                        bordercolor="#DBEAE3"
                        onChange={(e: any) =>
                          handleChange(ActivityRoles.PREPARER, e)
                        }
                        defaultValue={data.preparerId}
                        key="value"
                        style={{ minWidth: 300 }}
                        placeholder="Preparer not set"
                        disabled={
                          AccountType[currentUser.accountType] ===
                            AccountType.CLIENT ||
                          DataLogStatus[data?.status] ===
                            DataLogStatus.SUBMITTED
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option?.title
                            ?.toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {data.staff &&
                          data.staff.map((member, idx) => (
                            <Select.Option
                              key={idx}
                              title={`${member.firstName} ${member.lastName}`}
                              value={member.accountUuid}
                            >
                              <RenderUserDetail
                                record={member}
                                showOption
                                hideEmail
                              />
                            </Select.Option>
                          ))}
                      </FormSelect>
                    </div>
                  </div>

                  <div className="selects-row">
                    <div className=" column-1 cell-3-1">
                      <div className="linethru" />
                      <GreenDot />
                      <div className="linethru" />
                    </div>
                    <div className="cell-label" style={{ paddingTop: 5 }}>
                      REVIEWER
                    </div>
                  </div>
                  <div className="selects-row">
                    <div className=" column-1 cell-3-1">
                      <div className="linethru linethru-100" />
                    </div>
                    <div className="cell-select">
                      <FormSelect //@ts-ignore
                        width="100%"
                        height="55px"
                        radius={'10px'}
                        bordercolor="#DBEAE3"
                        placeholder="Reviewer not set"
                        onChange={(e: any) =>
                          e && handleChange(ActivityRoles.REVIEWER, e)
                        }
                        defaultValue={data.reviewerId}
                        key="value"
                        style={{ minWidth: 300 }}
                        disabled={
                          AccountType[currentUser.accountType] ===
                            AccountType.CLIENT ||
                          DataLogStatus[data?.status] ===
                            DataLogStatus.SUBMITTED
                        }
                        allowClear
                        onClear={() => {
                          data.reviewerId &&
                            handleChange(
                              ActivityRoles.REMOVE_REVIEWER,
                              data.reviewerId
                            );
                        }}
                        showSearch={true}
                        filterOption={(input, option) =>
                          option?.title
                            ?.toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {data.staff &&
                          data.staff.map(
                            (member, idx) =>
                              member.accountUuid !== selectedPreparer && (
                                <Select.Option
                                  key={idx}
                                  title={`${member.firstName} ${member.lastName}`}
                                  value={member.accountUuid}
                                >
                                  <RenderUserDetail
                                    record={member}
                                    showOption
                                    hideEmail
                                  />
                                </Select.Option>
                              )
                          )}
                      </FormSelect>
                    </div>
                  </div>

                  <div className="selects-row">
                    <div className=" column-1 ">
                      <div className="linethru" />
                      <GreenDot />
                      <div className="divider-10" />
                    </div>
                    <div className="cell-label" style={{ paddingTop: 5 }}>
                      CLIENT
                    </div>
                  </div>
                  <div className="selects-row">
                    <div className=" column-1" />

                    <div className="cell-select">
                      <FormSelect //@ts-ignore
                        width="100%"
                        height="55px"
                        radius={'10px'}
                        bordercolor="#DBEAE3"
                        onChange={(e: any) =>
                          e && handleChange(ActivityRoles.CLIENT, e)
                        }
                        defaultValue={data.clientId}
                        key="value"
                        style={{ minWidth: 300 }}
                        placeholder="Client not set"
                        disabled={
                          AccountType[currentUser.accountType] ===
                            AccountType.CLIENT ||
                          DataLogStatus[data?.status] ===
                            DataLogStatus.SUBMITTED
                        }
                        allowClear
                        onClear={() => {
                          data.clientId &&
                            handleChange(
                              ActivityRoles.REMOVE_CLIENT,
                              data.clientId
                            );
                        }}
                        showSearch={true}
                        filterOption={(input, option) =>
                          option?.title
                            ?.toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {data.clients &&
                          data.clients.map((member, idx) => (
                            <Select.Option
                              key={idx}
                              title={`${member.firstName} ${member.lastName}`}
                              value={member.accountUuid}
                            >
                              <RenderUserDetail
                                record={member}
                                showOption
                                hideEmail
                              />
                            </Select.Option>
                          ))}
                      </FormSelect>
                    </div>
                  </div>
                </>
              )}
          </div>
        </>
      )}
    </ActivityWrapper>
  );
};
