import { Currency, SubscriptionPlan } from 'store/api/enums';
import {
  BillingAction,
  BillingState,
  FetchPlanPricingDetailsSuccessAction,
  FetchSubscriptionTypesSuccessAction,
  GetCurrentSubscriptionSuccessAction,
  GetInvoicesSuccessAction,
  SetCurrentPlanAction,
  ValidateVATNumberRequestAction,
  ValidateVATNumberSuccessAction,
} from './types';
import {
  BILLING_CHECKOUT_FAILURE,
  BILLING_CHECKOUT_REQUEST,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_PLAN_FAILURE,
  CHANGE_SUBSCRIPTION_PLAN_REQUEST,
  CHANGE_SUBSCRIPTION_PLAN_SUCCESS,
  FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_FAILURE,
  FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST,
  FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_SUCCESS,
  FETCH_SUBSCRIPTION_TYPES_FAILURE,
  FETCH_SUBSCRIPTION_TYPES_REQUEST,
  FETCH_SUBSCRIPTION_TYPES_SUCCESS,
  GET_CURRENT_SUBSCRIPTION_FAILURE,
  GET_CURRENT_SUBSCRIPTION_REQUEST,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  SET_CURRENT_PLAN,
  UPDATE_CARD_FAILURE,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  VALIDATE_VAT_NUMBER_FAILURE,
  VALIDATE_VAT_NUMBER_REQUEST,
  VALIDATE_VAT_NUMBER_SUCCESS,
} from './constants';

export const initialState: BillingState = {
  loading: false,
  selectedPlan: {
    currentPlan: SubscriptionPlan.FREE_PLAN,
    selectedCurrency: Currency.EUR,
    price: '0',
  },
  countryCode: '',
  taxNumber: '',
  isVATNumberValid: false,
  licenseTypes: [],
  priceDetails: undefined,
  currentSubscription: undefined,
  invoices: [],
};

export const billingReducer = (
  state = initialState,
  action: BillingAction
): BillingState => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_TYPES_REQUEST:
    case CHANGE_SUBSCRIPTION_PLAN_REQUEST:
    case FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST:
    case BILLING_CHECKOUT_REQUEST:
    case GET_CURRENT_SUBSCRIPTION_REQUEST:
    case GET_INVOICES_REQUEST:
    case UPDATE_CARD_REQUEST:
    case CANCEL_SUBSCRIPTION_REQUEST:
      return handleRequest(state);
    case SET_CURRENT_PLAN:
      return handleSetCurrentPlan(state, action);
    case VALIDATE_VAT_NUMBER_REQUEST:
      return handleValidateVATNumberRequest(state, action);
    case VALIDATE_VAT_NUMBER_SUCCESS:
      return handleValidateVATNumberSuccess(state, action);
    case FETCH_SUBSCRIPTION_TYPES_SUCCESS:
      return handleFetchSubscriptionTypesSuccess(state, action);
    case FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_SUCCESS:
      return handlefetchPlanPricingDetailsSuccess(state, action);
    case GET_CURRENT_SUBSCRIPTION_SUCCESS:
      return handleGetCurrentSubscriptionSuccess(state, action);
    case GET_INVOICES_SUCCESS:
      return handleGetInvoicesSuccess(state, action);
    case GET_INVOICES_FAILURE:
    case GET_CURRENT_SUBSCRIPTION_FAILURE:
    case FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_FAILURE:
    case FETCH_SUBSCRIPTION_TYPES_FAILURE:
    case VALIDATE_VAT_NUMBER_FAILURE:
    case CANCEL_SUBSCRIPTION_FAILURE:
    case BILLING_CHECKOUT_FAILURE:
    case UPDATE_CARD_FAILURE:
    case CHANGE_SUBSCRIPTION_PLAN_FAILURE:
      return handleFailure(state);
    case CHANGE_SUBSCRIPTION_PLAN_SUCCESS:
    case CANCEL_SUBSCRIPTION_SUCCESS:
    case UPDATE_CARD_SUCCESS:
      return handleSuccess(state);
    default:
      return state;
  }
};

function handleSetCurrentPlan(
  state: BillingState,
  action: SetCurrentPlanAction
): BillingState {
  return {
    ...state,
    selectedPlan: {
      currentPlan: action.payload.currentPlan,
      selectedCurrency: action.payload.selectedCurrency,
      price: action.payload.price,
    },
  };
}

function handleValidateVATNumberRequest(
  state: BillingState,
  action: ValidateVATNumberRequestAction
): BillingState {
  return {
    ...state,
    countryCode: action.payload.countryCode,
    taxNumber: action.payload.taxNumber,
    loading: true,
  };
}

function handleValidateVATNumberSuccess(
  state: BillingState,
  action: ValidateVATNumberSuccessAction
): BillingState {
  return {
    ...state,
    loading: false,
    isVATNumberValid: action.payload.isValid,
  };
}

function handleFetchSubscriptionTypesSuccess(
  state: BillingState,
  action: FetchSubscriptionTypesSuccessAction
): BillingState {
  return {
    ...state,
    loading: false,
    licenseTypes: action.payload.licenseTypes,
  };
}

function handlefetchPlanPricingDetailsSuccess(
  state: BillingState,
  action: FetchPlanPricingDetailsSuccessAction
): BillingState {
  return {
    ...state,
    priceDetails: action.payload.priceDetails,
    loading: false,
  };
}

function handleGetCurrentSubscriptionSuccess(
  state: BillingState,
  action: GetCurrentSubscriptionSuccessAction
): BillingState {
  return {
    ...state,
    currentSubscription: action.payload.subscription,
    loading: false,
  };
}

function handleGetInvoicesSuccess(
  state: BillingState,
  action: GetInvoicesSuccessAction
): BillingState {
  return {
    ...state,
    invoices: action.payload.invoices,
  };
}

// Common
function handleRequest(state: BillingState): BillingState {
  return {
    ...state,
    loading: true,
  };
}

function handleFailure(state: BillingState): BillingState {
  return {
    ...state,
    loading: false,
  };
}

function handleSuccess(state: BillingState): BillingState {
  return {
    ...state,
    loading: false,
  };
}
