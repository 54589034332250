import { call, put } from 'redux-saga/effects';
import { updateDetailsFailure, updateDetailsSuccess } from '../actions';
import { updateDetails } from '../api';
import { UpdateDetailsRequestAction } from '../types';
import { ApiError, ApiResponse } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { fetchMeSuccess } from 'store/auth/actions';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* updateDetailsSaga(action: UpdateDetailsRequestAction) {
  try {
    let err: ApiError | null;

    const { user } = action.payload;
    const response: ApiResponse = yield call(updateDetails, user);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const message: MessageData = {
      title: 'Updated !',
      description: 'Details updated successfully.',
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(updateDetailsSuccess());
    yield put(fetchMeSuccess(user, user.typeId, user.isInTrial));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(updateDetailsFailure());
  }
}
