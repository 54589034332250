import { Currency, SubscriptionPlan } from 'store/api/enums';
import { createAction } from 'store/utils';
import {
  BILLING_CHECKOUT_FAILURE,
  BILLING_CHECKOUT_REQUEST,
  BILLING_CHECKOUT_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION_PLAN_FAILURE,
  CHANGE_SUBSCRIPTION_PLAN_REQUEST,
  CHANGE_SUBSCRIPTION_PLAN_SUCCESS,
  FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_FAILURE,
  FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST,
  FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_SUCCESS,
  FETCH_SUBSCRIPTION_TYPES_FAILURE,
  FETCH_SUBSCRIPTION_TYPES_REQUEST,
  FETCH_SUBSCRIPTION_TYPES_SUCCESS,
  GET_CURRENT_SUBSCRIPTION_FAILURE,
  GET_CURRENT_SUBSCRIPTION_REQUEST,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_INVOICES_FAILURE,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  SET_CURRENT_PLAN,
  UPDATE_CARD_FAILURE,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  VALIDATE_VAT_NUMBER_FAILURE,
  VALIDATE_VAT_NUMBER_REQUEST,
  VALIDATE_VAT_NUMBER_SUCCESS,
} from './constants';
import {
  BillingCheckoutFailureAction,
  BillingCheckoutRequestAction,
  BillingCheckoutSuccessAction,
  CancelSubscriptionFailureAction,
  CancelSubscriptionRequestAction,
  CancelSubscriptionSuccessAction,
  ChangeSubscriptionPlanFailureAction,
  ChangeSubscriptionPlanRequestAction,
  ChangeSubscriptionPlanSuccessAction,
  FetchPlanPricingDetailsFailureAction,
  FetchPlanPricingDetailsRequestAction,
  FetchPlanPricingDetailsSuccessAction,
  FetchSubscriptionTypesFailureAction,
  FetchSubscriptionTypesRequestAction,
  FetchSubscriptionTypesSuccessAction,
  GetCurrentSubscriptionFailureAction,
  GetCurrentSubscriptionRequestAction,
  GetCurrentSubscriptionSuccessAction,
  GetInvoicesFailureAction,
  GetInvoicesRequestAction,
  GetInvoicesSuccessAction,
  SetCurrentPlanAction,
  UpdateCardFailureAction,
  UpdateCardRequestAction,
  UpdateCardSuccessAction,
  ValidateVATNumberFailureAction,
  ValidateVATNumberRequestAction,
  ValidateVATNumberSuccessAction,
} from './types';
import { SubscriptionType } from './models/subscriptionType';
import { PlanPricingDetails } from './models/planPricingDetails';
import { BillingCheckout } from './models/billingCheckout';
import { CurrentSubscription } from './models/currentSubscription';
import { Invoice } from './models/invoice';

export const setCurrentPlan = (
  currentPlan: SubscriptionPlan,
  selectedCurrency: Currency,
  price: string
): SetCurrentPlanAction =>
  createAction(SET_CURRENT_PLAN, { currentPlan, selectedCurrency, price });

// Validate VAT number
export const validateVATNumberRequest = (
  countryCode: string,
  taxNumber: string
): ValidateVATNumberRequestAction =>
  createAction(VALIDATE_VAT_NUMBER_REQUEST, { countryCode, taxNumber });

export const validateVATNumberSuccess = (
  isValid: boolean
): ValidateVATNumberSuccessAction =>
  createAction(VALIDATE_VAT_NUMBER_SUCCESS, { isValid });

export const validateVATNumberFailure = (): ValidateVATNumberFailureAction =>
  createAction(VALIDATE_VAT_NUMBER_FAILURE);

// fetch subscriptions types
export const fetchSubscriptionTypesRequest =
  (): FetchSubscriptionTypesRequestAction =>
    createAction(FETCH_SUBSCRIPTION_TYPES_REQUEST);

export const fetchSubscriptionTypesSuccess = (
  licenseTypes: SubscriptionType[]
): FetchSubscriptionTypesSuccessAction =>
  createAction(FETCH_SUBSCRIPTION_TYPES_SUCCESS, { licenseTypes });

export const fetchSubscriptionTypesFailure =
  (): FetchSubscriptionTypesFailureAction =>
    createAction(FETCH_SUBSCRIPTION_TYPES_FAILURE);

// change subscription plan
export const changeSubscriptionPlanRequest = (
  subscriptionTypeId: string,
  additionalSize: number
): ChangeSubscriptionPlanRequestAction =>
  createAction(CHANGE_SUBSCRIPTION_PLAN_REQUEST, {
    subscriptionTypeId,
    additionalSize,
  });

export const changeSubscriptionPlanSuccess = (
): ChangeSubscriptionPlanSuccessAction =>
  createAction(CHANGE_SUBSCRIPTION_PLAN_SUCCESS);

export const changeSubscriptionPlanFailure =
  (): ChangeSubscriptionPlanFailureAction =>
    createAction(CHANGE_SUBSCRIPTION_PLAN_FAILURE);

// fetch subscription plan pricing details
export const fetchPlanPricingDetailsRequest = (
  subscriptionTypeId: string
): FetchPlanPricingDetailsRequestAction =>
  createAction(FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST, {
    subscriptionTypeId,
  });

export const fetchPlanPricingDetailsSuccess = (
  priceDetails: PlanPricingDetails
): FetchPlanPricingDetailsSuccessAction =>
  createAction(FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_SUCCESS, {
    priceDetails,
  });

export const fetchPlanPricingDetailsFailure =
  (): FetchPlanPricingDetailsFailureAction =>
    createAction(FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_FAILURE);

// billing checkout
export const billingCheckoutRequest = (
  billingCheckout: BillingCheckout
): BillingCheckoutRequestAction =>
  createAction(BILLING_CHECKOUT_REQUEST, {
    billingCheckout,
  });

export const billingCheckoutSuccess = (
  responseBody: string
): BillingCheckoutSuccessAction =>
  createAction(BILLING_CHECKOUT_SUCCESS, {
    responseBody,
  });

export const billingCheckoutFailure = (): BillingCheckoutFailureAction =>
  createAction(BILLING_CHECKOUT_FAILURE);

// get current subscription
export const getCurrentSubscriptionRequest = (
  accountId: string
): GetCurrentSubscriptionRequestAction =>
  createAction(GET_CURRENT_SUBSCRIPTION_REQUEST, {
    accountId,
  });

export const getCurrentSubscriptionSuccess = (
  subscription: CurrentSubscription
): GetCurrentSubscriptionSuccessAction =>
  createAction(GET_CURRENT_SUBSCRIPTION_SUCCESS, {
    subscription,
  });

export const getCurrentSubscriptionFailure =
  (): GetCurrentSubscriptionFailureAction =>
    createAction(GET_CURRENT_SUBSCRIPTION_FAILURE);

// get invoices
export const getInvoicesRequest = (
  customerId: string
): GetInvoicesRequestAction =>
  createAction(GET_INVOICES_REQUEST, {
    customerId,
  });

export const getInvoicesSuccess = (
  invoices: Invoice[]
): GetInvoicesSuccessAction =>
  createAction(GET_INVOICES_SUCCESS, {
    invoices,
  });

export const getInvoicesFailure = (): GetInvoicesFailureAction =>
  createAction(GET_INVOICES_FAILURE);

// update card
export const updateCardRequest = (accountId: string): UpdateCardRequestAction =>
  createAction(UPDATE_CARD_REQUEST, {
    accountId,
  });

export const updateCardSuccess = (data: any): UpdateCardSuccessAction =>
  createAction(UPDATE_CARD_SUCCESS, {
    data,
  });

export const updateCardFailure = (): UpdateCardFailureAction =>
  createAction(UPDATE_CARD_FAILURE);


// cancel subscription
export const cancelSubscriptionRequest = (
  licenseId: string
): CancelSubscriptionRequestAction =>
  createAction(CANCEL_SUBSCRIPTION_REQUEST, {
    licenseId,
  });

export const cancelSubscriptionSuccess = (
): CancelSubscriptionSuccessAction =>
  createAction(CANCEL_SUBSCRIPTION_SUCCESS);

export const cancelSubscriptionFailure = (): CancelSubscriptionFailureAction =>
  createAction(CANCEL_SUBSCRIPTION_FAILURE);
