import { Table } from 'antd';
import styled from 'styled-components';

export const CustomTable = styled(Table)`
  .ant-table-thead > tr > th:not(:first-child) {
    border: 1px solid #dbeae3;
    box-shadow: 0px -7px 10px 0px #0000000d;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }

  .ant-table-thead > tr > th:first-child {
    padding-left: 0px;
    padding-bottom: 30px;
  }

  .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-thead > tr > th:empty {
    border: none;
    box-shadow: none;
  }

  .ant-table-row > .ant-table-cell-row-hover {
    background-color: #e9f6f7 !important;
    border-color: #e9f6f7 !important;
  }

  .ant-table-row > .ant-table-cell:not(:first-child) {
    border-right: 1px solid #dbeae3;
    border-left: 1px solid #dbeae3;
    border-bottom: none;
  }

  .ant-table-row > .ant-table-cell {
    border-bottom: none;
  }

  :where(.ant-table-row:has(td:first-child:empty)) .ant-table-cell {
    background-color: white !important;
    pointer-events: none;
  }

  .ant-table-row > .ant-table-cell:nth-child(odd) {
    background-color: #f3f7f8;
    border: none;
  }

  .ant-table-tbody > tr:first-child > .ant-table-cell:first-child {
    border-top-left-radius: 10px !important;
  }

  .ant-table-tbody > tr:first-child > .ant-table-cell:last-child {
    border-top-right-radius: 10px !important;
  }

  :where(.ant-table-row:has(+ .ant-table-row:has(td:first-child:empty)))
    .ant-table-cell {
    border-radius: 10px 0px 0px 10px !important;
  }

  .ant-table-row:has(+ .ant-table-row > .ant-table-cell:first-child:empty)
    > .ant-table-cell:first-child {
    border-bottom-left-radius: 10px !important;
  }

  .ant-table-row:has(+ .ant-table-row > .ant-table-cell:first-child:empty)
    > .ant-table-cell:last-child {
    border-bottom-right-radius: 10px !important;
  }

  .ant-table-row:has(td:first-child:empty) + tr > td:last-child {
    border-top-right-radius: 10px !important;
  }

  .ant-table-row:has(td:first-child:empty) + tr > td:first-child {
    border-top-left-radius: 10px !important;
  }

  .ant-table-tbody > tr:last-child > td:nth-child(even) {
    border: 1px solid #dbeae3;
    box-shadow: 0px 7px 10px 0px #0000000d;
    border-radius: 0px 0px 10px 10px;
    border-top: none;
  }

  .ant-table-tbody > tr > td {
    height: 35px;
    padding: 4px 12px;
    padding-top: 6px;
  }

  .ant-table-tbody > tr > td:first-child {
    padding-left: 30px;
  }

  .ant-table-thead > tr > th {
    padding: 28px 0px;
  }

  .ant-table-thead > tr > th:before {
    content: none !important;
  }
`;
