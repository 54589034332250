import { call, put, select } from 'redux-saga/effects';
import {
  fetchViewRunDataRequest,
  saveReturnFailure,
  saveReturnSuccess,
  updateActivityRoleRequest,
} from '../actions';
import { ApiError } from 'store/api/types';
import { SaveReturnRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { SaveReturnResponse, saveReturn, updateReturn } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { updateSubmissionLog } from 'store/organisation/actions';
import {
  ActivityRoles,
  DataLogStatus,
  SubmissionUpdateField,
} from 'store/app/enums';
import { getAuthUser } from 'store/auth/selectors';
import { getUserMembers } from 'store/user/selectors';
import routes from 'routes';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* saveReturnSaga(action: SaveReturnRequestAction) {
  try {
    let err: ApiError | null;
    let response: SaveReturnResponse;
    const { saveReturnRequestData } = action.payload;

    if (!saveReturnRequestData.dataLogId) {
      response = yield call(saveReturn, saveReturnRequestData);
    } else {
      response = yield call(updateReturn, saveReturnRequestData);
    }

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const dataLogId = saveReturnRequestData.dataLogId
      ? saveReturnRequestData.dataLogId
      : response.data.dataLogId;
    // const viewRunRequestData: ViewRunRequestData = {
    //   ...saveReturnRequestData,
    //   dataLogId,
    // };
    const currentUser = yield select(getAuthUser);
    const users = yield select(getUserMembers);
    const generatedDate = saveReturnRequestData.generatedDate;

    yield put(saveReturnSuccess(dataLogId, users, currentUser, generatedDate));
    const message: MessageData = {
      title: 'Success!',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    if (!saveReturnRequestData.dataLogId)
      window.history.pushState(
        {},
        '',
        routes.vat.viewReportLink
          .replace(':connectionId', saveReturnRequestData.connectionId)
          .replace(':mappingId', saveReturnRequestData.mappingId)
          .replace(':dataLogId', dataLogId)
      );

    yield put(turnMessageOn(message));
    yield put(
      updateActivityRoleRequest({
        activityRole: ActivityRoles.PREPARER,
        memberUser: currentUser,
        dataLogId,
        mappingId: saveReturnRequestData.mappingId,
      })
    );

    yield put(
      updateSubmissionLog(
        saveReturnRequestData.mappingId,
        SubmissionUpdateField.STATUS,
        DataLogStatus.SAVED,
        response.data.dataLogId
      )
    );
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(saveReturnFailure());
  }
}
