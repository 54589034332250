import React from 'react';
import { ListItemSpan, TableItemSpan } from 'components/Custom/spans';
import { useTranslation } from 'react-i18next';
import { NumericInput } from 'components/Custom/inputs';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { StyledCard } from 'components/Custom/card';
import { ReactComponent as ErrorSignIcon } from 'assets/images/icons/errorSignIcon.svg';
import { SelectedPlan } from 'store/billing/types';
import { Link } from 'react-router-dom';
import { ScopeButton } from 'components/Custom/buttons';
import { CurrentSubscription } from 'store/billing/models/currentSubscription';

interface EditPlanProps {
  currentSubscriptionPlan: CurrentSubscription;
  updatePlan: (additionalSize:number) => void;
  close: () => void;
}

export const EditPlan: React.FC<EditPlanProps> = ({
  currentSubscriptionPlan,
  updatePlan,
  close,
}) => {
  const { t } = useTranslation();
  const [additionalEntities, setAdditionalEntities] = React.useState(
    currentSubscriptionPlan?.additionalOrganisations
  );
  const [vatNumberCheckerPerEntity, setVatNumberCheckerPerEntity] =
    React.useState(currentSubscriptionPlan?.vatNumberChecker);

  return (
    <>
      <TableItemSpan fontSize="14px" lineHeight="18px">
        {t('billing.edit-plan-desc')}
      </TableItemSpan>
      <br/>
      <br/>
      <TableItemSpan fontSize="14px" lineHeight="22px" style={{paddingTop: 20}}>
        {t('billing.edit-plan-desc2')}
      </TableItemSpan>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexFlow: 'row',
          alignContent: 'normal',
          paddingBottom: '15px',
          paddingTop: '30px',
        }}
      >
        <ListItemSpan fontSize="14px" lineHeight="40px" fontWeight="400">
          {t('billing.additional-entities')}
        </ListItemSpan>
        <NumericInput //@ts-ignore
          hasError={
            additionalEntities <
            currentSubscriptionPlan?.additionalOrganisations
          }
          min={currentSubscriptionPlan?.additionalOrganisations}
          addonAfter={
            <PlusOutlined
              style={{ color: '#41AAAF' }}
              onClick={() => setAdditionalEntities(additionalEntities + 1)}
            />
          }
          addonBefore={
            <MinusOutlined
              disabled={
                additionalEntities <
                currentSubscriptionPlan?.additionalOrganisations
              }
              style={{ color: '#41AAAF' }}
              onClick={() => setAdditionalEntities(additionalEntities - 1)}
            />
          }
          controls={false}
          value={additionalEntities}
          onChange={(value) => setAdditionalEntities(value)}
        />
      </div>
      {additionalEntities <
        currentSubscriptionPlan?.additionalOrganisations && (
        <StyledCard //@ts-ignore
          stopShadowBox
          padding="15px 24px"
          style={{
            margin: '0px 0px 30px',
            width: '100%',
            backgroundColor: '#E9F6F7',
            border: '1px solid #DBEAE3',
          }}
        >
          <Space style={{ columnGap: 16 }}>
            <ErrorSignIcon />
            <TableItemSpan fontSize="12px" lineHeight="16px" fontWeight="400">
              You need to first delete one or more Entities from the{' '}
              <Link
                style={{ textDecoration: 'underline', color: '#042a2a' }}
                to={'/entities'}
                onClick={close}
              >
                Entities
              </Link>{' '}
              Section before reducing the amount.
            </TableItemSpan>
          </Space>
        </StyledCard>
      )}

{/*       <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexFlow: 'row',
          alignContent: 'normal',
          paddingBottom: '45px',
          paddingTop: '15px',
        }}
      >
        <ListItemSpan fontSize="14px" lineHeight="40px" fontWeight="400">
          {t('billing.vat-Number-checker')}{' '}
        </ListItemSpan>
        <NumericInput
          addonAfter={
            <PlusOutlined
              style={{ color: '#41AAAF' }}
              onClick={() =>
                setVatNumberCheckerPerEntity(vatNumberCheckerPerEntity + 1)
              }
            />
          }
          addonBefore={
            <MinusOutlined
              style={{ color: '#41AAAF' }}
              onClick={() =>
                setVatNumberCheckerPerEntity(vatNumberCheckerPerEntity - 1)
              }
            />
          }
          controls={false}
          value={vatNumberCheckerPerEntity}
          onChange={(value) => setVatNumberCheckerPerEntity(value)}
        />
      </div> */}
      <ScopeButton disabled={additionalEntities < currentSubscriptionPlan?.additionalOrganisations} type="primary" onClick={() => updatePlan(additionalEntities)}>
        {t('billing.update-plan')}
      </ScopeButton>
    </>
  );
};
