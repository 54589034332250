import { call, put } from 'redux-saga/effects';
import {
  getCurrentSubscriptionFailure,
  getCurrentSubscriptionSuccess,
} from '../actions';
import { ApiError } from 'store/api/types';
import { GetCurrentSubscriptionRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import { GetCurrentSubscriptionResponse, getCurrentSubscription } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* getCurrentSubscriptionSaga(
  action: GetCurrentSubscriptionRequestAction
) {
  try {
    let err: ApiError | null;
    const { accountId } = action.payload;

    const getCurrentSubscriptionResponse: GetCurrentSubscriptionResponse =
      yield call(getCurrentSubscription, accountId);

    err = checkResponse(getCurrentSubscriptionResponse?.responseMessage);
    if (err) throw err;

    yield put(
      getCurrentSubscriptionSuccess(
        getCurrentSubscriptionResponse?.data?.subscription
      )
    );
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(getCurrentSubscriptionFailure());
  }
}
