import { call, put } from 'redux-saga/effects';
import { generateReportFailure, generateReportSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { LoadReportRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { LoadReportResponse, loadReport } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { VatReportData } from '../models';
import { MessageType } from 'store/api/enums';
import { logoutRequest } from 'store/auth/actions';
import { push } from 'connected-react-router';
import routes from 'routes';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* loadReportSaga(action: LoadReportRequestAction) {
  try {
    let err: ApiError | null;
    const { vatLoadRequestData } = action.payload;
    let pg = 0;
    let noDataMessage = '';
    let lastId = 1;
    let isEmpty = false;

    do {
      const response: LoadReportResponse = yield call(
        loadReport,
        vatLoadRequestData,
        lastId
      );

      err = checkResponse(response?.responseMessage);
      if (err) throw err;

      if (
        !response.data &&
        response.responseMessage.messageType === MessageType.WARNING
      ) {
        noDataMessage = response.responseMessage.message;
        isEmpty = true;
      } else {
        const vatReportData: VatReportData = response.data;

        if(!vatReportData.journals || vatReportData.journals.length == 0 || vatReportData.recap) {
          isEmpty = true;

          if(!vatReportData.journals) {
            vatReportData.journals = [];
          }

          if(!vatReportData.exceptions) {
            vatReportData.exceptions = [];
          }
          
          vatReportData.totalPages = 0;

        }else {
          vatReportData.totalPages = 1;
        }

        lastId = vatReportData.page;
        
        yield put(generateReportSuccess(vatReportData, isEmpty));
      }

    } while (!isEmpty);
    if (noDataMessage) {
      const message: MessageData = {
        title: 'Server Message',
        description: noDataMessage,
        type: MessageStates.WARNING,
      };
      yield put(turnMessageOn(message));
      yield put(generateReportFailure());
      yield put(push(routes.main));
    }
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(generateReportFailure());
  }
}
