export const SET_CURRENT_PLAN = 'billing/set-current-plan';

export const VALIDATE_VAT_NUMBER_REQUEST = 'billing/validate-vat-number-request';
export const VALIDATE_VAT_NUMBER_SUCCESS = 'billing/validate-vat-number-success';
export const VALIDATE_VAT_NUMBER_FAILURE = 'billing/validate-vat-number-failure';

export const FETCH_SUBSCRIPTION_TYPES_REQUEST = 'billing/fetch-subscription-types-request';
export const FETCH_SUBSCRIPTION_TYPES_SUCCESS = 'billing/fetch-subscription-types-success';
export const FETCH_SUBSCRIPTION_TYPES_FAILURE = 'billing/fetch-subscription-types-failure';


export const CHANGE_SUBSCRIPTION_PLAN_REQUEST = 'billing/change-subscription-plan-request';
export const CHANGE_SUBSCRIPTION_PLAN_SUCCESS = 'billing/change-subscription-plan-success';
export const CHANGE_SUBSCRIPTION_PLAN_FAILURE = 'billing/change-subscription-plan-failure';


export const FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_REQUEST = 'billing/fetch-subscription-plan-pricing-details-request';
export const FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_SUCCESS = 'billing/fetch-subscription-plan-pricing-details-success';
export const FETCH_SUBSCRIPTION_PLAN_PRICING_DETAILS_FAILURE = 'billing/fetch-subscription-plan-pricing-details-failure';

export const BILLING_CHECKOUT_REQUEST = 'billing/billing-checkout-request';
export const BILLING_CHECKOUT_SUCCESS = 'billing/billing-checkout-success';
export const BILLING_CHECKOUT_FAILURE = 'billing/billing-checkout-failure';

export const GET_CURRENT_SUBSCRIPTION_REQUEST = 'billing/get-current-subscription-request';
export const GET_CURRENT_SUBSCRIPTION_SUCCESS = 'billing/get-current-subscription-success';
export const GET_CURRENT_SUBSCRIPTION_FAILURE = 'billing/get-current-subscription-failure';

export const GET_INVOICES_REQUEST = 'billing/get-invoices-request';
export const GET_INVOICES_SUCCESS = 'billing/get-invoices-success';
export const GET_INVOICES_FAILURE = 'billing/get-invoices-failure';


export const CANCEL_SUBSCRIPTION_REQUEST = 'billing/cancel-subscription-request';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'billing/cancel-subscription-success';
export const CANCEL_SUBSCRIPTION_FAILURE = 'billing/cancel-subscription-failure';

export const UPDATE_CARD_REQUEST = 'billing/update-card-request';
export const UPDATE_CARD_SUCCESS = 'billing/update-card-success';
export const UPDATE_CARD_FAILURE = 'billing/update-card-failure';

