import { ContactUs } from 'components/Billing/contact-us';
import { PricingComponent } from 'components/Billing/pricing';
import { push } from 'connected-react-router';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes';
import { Currency, SubscriptionPlan } from 'store/api/enums';
import { turnModalOn } from 'store/app/actions';
import { WindowType } from 'store/app/enums';
import { ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import {
  fetchPlanPricingDetailsRequest,
  fetchSubscriptionTypesRequest,
  setCurrentPlan,
} from 'store/billing/actions';
import {
  getBillingLoading,
  getSelectedPlan,
  getSubscriptionPlanTypes,
} from 'store/billing/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';

export const PricingPage: React.FC = () => {
  const dispatch = useDispatch();
  const [messageValue, setMessageValue] = React.useState<any>('');
  const [authUser, setAuthUser] = React.useState<any>({});
  const [showPage, setShowPage] = useState(false);
  const selectedPlan = useSelector(getSelectedPlan);
  const currentUser = useSelector(getAuthUser);
  const subscriptionPlanTypes = useSelector(getSubscriptionPlanTypes);
  const isLoading = useSelector(getBillingLoading);

  const activePlane = (
    plan: SubscriptionPlan,
    selectedCurrency: Currency,
    price: string,
    licenseId: string
  ) => {
    dispatch(fetchPlanPricingDetailsRequest(licenseId));
    dispatch(setCurrentPlan(plan, selectedCurrency, price));
    dispatch(push(`/pricing/checkout/${licenseId}`));
  };

  React.useEffect(() => {
    setShowPage(true);
    if (subscriptionPlanTypes) {
      dispatch(fetchSubscriptionTypesRequest());
    }
    currentUser && checkPageLoaded(location.pathname, routes.users);
  }, []);

  React.useEffect(() => {
    if (currentUser) {
      setAuthUser(currentUser);
    }
  }, [currentUser]);

  const renderContactUsWindow = () => {
    const modalData: ModalData = {
      type: WindowType.WARNING,
      title:
        'Thank you for your interest in the Enterprise plan. A product specialist will be in touch to discuss your exact requirements.\nWould you like to leave a note before sending the request?',
      component: (
        <ConfirmBoxContainer
          callback={
            () => {}
            //navigateToExternalPage('https://generate.tax/contact/')
          }
          showCancelBtn={false}
          okText="Request More Info"
          description={
            <>
              <ContactUs
                messageValue={messageValue}
                setMessageValue={setMessageValue}
              />
            </>
          }
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Pricing | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This is a page for pricing" />
          </Helmet>
          <PricingComponent
            selectedPlan={selectedPlan}
            activePlane={activePlane}
            subscriptionPlanTypes={subscriptionPlanTypes}
            currentUser={authUser}
            isLoading={isLoading}
            renderContactUsWindow={renderContactUsWindow}
          />
        </>
      )}
    </React.Fragment>
  );
};
