import { ConnectEntity } from 'components/ConnectEntity';
import { MainComponent } from 'components/MainComponent';
import { push } from 'connected-react-router';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import { VatOverviewPage } from 'pages/vat';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import routes from 'routes';
import { turnDrawerOn, turnModalOn } from 'store/app/actions';
import { DrawerType, WindowType } from 'store/app/enums';
import { DrawerData, ModalData } from 'store/app/types';
import { getAuthUser } from 'store/auth/selectors';
import { fetchSubscriptionTypesRequest } from 'store/billing/actions';
import { getCommonCountries } from 'store/common/selectors';

import {
  getOrgGettingTaxes,
  getOrgLoading,
  getOrgMappedReturns,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import { deleteMappingRequest } from 'store/returns/actions';
import { getReturnLoading } from 'store/returns/selectors';
import { fetchRunDataFailure, generateReportFailure } from 'store/vat/actions';
import { getVatPrepLoading } from 'store/vat/selectors';
import { checkPageLoaded } from 'utils/check-page-loaded';
import {
  Preference,
  PreferenceActions,
  preferences,
  PreferenceSections,
} from 'utils/preferences';

export const PAGE_SIZE_MAIN = 'page-size-main';

export const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mappedReturns = useSelector(getOrgMappedReturns);
  const entities = useSelector(getOrgOrganisations);
  const gettingTaxes = useSelector(getOrgGettingTaxes);
  const countries = useSelector(getCommonCountries);
  const currentUser = useSelector(getAuthUser);
  const orgLoading = useSelector(getOrgLoading);
  const retLoading = useSelector(getReturnLoading);
  const vatLoading = useSelector(getVatPrepLoading);

  const [showPage, setShowPage] = React.useState(false);
  const loading = orgLoading || retLoading || vatLoading;

  const handlePreferences = (
    action: PreferenceActions,
    preference: Preference
  ) => {
    if (currentUser)
      return preferences(
        action,
        PreferenceSections.MAIN,
        preference,
        currentUser?.userUuid
      );
    return false;
  };

  const addReturnMapping = (connectionId: string) => {
    if (connectionId) {
      dispatch(push(routes.returns.add.replace(':connectionId', connectionId)));
    } else alert('Missing connection Id.');
  };

  const emptyPageCallback = () => {
    if (!!entities && entities.length > 0) {
      dispatch(push(routes.organisations.all));
    } else {
      const drawerData: DrawerData = {
        title: 'Add a new entity',
        subtitle: 'Connect one of the below',
        type: DrawerType.CONNECT_ENTITY,
        component: <ConnectEntity />,
      };
      dispatch(turnDrawerOn(drawerData));
    }
  };

  const editReturnMapping = (connectionId: string, mappingId: string) => {
    if (connectionId && mappingId) {
      dispatch(
        push(
          routes.returns.edit
            .replace(':connectionId', connectionId)
            .replace(':mappingId', mappingId)
        )
      );
    } else alert('Missing connection Id.');
  };

  const deleteReturnMapping = (organisationId: string, mappingId: string) => {
    if (organisationId && mappingId) {
      dispatch(deleteMappingRequest(organisationId, mappingId));
    } else alert('Missing connection / mapping Id.');
  };

  const vatGenerate = (
    connectionId: string,
    mappingId: string,
    dataLogId: string
  ) => {
    dataLogId
      ? dispatch(
          push(
            routes.vat.viewReportLink
              .replace(':connectionId', connectionId)
              .replace(':mappingId', mappingId)
              .replace(':dataLogId', dataLogId)
          )
        )
      : openVatWindow(connectionId, mappingId);
  };

  const openVatWindow = (
    connectionId: string,
    mappingId: string
    //dataLogId?: string
  ) => {
    const modalData: DrawerData = {
      type: DrawerType.ADD_TAX_SUBMISSION,
      title: 'Create Tax Submission',
      component: (
        <VatOverviewPage connectionId={connectionId} mappingId={mappingId} />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const onAddEntity = () => {
    const drawerData: DrawerData = {
      title: 'Connect entity',
      subtitle: 'Connect one of the below',
      type: DrawerType.CONNECT_ENTITY,
      component: <ConnectEntity />,
    };
    dispatch(turnDrawerOn(drawerData));
  };

  React.useEffect(() => {
    setShowPage(true);
    !currentUser && window.location.replace('/login');
    currentUser && checkPageLoaded(location.pathname, routes.main);
    dispatch(fetchRunDataFailure());
    dispatch(generateReportFailure());
    dispatch(fetchSubscriptionTypesRequest());
  }, []);

  return (
    <React.Fragment>
      {showPage && (
        <>
          <Helmet>
            <title>{`Home | ${process.env.PROJECT_NAME}`}</title>
            <meta name="description" content="This a home page" />
          </Helmet>

          <MainComponent
            loading={loading}
            mappedReturns={mappedReturns}
            countries={countries}
            addReturnMapping={addReturnMapping}
            editReturnMapping={editReturnMapping}
            deleteReturnMapping={deleteReturnMapping}
            vatGenerate={vatGenerate}
            currentUser={currentUser}
            emptyPageCallback={emptyPageCallback}
            hasEntities={!!entities && entities.length > 0}
            gettingTaxes={gettingTaxes}
            connectEntity={onAddEntity}
            handlePreferences={handlePreferences}
          />
        </>
      )}
    </React.Fragment>
  );
};
