import { call, put } from 'redux-saga/effects';
import {
  fetchPlanPricingDetailsFailure,
  fetchPlanPricingDetailsSuccess,
} from '../actions';
import { ApiError } from 'store/api/types';
import { FetchPlanPricingDetailsRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import {
  FetchPlanPricingDetailsResponse,
  fetchPlanPricingDetails,
} from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchSubscriptionPlanPricingDetailsSaga(
  action: FetchPlanPricingDetailsRequestAction
) {
  try {
    let err: ApiError | null;
    const { subscriptionTypeId } = action.payload;

    const fetchPlanPricingDetailsResponse: FetchPlanPricingDetailsResponse =
      yield call(fetchPlanPricingDetails, subscriptionTypeId);

    err = checkResponse(fetchPlanPricingDetailsResponse?.responseMessage);
    if (err) throw err;

    yield put(
      fetchPlanPricingDetailsSuccess(
        fetchPlanPricingDetailsResponse?.data?.priceDetails
      )
    );
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchPlanPricingDetailsFailure());
  }
}
