import moment from 'moment';
import { Divider, Radio, Space, Switch, Tooltip, Typography } from 'antd';
import * as React from 'react';
import { DataJournal, DataJournalType } from 'store/vat/models/vatReportData';
import { StyledCard } from 'components/Custom/card';
import { ScopeTable } from 'components/Custom/table';
import { ExportButton } from 'components/Custom/buttons';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { RoundInput } from 'components/Custom/inputs';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import {
  Preference,
  PreferenceActions,
  PreferenceSections,
} from 'utils/preferences';
import { FILTERS_TRANSACTIONS, PAGE_SIZE_TRANSACTIONS } from 'pages/vat/report';
import { ExceptionsActionType } from 'store/vat/models';
import { GrayItalicSpan } from 'components/Custom/spans';

interface RenderTRansactionProps {
  transData?: DataJournal[];
  dataToExport?: DataJournal[];
  journalType?: string;
  setJournalType?: (value: any) => void;
  filteredBox?: string | null;
  noSumData?: boolean;
  handlePreferences: (
    preferenceSection: PreferenceSections,
    action: PreferenceActions,
    preference: Preference
  ) => any;
  providerName: string;
  showSideMenu: boolean;
  isAdjustedReturn: boolean;
}

const { Text } = Typography;

export const RenderTransactions: React.FC<RenderTRansactionProps> = ({
  transData,
  dataToExport,
  journalType,
  setJournalType,
  filteredBox,
  noSumData,
  handlePreferences,
  providerName,
  showSideMenu,
  isAdjustedReturn,
}) => {
  const pageSizeSaved = handlePreferences(
    PreferenceSections.TRANSACTIONS,
    PreferenceActions.GET,
    {
      name: PAGE_SIZE_TRANSACTIONS,
    }
  );

  const filterSavedRecord: Array<Record<string, string>> = [];
  const filtersSaved = handlePreferences(
    PreferenceSections.TRANSACTIONS,
    PreferenceActions.GET,
    {
      name: FILTERS_TRANSACTIONS,
    }
  );
  if (filtersSaved)
    Object.keys(filtersSaved).forEach((key) =>
      filterSavedRecord.push({ key: key, value: filtersSaved[key] })
    );

  const [source, setSource] = React.useState(transData);
  const [showAdjustedTransactions, setShowAdjustedTransactions] =
    React.useState<boolean>(false);
  const { t } = useTranslation();

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };
  React.useEffect(() => {
    setSource(transData);
  }, [transData]);

  React.useEffect(() => {
    let transactionsToShow: DataJournal[] | undefined = [];
    if (showAdjustedTransactions) {
      transactionsToShow = transData?.filter(
        (transaction) =>
          transaction.exceptionActionType !== undefined &&
          ExceptionsActionType[transaction.exceptionActionType] ==
            ExceptionsActionType.ADJUSTMENT
      );
    } else {
      transactionsToShow = transData;
    }

    setSource(transactionsToShow);
  }, [showAdjustedTransactions]);

  const columns = [
    {
      //width: 30,
      fixed: 'left',
      render: (_, record: DataJournal) => (
        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          data-exception-type={
            record.exceptionActionType !== undefined
              ? ExceptionsActionType[record.exceptionActionType]
              : undefined
          }
        >
          <div
            style={{
              width: 6,
              backgroundColor: 'white',
              marginLeft: -12,
              marginTop: -4,
              marginBottom: -4,
              marginRight: 10,
              height: 45,
            }}
          >
            <div
              style={{
                background: `${
                  record.exceptionActionType !== undefined &&
                  ExceptionsActionType[record.exceptionActionType] ===
                    ExceptionsActionType.ADJUSTMENT
                    ? '#bbbb42'
                    : record.exceptionActionType !== undefined &&
                      ExceptionsActionType[record.exceptionActionType] ===
                        ExceptionsActionType.CURRENT_RETURN
                    ? '#48B9BF'
                    : '#f3f7f8'
                }`,
                height: 45,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            />
          </div>
          <a
            href={record.sourceUrl}
            style={{ cursor: 'pointer' }}
            target={'_blank'}
            rel="noreferrer"
          >
            <img
              src={
                providerName
                  ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                      .default
                  : ''
              }
              style={{ marginTop: 7 }}
              width={25}
            />
          </a>
        </div>
      ),
    },
    {
      title: 'Mapping',
      dataIndex: 'selectedValue',
      key: 'selectedValue',
      fixed: 'left',
      render: (content: string, record: DataJournal) => (
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              minWidth: 100,
              maxWidth: 130,
              fontSize: 12,
            }}
          >
            {content}
          </Text>
          {record.exceptionActionType && (
            <Tooltip
              title={
                ExceptionsActionType[record.exceptionActionType] ===
                ExceptionsActionType.ADJUSTMENT
                  ? 'Adjusted exception from another submission.'
                  : ExceptionsActionType[record.exceptionActionType] ===
                    ExceptionsActionType.CURRENT_RETURN
                  ? 'Included from exceptions.'
                  : ''
              }
              className="tooltip-info"
            >
              <InfoCircleTwoTone
                style={{ fontSize: 18, cursor: 'pointer' }}
                twoToneColor={'#41aaaf'}
                className="testing-icon"
              />
            </Tooltip>
          )}
        </Space>
      ),
      sorter: (a, b) => a.selectedValue?.localeCompare(b.selectedValue),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Tax Name',
      dataIndex: 'taxName',
      key: 'taxName',
      //ellipsis: true,
      //fixed: 'left',
      sorter: (a, b) => a.taxName?.localeCompare(b.taxName),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Journal Date',
      dataIndex: 'journalDate',
      key: 'journalDate',
      ellipsis: true,
      //fixed: 'left',
      render: (_, record: DataJournal) =>
        moment(record.journalDate).format('DD/MM/YYYY'),
      sorter: (a, b) =>
        new Date(a.journalDate).valueOf() - new Date(b.journalDate).valueOf(),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Source Type',
      dataIndex: 'sourceType',
      key: 'sourceType',
      ellipsis: true,
      sorter: (a, b) => a.sourceType?.localeCompare(b.sourceType),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Code',
      dataIndex: 'accountCode',
      key: 'accountCode',
      sorter: (a, b) => a.accountCode?.localeCompare(b.accountCode),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Type',
      dataIndex: 'accountType',
      key: 'accountType',
      ellipsis: true,
      sorter: (a, b) => a.accountType?.localeCompare(b.accountType),
      sortIcon: renderSortIcon,
    },
    {
      title: 'A/C Name',
      dataIndex: 'accountName',
      key: 'accountName',
      ellipsis: true,
      // render: (content: string) => (
      //   <>
      //     <Text
      //       style={{ minWidth: 100, maxWidth: 130 }}
      //       //ellipsis={{ rows: 1, tooltip: true }}
      //     >
      //       {content}
      //     </Text>
      //   </>
      // ),
      sorter: (a, b) => a.accountName?.localeCompare(b.accountName),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Net',
      dataIndex: 'netAmountConverted',
      key: 'netAmountConverted',
      fixed: 'right',
      render: (content: number) => (
        <div
          style={{
            textAlign: 'end',
            color: content < 0 ? '#d13131' : '',
          }}
        >
          {content.toFixed(2)}
        </div>
      ),
      sorter: (a, b) => (a.netAmountConverted > b.netAmountConverted ? -1 : 1),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Tax',
      dataIndex: 'taxAmountConverted',
      key: 'taxAmountConverted',
      fixed: 'right',
      render: (content: number, record: DataJournal) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
          data-exception-type={
            record.exceptionActionType !== undefined
              ? ExceptionsActionType[record.exceptionActionType]
              : undefined
          }
        >
          <div
            style={{
              textAlign: 'end',
              color: content < 0 ? '#d13131' : '',
              //width: 100,
            }}
          >
            {content.toFixed(2)}
          </div>
          <div
            style={{
              width: 6,
              backgroundColor: 'white',
              marginLeft: 10,
              marginTop: -4,
              marginBottom: -4,
              marginRight: -13,
              height: 45,
              border: 0,
            }}
          >
            <div
              style={{
                background: `${
                  record.exceptionActionType !== undefined &&
                  ExceptionsActionType[record.exceptionActionType] ===
                    ExceptionsActionType.ADJUSTMENT
                    ? '#f8f8eb'
                    : record.exceptionActionType ===
                      ExceptionsActionType.CURRENT_RETURN
                    ? '#ebf8f8'
                    : '#f3f7f8'
                }`,
                height: 45,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
          </div>
        </div>
      ),
      sorter: (a, b) => (a.taxAmountConverted > b.taxAmountConverted ? -1 : 1),
      sortIcon: renderSortIcon,
    },
  ];

  const getHeaderForExport = (dataToExport) => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: any = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) => ({
        label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
        key: key,
      }));
    }

    return headerArr;
  };

  const search = (e) => {
    const value = e.target.value;
    if (value != '') {
      const filterTable = transData?.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(value.toLowerCase())
        )
      );

      setSource(filterTable);
    } else {
      setSource(transData);
    }
  };

  const renderCardTitle = () => {
    return (
      <Space
        style={{
          display: 'flex',
          marginBottom: 20,
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <Radio.Group
            defaultValue={journalType}
            buttonStyle="solid"
            style={{ borderRadius: '10px' }} //@ts-ignore
            onChange={(e) => setJournalType(e.target.value)}
          >
            <Radio.Button value={'SUMMARISED'} disabled={noSumData}>
              {t('transactions.summarised')}
            </Radio.Button>
            <Radio.Button value={'DETAILED'}>
              {' '}
              {t('transactions.detailed')}
            </Radio.Button>
          </Radio.Group>

          <ExportButton icon={<IconExport />}>
            <span>
              <CSVLink
                filename={`${
                  journalType == 'SUMMARISED' ? 'Summarised' : 'Detailed'
                } Transactions Export.csv`}
                data={dataToExport}
                headers={getHeaderForExport(dataToExport)}
                className="csv-link-wrapper"
              >
                Export Transactions
              </CSVLink>
            </span>
          </ExportButton>
        </Space>

        <RoundInput
          width={'200px'}
          height={'35px'}
          prefix={<SearchOutlined />}
          size="large"
          placeholder={'Search Transactions'}
          onChange={search}
        />
      </Space>
    );
  };

  const savePageSize = (pageSize: number) => {
    const preferenceSection = PreferenceSections.TRANSACTIONS;
    handlePreferences(preferenceSection, PreferenceActions.SET, {
      name: PAGE_SIZE_TRANSACTIONS,
      value: pageSize,
    });
  };

  const saveFilters = (filters: any) => {
    const preferenceSection = PreferenceSections.TRANSACTIONS;
    handlePreferences(preferenceSection, PreferenceActions.SET, {
      name: FILTERS_TRANSACTIONS,
      value: filters,
    });
  };

  const renderAppendedFilter = () => {
    if (isAdjustedReturn)
      return (
        <Space>
          <GrayItalicSpan color="#879494">
            {t('vat-checker.show-adjusted')}
          </GrayItalicSpan>
          <Switch
            title={t('vat-checker.show-adjusted')}
            className={showAdjustedTransactions ? 'switch-checked' : ''}
            checked={showAdjustedTransactions}
            onChange={(
              checked: boolean,
              _event: React.MouseEvent<HTMLButtonElement>
            ) => {
              setShowAdjustedTransactions(checked);
            }}
          />
        </Space>
      );
  };

  const clearAppendedFilter = () => {
    setShowAdjustedTransactions(false);
  };

  return (
    <>
      <StyledCard //@ts-ignore
        style={{
          width: showSideMenu ? 'calc(100vw - 550px)' : '100%',
          minWidth: 900,
          margin: 'auto',
        }}
      >
        {transData && transData?.length > 0 && renderCardTitle()}
        <Divider />
        <div
          style={{
            padding: '10px',
            paddingLeft: '5px',
            paddingTop: '10px',
            //maxWidth: 1600,
            minWidth: 800,
            margin: 'auto',
          }}
        >
          <ScopeTable
            filters={[
              'taxName',
              'selectedValue',
              'journalDate',
              'sourceType',
              'accountCode',
              'accountType',
              'accountName',
            ]} //@ts-ignore
            mainLocaleKey="transactions"
            tableDataSource={source}
            originalDataSource={transData}
            columns={columns}
            pagination={true}
            rowsPerPage={pageSizeSaved ? pageSizeSaved : 20}
            enableColumnsCustomization={false}
            rowHeight={40} //@ts-ignore
            //selectedFilter={{ key: 'selectedValue', value: filteredBox }}
            selectedPresetFilters={[
              { key: 'selectedValue', value: filteredBox },
            ]}
            selectedPresetFilters={filterSavedRecord}
            showSizeChanger
            getNewPageSize={savePageSize}
            filterSave={saveFilters}
            scroll={{ x: 'max-content' }}
            fontSize={12}
            appendedFilterComponent={renderAppendedFilter()}
            clearAppendedFilterComponent={clearAppendedFilter}
          />
        </div>
      </StyledCard>
    </>
  );
};
