import { Space, Input } from 'antd';
import * as React from 'react';

interface ContactUsProps {
  messageValue: string | undefined;
  setMessageValue: (value: string) => void;
}

const { TextArea } = Input;
export const ContactUs: React.FC<ContactUsProps> = ({
  messageValue,
  setMessageValue,
}) => {
  return (
    <Space direction="vertical" style={{ width: '100%', rowGap: 30 }}>
      <TextArea
        rows={4}
        style={{ width: '100%', marginTop: '20px' }}
        value={messageValue}
        onChange={(e) => setMessageValue(e.target.value)}
      />
    </Space>
  );
};
