import { all, call, delay, put, select } from 'redux-saga/effects';
import {
  fetchOrganisationsFailure,
  fetchOrganisationsSuccess,
  setGettingTaxes,
} from '../actions';
import {
  FetchMappingsResponse,
  FetchOrganisationsResponse,
  getMappings,
  getOrganisations,
} from '../api';
import { ApiError } from 'store/api/types';
import moment from 'moment';
import { checkResponse } from 'store/utils';
import { Mapping, Organisation } from '../models';
import { MessageData, ProfilePicture } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { PICTURE_DELAY_TIME } from 'store/app/constants';
import { GetTaxRatesResponse, getTaxRates } from 'store/returns/api';
import { TaxRate } from 'store/returns/models/returnData';
import { getProfilePictures } from 'store/app/selectors';
import { fetchProfilePictureRequest } from 'store/account/actions';
import { getAuthUser } from 'store/auth/selectors';
import { User } from 'store/auth/models';
import { Provider } from 'store/api/enums';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

interface SavedRates {
  userUuid: string;
  timestamp: string;
  rates: { connectionId: string; taxRates: TaxRate[] }[];
}

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchOrganisationsSaga() {
  const REFRESH_TIMEOUT = 15;
  let currentUser: User = yield select(getAuthUser);
  if (!currentUser) {
    do {
      delay(500);
      currentUser = yield select(getAuthUser);
    } while (!currentUser);
  }
  const fileName = `gen-tax-tax-rates-${currentUser.userUuid}`;

  try {
    let errOrgs: ApiError | null;
    let errMaps: ApiError | null;
    const responseOrgs: FetchOrganisationsResponse = yield call(
      getOrganisations
    );

    const orgs: Organisation[] = responseOrgs.data.organisations;

    errOrgs = checkResponse(responseOrgs?.responseMessage);
    if (errOrgs) throw errOrgs;

    if (orgs && orgs.length === 0) {
      const message: MessageData = {
        title: 'No organisations found',
        description: 'Check your connections with your providers respectfully.',
        type: MessageStates.WARNING,
      };
      yield put(turnMessageOn(message));
    }
    // testing
    orgs.map(function (x) {
      x.connectedProperly = false;
      return x;
    });

    const responseMaps: FetchMappingsResponse = yield call(getMappings);
    const maps: Mapping[] = responseMaps.data.mappings;

    errMaps = checkResponse(responseMaps?.responseMessage);
    if (errMaps) throw errMaps;

    // prepare for taxes call and get from cache

    const allCalls: string[] = [];
    const savedRatesString = localStorage.getItem(fileName);

    const savedRates: SavedRates = savedRatesString
      ? JSON.parse(savedRatesString)
      : null;
    const rates = savedRates?.rates;
    const timestamp = savedRates?.timestamp
      ? moment(savedRates?.timestamp)
      : null;

    let newEntities = false;

    if (rates) {
      if (
        rates.length !==
        orgs.filter((o) => o.providerName !== Provider.CIN7).length
      )
        newEntities = true;
      else
        orgs.forEach((o) => {
          let found = false;
          rates.forEach((r) => {
            if (r.connectionId === o.connectionId) found = true;
          });
          newEntities = !found;
        });
    }
    if (!timestamp || newEntities) {
      localStorage.removeItem(fileName);
    }

    for (let i = 0; i < orgs.length; i++) {
      const connId = orgs[i].connectionId;

      allCalls.push(connId);
      if (rates) {
        const rate = rates?.find((r) => r.connectionId === connId);
        if (rate) {
          const taxRates = rate.taxRates;
          orgs[i].taxRates = taxRates;
          orgs[i].connectedProperly = true;
        }
      }
    }

    yield put(fetchOrganisationsSuccess(orgs, maps));
    ///// PICS
    let pp: ProfilePicture[] = yield select(getProfilePictures);
    for (let a = 0; a < orgs.length; a++) {
      let users = [...orgs[a].users];
      for (let i = 0; i < orgs[a].users.length; i++) {
        const user = { ...orgs[a].users[i] };
        if (user && user.hasProfilePicture && !user.profilePicture) {
          const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
          if (ppIdx > -1) {
            const profilePicture = pp[ppIdx].profilePicture;
            users[i] = { ...users[i], profilePicture };
          } else {
            yield put(fetchProfilePictureRequest(user.userUuid));
            yield delay(PICTURE_DELAY_TIME);
            pp = yield select(getProfilePictures);

            const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
            if (ppIdx > -1) {
              const profilePicture = pp[ppIdx].profilePicture;
              users[i] = { ...users[i], profilePicture };
            }
          }
        }
      }
      orgs[a] = { ...orgs[a], users };
    }
    //-mappings
    const activityUsers = ['preparerUser', 'reviewerUser', 'clientUser'];

    for (let b = 0; b < maps.length; b++) {
      let dataLog: any = { ...maps[b].dataLog };
      if (maps[b].dataLog) {
        for (let c = 0; c < activityUsers.length; c++) {
          //@ts-ignore
          const user = { ...maps[b].dataLog[activityUsers[c]] };
          if (user) {
            if (user.hasProfilePicture && !user.profilePicture) {
              const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
              if (ppIdx > -1) {
                const profilePicture = pp[ppIdx].profilePicture;
                if (dataLog[activityUsers[c]]) {
                  dataLog[activityUsers[c]] = {
                    ...dataLog[activityUsers[c]],
                    profilePicture,
                  };
                }
                if (dataLog) maps[b] = { ...maps[b], dataLog };
              } else {
                yield put(fetchProfilePictureRequest(user.userUuid));
                yield delay(PICTURE_DELAY_TIME);
                pp = yield select(getProfilePictures);
                const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
                if (ppIdx > -1) {
                  const profilePicture = pp[ppIdx].profilePicture;
                  if (dataLog[activityUsers[c]]) {
                    dataLog[activityUsers[c]] = {
                      ...dataLog[activityUsers[c]],
                      profilePicture,
                    };
                  }
                  if (dataLog) maps[b] = { ...maps[b], dataLog };
                }
              }
            }
          }
        }
      }
    }
    ////TAXES
    const timeToRefresh = timestamp
      ? moment().isAfter(timestamp.add(REFRESH_TIMEOUT, 'minutes'))
      : true;

    if (timeToRefresh || newEntities) {
      yield put(setGettingTaxes(true));
      let newTaxes: GetTaxRatesResponse[] = [];
      const taxResponses = yield call(getTaxRatesPromises, allCalls);
      taxResponses.map((tr) => {
        if (tr.value?.data) newTaxes.push(tr.value.data);
      });

      const ratesToSave: any[] = [];
      for (let i = 0; i < orgs.length; i++) {
        const curTaxRates = newTaxes.find(
          (nt) => !!nt && nt.connectionId === orgs[i].connectionId
        );

        if (curTaxRates) {
          const taxRates: TaxRate[] = curTaxRates.taxRates;
          orgs[i].taxRates = taxRates;
          orgs[i].connectedProperly = true;
          ratesToSave.push({ connectionId: orgs[i].connectionId, taxRates });
        } else {
          orgs[i].taxRates = [];
          orgs[i].connectedProperly = false;
        }
      }

      ratesToSave.length > 0
        ? localStorage.setItem(
            fileName,
            JSON.stringify({ timestamp: moment(), rates: ratesToSave })
          )
        : localStorage.removeItem(fileName);

      yield put(setGettingTaxes(false));
    }
    yield put(fetchOrganisationsSuccess(orgs, maps));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchOrganisationsFailure());
  }
}

const getTaxRatesPromises = async (list: string[]) => {
  return await Promise.allSettled(list.map((l) => getTaxRates(l)));
};
