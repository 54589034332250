import { call, put } from 'redux-saga/effects';
import { fetchQRCodeFailure, fetchQRCodeSuccess } from '../actions';
import { FetchQRCodeResponse, getQRCode } from '../api';
import { ApiError } from 'store/api/types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { checkResponse } from 'store/utils';
import { QRCodeData } from '../models';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchQRCodeSaga() {
  try {
    let err: ApiError | null;
    const response: FetchQRCodeResponse = yield call(getQRCode);
    const code: string = response?.data?.qrCodeImage;
    const secretKey: string = response?.data?.secretKey;
    const QRCodeData: QRCodeData = { code, secretKey };

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(fetchQRCodeSuccess(QRCodeData));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchQRCodeFailure());
  }
}
