import {
  CONNECT_CIN7_ENTITY_FAILURE,
  CONNECT_CIN7_ENTITY_REQUEST,
  CONNECT_CIN7_ENTITY_SUCCESS,
  DISCONNECT_ORGANISATION_FAILURE,
  DISCONNECT_ORGANISATION_REQUEST,
  DISCONNECT_ORGANISATION_SUCCESS,
  EDIT_ORGANISATION_FAILURE,
  EDIT_ORGANISATION_REQUEST,
  EDIT_ORGANISATION_SUCCESS,
  FETCH_ADD_URL_FAILURE,
  FETCH_ADD_URL_REQUEST,
  FETCH_ADD_URL_SUCCESS,
  FETCH_ORGANISATIONS_FAILURE,
  FETCH_ORGANISATIONS_REQUEST,
  FETCH_ORGANISATIONS_SUCCESS,
  //POST_SAVE_REFRESH_MAPPING_DATA,
  RESET_ORGANISATION_DATA,
  SET_GETTING_TAXES,
  UPDATE_ENTITY_MEMBERS,
  UPDATE_ORGANISATION_DATA,
  UPDATE_SUBMISSION_LOG,
} from './constants';
import { Entity, Mapping, Organisation } from './models';
import {
  EditOrganisationSuccessAction,
  FetchOrganisationsSuccessAction,
  OrganisationAction,
  OrganisationState,
  //PostSaveRefreshMappingDataAction,
  SetGettingTaxesAction,
  UpdateEntityMembersAction,
  UpdateOrganisationDataAction,
  UpdateSubmissionLogAction,
} from './types';
import { DataLogStatus, SubmissionUpdateField } from 'store/app/enums';
import {
  MappedReturn,
  ReturnFrequencies,
  Section,
} from '../returns/models/returnData';
import { createAction } from 'store/utils';
import { Provider, ReturnFrequency, TablePermission } from 'store/api/enums';
import { VatReturnLog } from 'store/vat/models';

const initialState: OrganisationState = {
  loading: false,
  organisations: undefined,
  mappings: undefined,
  mappedOrganisations: undefined,
  showActions: false,
  showConnections: false,
  mappedReturns: undefined,
  gettingTaxes: false,
};

export const organisationReducer = (
  state = initialState,
  action: OrganisationAction
): OrganisationState => {
  switch (action.type) {
    case FETCH_ORGANISATIONS_REQUEST:
    case EDIT_ORGANISATION_REQUEST:
    case DISCONNECT_ORGANISATION_REQUEST:
    case FETCH_ADD_URL_REQUEST:
    case CONNECT_CIN7_ENTITY_REQUEST:
      return handleRequest(state);
    case FETCH_ORGANISATIONS_SUCCESS:
      return handleFetchOrganisationsSuccess(state, action);
    case FETCH_ADD_URL_SUCCESS:
    case CONNECT_CIN7_ENTITY_SUCCESS:
    case DISCONNECT_ORGANISATION_SUCCESS:
    case EDIT_ORGANISATION_SUCCESS:
      return handleSuccess(state);
    // case EDIT_ORGANISATION_SUCCESS:
    //   return handleEditOrganisationSuccess(state, action);
    case FETCH_ORGANISATIONS_FAILURE:
    case DISCONNECT_ORGANISATION_FAILURE:
    case FETCH_ADD_URL_FAILURE:
    case CONNECT_CIN7_ENTITY_FAILURE:
    case EDIT_ORGANISATION_FAILURE:
      return handleFailure(state);
    // case POST_SAVE_REFRESH_MAPPING_DATA:
    //   return handlePostSaveRefreshMappinData(state, action);
    case UPDATE_ENTITY_MEMBERS:
      return handleUpdateEntityMembers(state, action);
    case UPDATE_SUBMISSION_LOG:
      return handleUpdateSubmissionLog(state, action);
    case SET_GETTING_TAXES:
      return handleSetGettingTaxes(state, action);
    case RESET_ORGANISATION_DATA:
      return handleResetOrganisationData();
    case UPDATE_ORGANISATION_DATA:
      return handleUpdateOrganisationData(state, action);
    default:
      return state;
  }
};

function handleRequest(state: OrganisationState): OrganisationState {
  return {
    ...state,
    loading: true,
  };
}

function handleFetchOrganisationsSuccess(
  state: OrganisationState,
  action: FetchOrganisationsSuccessAction
): OrganisationState {
  const { organisations, mappings } = action.payload;
  const orgs = organisations;
  const maps = mappings;
  const mappedReturns = maps ? addMapping(orgs, maps) : undefined;

  const mappedOrganisations = orgs ? addEntitiesMapping(orgs, maps) : undefined;
  return {
    ...state,
    loading: false,
    organisations: organisations,
    mappings: mappings,
    //showActions: action.payload.organisationsAndMappings.showActions,
    //showConnections: action.payload.organisationsAndMappings.showConnections,
    mappedOrganisations,
    mappedReturns,
  };
}

// function handleEditOrganisationSuccess(
//   state: OrganisationState,
//   action: EditOrganisationSuccessAction
// ): OrganisationState {
//   const orgs()
//   return {
//     ...state,
//     loading: false,
//     //organisations: action.payload.organisations,
//   };
// }

function handleSetGettingTaxes(
  state: OrganisationState,
  action: SetGettingTaxesAction
): OrganisationState {
  return {
    ...state,
    loading: false,
    gettingTaxes: action.payload.gettingTaxes,
  };
}

// function handlePostSaveRefreshMappinData(
//   state: OrganisationState,
//   action: PostSaveRefreshMappingDataAction
// ): OrganisationState {
//   const mapData = action.payload.mappingData;
//   const mappingId = mapData.mappingId ? mapData.mappingId : '';
//   const returnTypeId = mapData.typeId ? mapData.typeId : '';
//   const cashAccounting = mapData.mappedCashAccounting
//     ? mapData.mappedCashAccounting
//     : false;
//   const periodFromDate = mapData.periodFromDate
//     ? new Date(mapData.periodFromDate)
//     : undefined;
//   const returnFrequency = mapData.returnFrequency
//     ? mapData.returnFrequency
//     : undefined;
//   const returnDueDate = mapData.returnDueDate
//     ? new Date(mapData.returnDueDate)
//     : undefined;
//   const jsonMappingMod = mapData.jsonMapping
//     ? { mapping: mapData.jsonMapping }
//     : {};

//   const jsonMapping = JSON.stringify(jsonMappingMod);

//   const organisations = state.organisations;
//   const mappings = state.mappings;
//   const mapToUpdateIndex = mappings?.findIndex(
//     (m) => m.mappingId === mappingId
//   );
//   if (mapData && mapToUpdateIndex && mappings) {
//     const newMapRow: Mapping = {
//       ...mappings[mapToUpdateIndex],
//       mappingId,
//       returnTypeId,
//       cashAccounting,
//       periodFromDate,
//       returnFrequency,
//       returnDueDate,
//       jsonMapping,
//     };
//     mappings[mapToUpdateIndex] = newMapRow;
//     const fetchAction: FetchOrganisationsSuccessAction = createAction(
//       FETCH_ORGANISATIONS_SUCCESS,
//       { organisations, mappings }
//     );
//     return handleFetchOrganisationsSuccess(state, fetchAction);
//   }
//   return state;
// }

function handleUpdateEntityMembers(
  state: OrganisationState,
  action: UpdateEntityMembersAction
): OrganisationState {
  const { organisationId, member, tablePermission } = action.payload;
  if (state.organisations && state.mappedOrganisations) {
    const organisations = [...state.organisations];
    const mappedOrganisations = [...state.mappedOrganisations];
    const orgIdx = organisations?.findIndex((o) => o.uuid === organisationId);
    const mapOrgIdx = mappedOrganisations?.findIndex(
      (mo) => mo.organisationId === organisationId
    );

    if (orgIdx > -1 && mapOrgIdx > -1) {
      const newUsers = state.organisations[orgIdx].users;
      if (tablePermission === TablePermission.ALL) {
        if (
          newUsers.findIndex((nu) => nu.accountUuid === member.accountUuid) ===
          -1
        )
          newUsers.push(member);
      } else if (tablePermission === TablePermission.NONE) {
        const idx = newUsers.findIndex(
          (nu) => nu.accountUuid === member.accountUuid
        );
        idx > -1 && newUsers.splice(idx, 1);
      }

      organisations[orgIdx].users = newUsers;
      mappedOrganisations[mapOrgIdx].users = newUsers;
    }

    return {
      ...state,
      organisations,
      mappedOrganisations,
    };
  }
  return {
    ...state,
  };
}

function handleUpdateSubmissionLog(
  state: OrganisationState,
  action: UpdateSubmissionLogAction
): OrganisationState {
  const { mappingId, submissionUpdateField, data, dataLogId } = action.payload;

  if (state.mappings && state.mappedReturns) {
    const mappings = [...state.mappings];
    const mappedReturns = [...state.mappedReturns];
    const mappedOrganisations: Entity[] = state.mappedOrganisations
      ? [...state.mappedOrganisations]
      : [];

    const mapIdx = mappings?.findIndex((m) => m.mappingId === mappingId);
    const mapRetIdx = mappedReturns?.findIndex(
      (mr) => mr.mappingId === mappingId
    );

    if (mapIdx > -1 && mapRetIdx > -1) {
      let newDataLog: any = state.mappings[mapIdx].dataLog;
      switch (submissionUpdateField) {
        case SubmissionUpdateField.STATUS:
          if (newDataLog) {
            if (data === DataLogStatus.CANCELLED) {
              newDataLog = undefined;
              mappedReturns[mapRetIdx].status = '';
            } else {
              newDataLog = {
                ...newDataLog,
                status: data,
              };
              mappedReturns[mapRetIdx].status = data;
            }
          } else if (data === DataLogStatus.SAVED) {
            const status = 'SAVED';
            const newLog = {
              status,
              uuid: dataLogId,
              mappingId,
            } as VatReturnLog;
            newDataLog = newLog;
            mappedReturns[mapRetIdx].status = status;
          }
          break;
        case SubmissionUpdateField.PREPARER:
          newDataLog = { ...newDataLog, preparerUser: data };
          break;
        case SubmissionUpdateField.REVIEWER:
          newDataLog = { ...newDataLog, reviewerUser: data };
          break;
        case SubmissionUpdateField.CLIENT:
          newDataLog = { ...newDataLog, clientUser: data };
          break;
        case SubmissionUpdateField.REMOVE_REVIEWER:
          newDataLog = { ...newDataLog, reviewerUser: undefined };
          break;
        case SubmissionUpdateField.REMOVE_CLIENT:
          newDataLog = { ...newDataLog, clientUser: undefined };
          break;
      }

      mappings[mapIdx].dataLog = newDataLog;
      mappedReturns[mapRetIdx].dataLog = newDataLog;
      const entityIndex = mappedOrganisations.findIndex(
        (mo) => mo.organisationId === mappedReturns[mapRetIdx].organisationId
      );
      if (entityIndex > -1) {
        const entMapIdx = mappedOrganisations[entityIndex].returns.findIndex(
          (o) => o.mappingId === mappedReturns[mapRetIdx].mappingId
        );
        if (entMapIdx > -1) {
          mappedOrganisations[entityIndex].returns[entMapIdx] =
            mappedReturns[mapRetIdx];
        }
      }
    }

    return {
      ...state,
      mappings,
      mappedReturns,
      mappedOrganisations,
    };
  }
  return {
    ...state,
  };
}

function handleSuccess(state: OrganisationState): OrganisationState {
  return {
    ...state,
    loading: false,
  };
}

function handleFailure(state: OrganisationState): OrganisationState {
  return {
    ...state,
    loading: false,
  };
}

// Reset
function handleResetOrganisationData(): OrganisationState {
  return initialState;
}

function handleUpdateOrganisationData(
  state: OrganisationState,
  action: UpdateOrganisationDataAction
): OrganisationState {
  const { entityId, vatCheckerActive } = action.payload;
  let newOrgList: Organisation[] = [];
  if (state.organisations) {
    const organisations = [...state.organisations];
    const entity = organisations.find((entity) => entity.uuid == entityId);
    if (entity) {
      entity.vatCheckerActive = vatCheckerActive;
      newOrgList = organisations.filter((entity) => entity.uuid != entityId);
      newOrgList.push(entity);
    }
  }

  return {
    ...state,
    loading: false,
    organisations: newOrgList,
  };
}

function addEntitiesMapping(
  organisations?: Organisation[],
  mappings?: Mapping[]
): Entity[] {
  const entities: Entity[] = [];
  organisations?.forEach((c) => {
    const providerName = c.providerName;
    const organisationId = c.uuid;
    const organisation = c.friendlyName;
    const vatNumber = c.taxNumber;
    const country = c.country;
    const connectionId = c.connectionId;
    const shortCode = c.shortCode;
    const returnTypes = c.returnTypes;
    const returnTypesEdit = c.returnTypesEdit;
    const taxRates = c.taxRates;
    const connectedProperly = c.connectedProperly;
    c.users.forEach((user) => {
      user.fullName = `${user.firstName} ${user.lastName}`;
    });
    const users = c.users;

    const tempMappings = mappings?.filter((m) => m.organisationId === c.uuid);
    const returns: MappedReturn[] = [];
    if (c.providerName !== Provider.CIN7) {
      tempMappings?.forEach((mappedReturn, idx) => {
        const retDueDate = mappedReturn?.returnDueDate
          ? mappedReturn?.returnDueDate
          : '';
        const map: MappedReturn = {
          key: idx,
          mappingId: mappedReturn.mappingId,
          mappingName:
            c.returnTypesEdit?.find((rt) => rt.id === mappedReturn.returnTypeId)
              ?.countryName +
            ' - ' +
            mappedReturn.mappingName,
          nextDueDate: new Date(retDueDate).toLocaleDateString(),
          status: mappedReturn.dataLog
            ? DataLogStatus[mappedReturn.dataLog.status]
            : '',
          canDelete: mappedReturn.canDelete,
          hasMapping: true,
          dataLog: mappedReturn.dataLog ? mappedReturn.dataLog : undefined,
          connectionId: c.connectionId,
          sections: mappedReturn.sections,
          returnTypeId: mappedReturn.returnTypeId,
          connectedProperly,
          returnFrequency: mappedReturn.returnFrequency,
        };

        returns.push(map);
      });

      entities.push({
        providerName,
        organisationId,
        organisation,
        vatNumber,
        country,
        connectionId,
        shortCode,
        returns,
        key: entities.length + 1,
        users,
        returnTypes,
        returnTypesEdit,
        taxRates,
        connectedProperly,
      });
    }
  });
  return entities;
}

function addMapping(
  organisations?: Organisation[],
  mappings?: Mapping[]
): MappedReturn[] {
  const mappedReturns: MappedReturn[] = [];
  organisations?.map((c) => {
    const connectedProperly = c.connectedProperly;
    const providerName = c.providerName;
    const organisationId = c.uuid;
    const organisation = c.friendlyName;
    const vatNumber = c.taxNumber;
    const country = c.country;
    const connectionId = c.connectionId;
    const shortCode = c.shortCode;
    const returnTypes = c.returnTypes;
    const returnTypesEdit = c.returnTypesEdit;
    let mappingId = '';
    let mappingName = '';
    let nextDueDate = '';
    let hasMapping = false;
    let preparer;
    let reviewer;
    let client;
    let status = '';
    let canDelete = false;
    let returnTypeId = '';
    let sections: Section[];
    let returnFrequency: ReturnFrequency | undefined = undefined;
    const taxRates = c.taxRates;

    const tempMappings = mappings?.filter((m) => m.organisationId === c.uuid);
    if (c.providerName !== Provider.CIN7) {
      tempMappings?.map((m) => {
        const retDueDate = m.returnDueDate ? m.returnDueDate : '';
        mappingId = m.mappingId;
        mappingName =
          c.returnTypesEdit?.find((rt) => rt.id === m.returnTypeId)
            ?.countryName +
          ' - ' +
          m.mappingName;
        returnTypeId = m.returnTypeId;
        nextDueDate = new Date(retDueDate).toDateString();
        preparer = m.dataLog?.preparerUser
          ? m.dataLog?.preparerUser
          : undefined;
        reviewer = m.dataLog?.reviewerUser
          ? m.dataLog?.reviewerUser
          : undefined;
        client = m.dataLog?.clientUser ? m.dataLog?.clientUser : undefined;
        status = m.dataLog ? DataLogStatus[m.dataLog?.status] : '';
        canDelete = m.canDelete;
        hasMapping = true;
        sections = m.sections;

        returnFrequency = m.returnFrequency;
        const dataLog = m.dataLog ? m.dataLog : undefined;
        mappedReturns.push({
          providerName,
          organisationId,
          organisation,
          vatNumber,
          country,
          mappingId,
          mappingName,
          nextDueDate,
          preparer,
          reviewer,
          client,
          status,
          canDelete,
          key: mappedReturns.length + 1,
          connectionId,
          shortCode,
          dataLog,
          returnTypeId,
          returnTypes,
          returnTypesEdit,
          sections,
          taxRates,
          connectedProperly,
          returnFrequency,
        });
      });
    }
  });

  return mappedReturns;
}
