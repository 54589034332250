import { Button, Row, Space, Typography } from 'antd';
import { ContinueButton, ScopeButton } from 'components/Custom/buttons';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { turnModalOff } from 'store/app/actions';

const { Text } = Typography;
interface ConfirmBoxContainerProps {
  callback: (props?: any) => void;
  callback2?: (props?: any) => void;
  param?: any;
  param2?: any;
  description: any;
  cancelText?: string;
  okText?: string;
  okText2?: string;
  showCancelBtn?: boolean;
}
export const ConfirmBoxContainer: React.FC<ConfirmBoxContainerProps> = ({
  callback,
  callback2,
  param,
  param2,
  description,
  cancelText,
  showCancelBtn = true,
  okText,
  okText2,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Row justify={'center'}>
        <Text style={{ textAlign: 'center', width:'100%' }}>{description}</Text>
      </Row>
      <Row justify={'center'} style={{ paddingTop: '20px' }}>
        <Space>
          {showCancelBtn && (
            <ScopeButton
              type={'default'}
              width={'100px'}
              height={'40px'}
              onClick={() => dispatch(turnModalOff())}
            >
              {cancelText ? cancelText : 'Cancel'}
            </ScopeButton>
          )}
          {callback2 ? (
            <ContinueButton
              width={'fit-content'}
              height={'40px'}
              onClick={() => {
                dispatch(turnModalOff());
                param ? callback(param) : callback();
              }}
            >
              {okText ? okText : 'Yes'}
            </ContinueButton>
          ) : (
            <ScopeButton
              type={'primary'}
              width={'fit-content'}
              height={'40px'}
              onClick={() => {
                dispatch(turnModalOff());
                param ? callback(param) : callback();
              }}
            >
              {okText ? okText : 'Yes'}
            </ScopeButton>
          )}
          {callback2 && (
            <ScopeButton
              width={'fit-content'}
              height={'40px'}
              type={'primary'}
              onClick={() => {
                dispatch(turnModalOff());
                param2 ? callback2(param2) : callback2();
              }}
            >
              {okText2 ? okText2 : 'Yes 2'}
            </ScopeButton>
          )}
        </Space>
      </Row>
    </>
  );
};
