import { call, put } from 'redux-saga/effects';
import {
  changeStatusAfterUpdate,
  fetchActivityLogsRequest,
  notApproveFailure,
  notApproveSuccess,
} from '../actions';
import { ApiError, ApiResponse } from 'store/api/types';
import { NotApproveRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { notApprove } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { DataLogStatus, SubmissionUpdateField } from 'store/app/enums';
import { updateSubmissionLog } from 'store/organisation/actions';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* notApproveSaga(action: NotApproveRequestAction) {
  try {
    let err: ApiError | null;
    const { dataLogId, emailData, mappingId } = action.payload;
    const response: ApiResponse = yield call(notApprove, dataLogId, emailData);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    const message: MessageData = {
      title: 'Success!',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };
    yield put(turnMessageOn(message));
    yield put(fetchActivityLogsRequest(dataLogId));
    yield put(changeStatusAfterUpdate(DataLogStatus.SAVED));

    yield put(notApproveSuccess());
    yield put(
      updateSubmissionLog(
        mappingId,
        SubmissionUpdateField.STATUS,
        'SAVED',
        dataLogId
      )
    );
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(notApproveFailure());
  }
}
