import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Col, Form, Row, Select, Space } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { StyledCard } from 'components/Custom/card';
import { FormInput } from 'components/Custom/inputs';
import { LabelWrapper } from 'components/Custom/labels';
import { FormSelect } from 'components/Custom/selects';
import { CardDescSpan, CardTitleSpan } from 'components/Custom/spans';
import { StyledTooltip } from 'components/Custom/Tooltip';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'store/api/enums';
import { Country, CountryFlag } from 'store/common/models';
import { renderPhoneCodeOption } from 'utils/render-countries-codes';

interface BillingFormProps {
  billingForm?: any;
  countries: Country[];
  countriesExternalData: CountryFlag[];
  isCheckoutForm?: boolean;
  selectedCurrency?: string;
  changeCurrency?: (newCurrency) => void;
  calcVATPercentage?: (country) => void;
  validateVatNumber?: (countryCode: string, taxNumber: string) => void;
  isVATNumberValid?: boolean;
  isLoading?: boolean;
  isEmptyVAT?: boolean;
  setIsEmptyVAT?: (isEmptyVAT: boolean) => void;
  isEurCountry?: boolean;
  EUROPE_ISOS?: Array<string>;
}

interface BillingFormValues {
  addressLine1: string;
  addressLine2: string;
  town: string;
  country: string;
  zipCode: string;
  code?: string;
  phone?: string;
  email?: string;
  currency?: string;
  vat: string;
  companyName?: string;
}

export const BillingForm: React.FC<BillingFormProps> = ({
  billingForm,
  countries,
  countriesExternalData,
  isCheckoutForm,
  selectedCurrency,
  changeCurrency,
  calcVATPercentage,
  validateVatNumber,
  isVATNumberValid,
  isLoading,
  isEmptyVAT,
  setIsEmptyVAT,
  isEurCountry,
  EUROPE_ISOS,
}) => {
  const { t } = useTranslation();
  const [countryISO, setCountryISO] = React.useState('');

  useEffect(() => {
    countries.forEach((country) => {
      country.flag = countriesExternalData.find(
        (flag) => flag.cca2.toLowerCase() == country.iso.toLowerCase()
      )?.flags.png;
    }, []);
  }, [countries, countriesExternalData]);

  React.useEffect(() => {
    if (selectedCurrency) {
      billingForm.setFieldValue('currency', selectedCurrency);
    }
  }, [selectedCurrency]);

  const handleSubmit = (values: BillingFormValues) => {
    // to dispatch action for saving billing form values
  };

  const renderCountryWithFlag = (country: Country): React.ReactNode => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          width={'24px'}
          height={'16px'}
          alt={''}
          src={country.flag}
          style={{
            marginLeft: '5px',
            marginRight: '10px',
            marginTop: '3px',
          }}
        />
        {country.name}
      </div>
    );
  };

  const renderCurrencyList = () => {
    return Object.values(Currency).map((curr) => (
      <Select.Option key={curr} value={curr}>
        {curr}
      </Select.Option>
    ));
  };

  const onCurrencyChange = (value) => {
    changeCurrency && changeCurrency(value);
  };

  const onCountryChange = (value, option) => {
    const vatNumber = billingForm.getFieldValue('vat');
    const countryISO = option.key;
    setCountryISO(countryISO);
    preValidateVatNumber(vatNumber, countryISO);
  };

  const onVATInputChange = (event) => {
    const vatNumber = event.target.value;
    if (vatNumber?.trim() == '') {
      setIsEmptyVAT && setIsEmptyVAT(true);
    }
  };

  const preValidateVatNumber = (vatNumber, countryISO) => {
    calcVATPercentage && calcVATPercentage(countryISO);
    if (
      EUROPE_ISOS &&
      vatNumber != undefined &&
      vatNumber?.trim() != '' &&
      EUROPE_ISOS.includes(countryISO)
    ) {
      setIsEmptyVAT && setIsEmptyVAT(false);
      validateVatNumber && validateVatNumber(countryISO, vatNumber);
    } else {
      setIsEmptyVAT && setIsEmptyVAT(true);
    }
  };

  const onBlurVATNumber = (event) => {
    const vatNumber = event.target.value;
    preValidateVatNumber(vatNumber, countryISO);
  };

  const renderSuffix = () => {
    //const vatNumber = billingForm.getFieldValue('vat');
    if (isVATNumberValid && !isEmptyVAT && !isLoading && isEurCountry) {
      return <CheckCircleTwoTone twoToneColor="#52c41a" />;
    } else if (!isVATNumberValid && !isEmptyVAT && !isLoading && isEurCountry) {
      return (
        <>
          <StyledTooltip // @ts-ignore
            title={'Invalid VAT number!'}
            placement="top"
            arrow={false}
          >
            <CloseCircleTwoTone twoToneColor="#d13131" />
          </StyledTooltip>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <StyledCard //@ts-ignore
      stopShadowBox
      borderless={isCheckoutForm}
      width={!isCheckoutForm ? '773px' : '100%'}
      height={'640px'}
      style={{ marginTop: '25px' }}
    >
      <Space direction="vertical">
        <CardTitleSpan>
          {isCheckoutForm
            ? t('billing.need-details')
            : t('users.billing-details')}
        </CardTitleSpan>
        {isCheckoutForm && (
          <CardDescSpan>{t('billing.finalize-price')}</CardDescSpan>
        )}
      </Space>
      <Form
        form={billingForm}
        onFinish={handleSubmit}
        style={{ paddingTop: '40px' }}
      >
        <Row justify={'center'} align={'middle'}>
          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('signup.address-line1')}</span>
            </LabelWrapper>
            <Form.Item
              name="addressLine1"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput
                width="95%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('signup.address-line2')}</span>
            </LabelWrapper>
            <Form.Item name="addressLine2">
              <FormInput
                width="95%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('signup.town')}</span>
            </LabelWrapper>
            <Form.Item
              name="town"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput
                width="95%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('signup.country')}</span>
            </LabelWrapper>
            <Form.Item
              name="country"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormSelect //@ts-ignore
                width="95%"
                height="45px"
                bgcolor="#FFFFFF"
                bordercolor="#DBEAE3"
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().includes(input.toLowerCase())
                }
                onChange={onCountryChange}
              >
                {countries &&
                  countries.map((c) => (
                    <Select.Option key={c.iso3} value={c.name} label={c.name}>
                      {renderCountryWithFlag(c)}
                    </Select.Option>
                  ))}
              </FormSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('signup.zip-code')}</span>
            </LabelWrapper>
            <Form.Item
              name="zipCode"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput
                width="95%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>

          {
            //!isCheckoutForm && (
            <>
              <Col span={12} style={{ display: 'flex' }}>
                <div style={{ width: '28%', marginRight: '2%' }}>
                  <LabelWrapper>
                    <span className="label-text">{t('signup.code')}</span>
                  </LabelWrapper>
                  <Form.Item
                    name="code"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <FormSelect //@ts-ignore
                      width={'95%'}
                      height="45px"
                      bordercolor="#DBEAE3"
                      dropdownStyle={{ minWidth: '330px' }}
                      optionLabelProp="value"
                      id="counties-code"
                      bgcolor="#FFFFFF"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option?.value
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {countriesExternalData &&
                        countriesExternalData.map((c) =>
                          renderPhoneCodeOption(c)
                        )}
                    </FormSelect>
                  </Form.Item>
                </div>
                <div style={{ width: '68%' }}>
                  <LabelWrapper>
                    <span className="label-text">{t('signup.phone')}</span>
                  </LabelWrapper>
                  <Form.Item
                    name="phone"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <FormInput
                      width={'95%'} //@ts-ignore
                      border_color="#DBEAE3"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <LabelWrapper>
                  <span className="label-text">
                    {t('signup.email-address')}
                  </span>
                </LabelWrapper>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: t('signup.required-field') },
                    {
                      type: 'email',
                      message: t('signup.invalid-email'),
                    },
                  ]}
                >
                  <FormInput
                    width="95%" //@ts-ignore
                    border_color="#DBEAE3"
                  />
                </Form.Item>
              </Col>
            </>
            /*)*/
          }

          {
            //isCheckoutForm && (
            <Col span={12}>
              <LabelWrapper>
                <span className="label-text">{t('signup.currency')}</span>
              </LabelWrapper>
              <Form.Item
                name="currency"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
                //style={{ width: '29%', marginRight: '2%' }}
              >
                <FormSelect //@ts-ignore
                  width={'95%'}
                  height="45px"
                  bordercolor="#DBEAE3"
                  dropdownStyle={{ minWidth: '330px' }}
                  optionLabelProp="value"
                  id="counties-code"
                  bgcolor="#FFFFFF"
                  onChange={onCurrencyChange}
                >
                  {renderCurrencyList()}
                </FormSelect>
              </Form.Item>
            </Col>
            /*)*/
          }

          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('signup.company-name')}</span>
            </LabelWrapper>
            <Form.Item
              name="companyName"
              rules={[{ required: true, message: t('signup.required-field') }]}
            >
              <FormInput
                width="95%" //@ts-ignore
                border_color="#DBEAE3"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <LabelWrapper>
              <span className="label-text">{t('main.VAT')}</span>
            </LabelWrapper>
            <Form.Item
              name="vat"
              rules={[{ required: false, message: t('signup.required-field') }]}
            >
              <FormInput
                status={
                  !isVATNumberValid && !isEmptyVAT && !isLoading && isEurCountry
                    ? 'error'
                    : ''
                }
                width="95%" //@ts-ignore
                border_color="#DBEAE3"
                onBlur={onBlurVATNumber}
                onChange={onVATInputChange}
                suffix={renderSuffix()}
                loading={isLoading}
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>

        {!isCheckoutForm && (
          <Form.Item>
            <ScopeButton //@ts-ignore
              width="172px"
              height="40px"
              type="primary"
              htmlType="submit"
            >
              {t('common.save')}
            </ScopeButton>
          </Form.Item>
        )}
      </Form>
    </StyledCard>
  );
};
