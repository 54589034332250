import { call, delay, put, select } from 'redux-saga/effects';
import { fetchUsersFailure, fetchUsersSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { checkResponse } from 'store/utils';
import { FetchUserResponse, getUsers } from '../api';
import { MessageData, ProfilePicture } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { User } from 'store/auth/models';
import { PICTURE_DELAY_TIME } from 'store/app/constants';
import { getProfilePictures } from 'store/app/selectors';
import { fetchProfilePictureRequest } from 'store/account/actions';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchUsersSaga() {
  try {
    let err: ApiError | null;

    const response: FetchUserResponse = yield call(getUsers);

    err = checkResponse(response?.responseMessage);
    if (err) throw err;
    const members: User[] = response?.data?.members;
    yield put(fetchUsersSuccess(members));
    ///Get pics

    let pp: ProfilePicture[] = yield select(getProfilePictures);
    let newMembers = [...members];
    for (let i = 0; i < members.length; i++) {
      const user = members[i];
      if (user && user.hasProfilePicture && !user.profilePicture) {
        const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
        if (ppIdx > -1) {
          const profilePicture = pp[ppIdx].profilePicture;
          newMembers[i] = { ...newMembers[i], profilePicture };
        } else {
          yield put(fetchProfilePictureRequest(user.userUuid));
          yield delay(PICTURE_DELAY_TIME);
          pp = yield select(getProfilePictures);
          const ppIdx = pp.findIndex((p) => p.userUuid === user.userUuid);
          if (ppIdx > -1) {
            const profilePicture = pp[ppIdx].profilePicture;
            newMembers[i] = { ...newMembers[i], profilePicture };
          }
        }
      }
    }
    yield put(fetchUsersSuccess(newMembers));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchUsersFailure());
  }
}
