import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Space, Spin } from 'antd';
import { LinkButton, ScopeButton } from 'components/Custom/buttons';
import { GrayCard, StyledCard } from 'components/Custom/card';
import { NumericInput } from 'components/Custom/inputs';
import {
  CardDescSpan,
  GreenSpan,
  ListItemSpan,
  TableItemSpan,
} from 'components/Custom/spans';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddOnsType, Currency, SubscriptionPlan } from 'store/api/enums';
import Features from 'store/billing/models/features.json';
import { SelectedPlan } from 'store/billing/types';
import {
  getCurrencySymbol,
  getPriceByCurrency,
} from 'utils/calcAmountCurrency';
import { Country, CountryFlag } from 'store/common/models';
import { BillingForm } from 'components/AccountComponent/billing-form';
import { ReactComponent as SmallIcon } from 'assets/images/icons/iconSmall.svg';
import { ReactComponent as MediumIcon } from 'assets/images/icons/iconMedium.svg';
import { ReactComponent as LargeIcon } from 'assets/images/icons/iconLarge.svg';
import { ReactComponent as EnterpriseIcon } from 'assets/images/icons/iconEnterprise.svg';
import { ReactComponent as RocketIcon } from 'assets/images/icons/rocketIcon.svg';
import { Link } from 'react-router-dom';
import { PlanPricingDetails } from 'store/billing/models/planPricingDetails';

interface CheckoutComponentProps {
  selectedPlan: SelectedPlan;
  backToChangePlan: () => void;
  continueToCheckout: (billing: any) => void;
  countries: Country[];
  countriesExternalData: CountryFlag[];
  changeCurrency: (newCurrency: Currency) => void;
  validateVatNumber: (countryCode: string, taxNumber: string) => void;
  isVATNumberValid: boolean;
  isLoading?: boolean;
  resetValidationVatNumber?: () => void;
  subscriptionPlanPricingDetails: PlanPricingDetails | undefined;
  hideBillingDetails: boolean;
}

const VAT_PERCENTAGE = 18;

const MALTA_ISO = 'MLT';

const EUROPE_ISOS = [
  'IRL',
  'ITA',
  'LVA',
  'LTU',
  'LUX',
  'AUT',
  'NLD',
  'PRT',
  'SVK',
  'SVN',
  'ESP',
  'BEL',
  'CYP',
  'EST',
  'FIN',
  'FRA',
  'DEU',
  'GRC',
  'BGR',
  'HRV',
  'CZE',
  'DNK',
  'HUN',
  'POL',
  'ROU',
  'SWE',
  'GBR',
];

export const CheckoutComponent: React.FC<CheckoutComponentProps> = ({
  selectedPlan,
  backToChangePlan,
  continueToCheckout,
  countries,
  countriesExternalData,
  changeCurrency,
  validateVatNumber,
  isVATNumberValid,
  isLoading,
  resetValidationVatNumber,
  subscriptionPlanPricingDetails,
  hideBillingDetails,
}) => {
  const { t } = useTranslation();
  const [billingForm] = Form.useForm();
  const [vatPercentage, setVATPercentage] = React.useState(0);
  const [additionalEntities, setAdditionalEntities] = React.useState(0);
  const [featuresOfCurrentPlan, setFeaturesOfCurrentPlan] = React.useState<
    string[]
  >([]);
  const [vatNumberCheckerPerEntity, setVatNumberCheckerPerEntity] =
    React.useState(1);
  const [isEmptyVAT, setIsEmptyVAT] = React.useState(true);
  const [isEurCountry, setIsEurCountry] = React.useState(false);

  React.useEffect(() => {
    billingForm.setFieldsValue({
      addressLine1: '',
      addressLine2: '',
      town: '',
      country: '',
      zipCode: '',
      code: '',
      phone: '',
      email: '',
      currency: selectedPlan?.selectedCurrency,
      vat: '',
      companyName: '',
    });
  }, []);

  React.useEffect(() => {
    if (subscriptionPlanPricingDetails) {
      setFeaturesOfCurrentPlan(
        getFeaturesOfCurrentPlan(subscriptionPlanPricingDetails?.name + ' Plan')
      );
      setAdditionalEntities(
        subscriptionPlanPricingDetails?.minimumAdditionalEntities
      );
    }
  }, [subscriptionPlanPricingDetails]);

  const getFeaturesOfCurrentPlan = (currentPlan: SubscriptionPlan) => {
    const plan = currentPlan.split(' ')[0]?.toLowerCase();
    const integrationsIncludedIndex = Features.findIndex(
      (fet) => fet.feature?.toLowerCase() === 'integrations included'
    );
    const lastIntegrationIndex = Features.findIndex(
      (item, index) =>
        index > integrationsIncludedIndex && Object.keys(item).length == 0
    );
    const integrationFeatures = Features.slice(
      integrationsIncludedIndex + 1,
      lastIntegrationIndex
    );
    const thirdParties = integrationFeatures
      .filter((feature) => feature[plan])
      .map((feature) => feature.feature);

    const planFeatures = Features.map((feature, index) => {
      if (
        Object.keys(feature).length > 0 &&
        !feature.isCategoryFeature &&
        feature[plan] !== 'n/a' &&
        feature[plan] !== false &&
        !feature.feature?.includes('coming soon') &&
        !feature.feature?.toLowerCase().startsWith('additional') &&
        (index < integrationsIncludedIndex || index > lastIntegrationIndex)
      ) {
        if (feature.feature?.toLowerCase() == 'connected entities') {
          return `${feature[plan]} ${feature.feature}`;
        } else {
          return feature.feature;
        }
      }
    });
    planFeatures.push(`Integrations Included: ${thirdParties.join(', ')}`);
    return planFeatures.filter((item) => item !== undefined);
  };

  const calcAddOns = (addOnsType: AddOnsType) => {
    const currPlan = subscriptionPlanPricingDetails?.name;
    if (currPlan) {
      const vatNumberCheckerAddOnPrice = getPriceByCurrency(
        selectedPlan?.selectedCurrency,
        29,
        currPlan?.toLowerCase(),
        false
      );
      const additionalEntitiesAddOnPrice = getPriceByCurrency(
        selectedPlan?.selectedCurrency,
        2,
        currPlan?.toLowerCase(),
        false
      );

      if (
        addOnsType == AddOnsType.VAT_NUMBER_CHECKER &&
        vatNumberCheckerAddOnPrice != 'n/a'
      ) {
        return vatNumberCheckerPerEntity * vatNumberCheckerAddOnPrice;
      } else if (
        addOnsType == AddOnsType.ADDITIONAL_ENTITIES &&
        additionalEntitiesAddOnPrice != 'n/a'
      ) {
        return additionalEntities * additionalEntitiesAddOnPrice;
      } else {
        return 0;
      }
    }
  };

  const calcVATAmount = () => {
    const subTotalAmount = calcSubTotal();
    const vatAmount = subTotalAmount * (vatPercentage / 100);
    return vatAmount.toFixed(2);
  };

  const calcSubTotal = () => {
    const currPlan = subscriptionPlanPricingDetails?.name;
    const vatNumAddOn = calcAddOns(AddOnsType.VAT_NUMBER_CHECKER);
    const addEntitiesAddOn = calcAddOns(AddOnsType.ADDITIONAL_ENTITIES);
    if (currPlan) {
      const planPrice = getPriceByCurrency(
        selectedPlan?.selectedCurrency,
        27,
        currPlan?.toLowerCase(),
        false
      );
      return vatNumAddOn + addEntitiesAddOn + Number(planPrice);
    }
  };

  const calcTotal = () => {
    const totalAmount = Number(calcVATAmount()) + calcSubTotal();
    return totalAmount.toFixed(2);
  };

  const renderPlanFeaturesCard = () => {
    const currPlan = subscriptionPlanPricingDetails?.name;
    return (
      <StyledCard //@ts-ignore
        //width={'fit-content'}
        width="320px"
        style={{ marginTop: '30px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            paddingBottom: '30px',
            borderBottom: '1px solid #DBEAE3',
            marginBottom: '25px',
          }}
        >
          <TableItemSpan fontSize="16px" fontWeight="500" lineHeight="22px">
            {currPlan}
          </TableItemSpan>
          <GreenSpan fontSize="12px" fontWeight="600" lineHeight="22px">
            {currPlan
              ? getPriceByCurrency(
                  selectedPlan?.selectedCurrency,
                  27,
                  currPlan?.toLowerCase()
                )
              : ''}
          </GreenSpan>
        </div>
        <Space
          direction="vertical"
          style={{
            width: 267,
            paddingBottom: '25px',
            borderBottom: '1px solid #DBEAE3',
            marginBottom: '25px',
          }}
        >
          <TableItemSpan fontSize="16px" fontWeight="500" lineHeight="22px">
            {t('billing.add-ons')}
          </TableItemSpan>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemSpan lineHeight="22px">
              {' '}
              {t('billing.vat-Number-checker')}{' '}
              <TableItemSpan fontWeight="600" lineHeight="22px">
                x{vatNumberCheckerPerEntity}
              </TableItemSpan>
            </ListItemSpan>
            <GreenSpan fontSize="12px" fontWeight="600" lineHeight="22px">
              {`${getCurrencySymbol(
                selectedPlan?.selectedCurrency
              )} ${calcAddOns(AddOnsType.VAT_NUMBER_CHECKER)}`}
            </GreenSpan>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemSpan lineHeight="22px">
              {' '}
              {t('billing.additional-organisations')}{' '}
              <TableItemSpan fontWeight="600" lineHeight="22px">
                x{additionalEntities}
              </TableItemSpan>
            </ListItemSpan>
            <GreenSpan fontSize="12px" fontWeight="600" lineHeight="22px">
              {`${getCurrencySymbol(
                selectedPlan?.selectedCurrency
              )} ${calcAddOns(AddOnsType.ADDITIONAL_ENTITIES)}`}
            </GreenSpan>
          </div>
        </Space>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableItemSpan fontSize="12px" fontWeight="400" lineHeight="30px">
            {t('billing.subtotal')}
          </TableItemSpan>
          <TableItemSpan fontSize="12px" fontWeight="400" lineHeight="30px">
            {`${getCurrencySymbol(
              selectedPlan?.selectedCurrency
            )} ${calcSubTotal()}`}
          </TableItemSpan>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableItemSpan fontSize="12px" fontWeight="400" lineHeight="30px">
            {t('main.VAT')}
          </TableItemSpan>
          <TableItemSpan fontSize="12px" fontWeight="400" lineHeight="30px">
            {`${getCurrencySymbol(
              selectedPlan?.selectedCurrency
            )} ${calcVATAmount()}`}
          </TableItemSpan>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TableItemSpan fontSize="14px" fontWeight="700" lineHeight="30px">
            {t('billing.total')}
          </TableItemSpan>
          <TableItemSpan fontSize="14px" fontWeight="700" lineHeight="30px">
            {`${getCurrencySymbol(
              selectedPlan?.selectedCurrency
            )} ${calcTotal()}`}
          </TableItemSpan>
        </div>
        <ScopeButton
          type="primary" //@ts-ignore
          width="246px"
          height="32px"
          style={{ margin: '0 12px', marginTop: '18px' }}
          onClick={() => {
            let billing = billingForm.getFieldsValue();
            billing = {
              ...billing,
              additionalEntities,
              vatnumberchecker: vatNumberCheckerPerEntity,
            };
            continueToCheckout(billing);
          }}
          disabled={
            !isVATNumberValid && !isEmptyVAT && !isLoading && isEurCountry
          }
        >
          {t('billing.continue-to-checkout')}
        </ScopeButton>
      </StyledCard>
    );
  };

  const getNextPlan = (currentPlan: SubscriptionPlan) => {
    switch (currentPlan) {
      case SubscriptionPlan.SMALL_PLAN:
        return SubscriptionPlan.MEDIUM_PLAN;
        break;
      case SubscriptionPlan.MEDIUM_PLAN:
        return SubscriptionPlan.LARGE_PLAN;
        break;
      case SubscriptionPlan.LARGE_PLAN:
        return SubscriptionPlan.ENTERPRISE_PLAN;
        break;
      default:
        break;
    }
  };

  const isOwnerTaxPayer = (countryISO: string) => {
    if (countryISO) {
      if (countryISO === MALTA_ISO) {
        return true;
      } else if (
        EUROPE_ISOS.includes(countryISO) &&
        !billingForm.getFieldValue(
          'vat'
        ) /*&& StringUtils.isEmpty(accountDetails.getVatNumber())*/
      ) {
        return true;
      }
    }
    return false;
  };

  const getVatPercentage = (countryISO: string) => {
    setVATPercentage(isOwnerTaxPayer(countryISO) ? VAT_PERCENTAGE : 0);
    if (EUROPE_ISOS.includes(countryISO)) {
      setIsEurCountry(true);
    } else {
      setIsEurCountry(false);
      resetValidationVatNumber && resetValidationVatNumber();
    }
  };

  const getPlanIcon = (currentPlan) => {
    switch (currentPlan) {
      case SubscriptionPlan.SMALL_PLAN:
        return (
          <SmallIcon
            style={{
              boxShadow: '0px 4px 10px 0px #0000000D',
              borderRadius: '50px',
            }}
          />
        );
        break;
      case SubscriptionPlan.MEDIUM_PLAN:
        return (
          <MediumIcon
            style={{
              boxShadow: '0px 4px 10px 0px #0000000D',
              borderRadius: '50px',
            }}
          />
        );
        break;
      case SubscriptionPlan.LARGE_PLAN:
        return (
          <LargeIcon
            style={{
              boxShadow: '0px 4px 10px 0px #0000000D',
              borderRadius: '50px',
            }}
          />
        );
        break;
      case SubscriptionPlan.ENTERPRISE_PLAN:
        return (
          <EnterpriseIcon
            style={{
              boxShadow: '0px 4px 10px 0px #0000000D',
              borderRadius: '50px',
            }}
          />
        );
        break;
      default:
        break;
    }
  };

  const renderCheckoutForm = () => {
    return (
      <div
        style={{
          padding: '0 40px',
          width: '100%',
          margin: 'auto',
          maxWidth: 1100,
          marginTop: '30px',
        }}
      >
        <Space
          direction="vertical"
          style={{ width: '100%', rowGap: 25, marginBottom: 30 }}
        >
          <StyledCard //@ts-ignore
            //width={'fit-content'}
            width="100%"
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexFlow: 'row',
                alignContent: 'normal',
                margin: '4px 4px',
                paddingBottom: '20px',
                paddingTop: '10px',
                marginBottom: '45px',
              }}
            >
              <StyledCard //@ts-ignore
                //width={'fit-content'}
                width="100%"
                bordered={false}
                stopShadowBox
                style={{
                  overflow: 'auto',
                  background:
                    'linear-gradient(180deg, #EDFAF6 0%, #E1F4FE 100%)',
                  border: 'none',
                  maxHeight: '300px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexFlow: 'row',
                    alignContent: 'normal',
                    margin: '4px 23px',
                    paddingTop: '2px',
                  }}
                >
                  <Space style={{ columnGap: 20 }}>
                    {getPlanIcon(subscriptionPlanPricingDetails?.name)}
                    <TableItemSpan
                      fontSize="18px"
                      lineHeight="37px"
                      fontWeight="400"
                    >
                      {subscriptionPlanPricingDetails?.name + ' Plan'}
                    </TableItemSpan>
                  </Space>
                  <LinkButton
                    type="link"
                    color="#41AAAF"
                    onClick={backToChangePlan}
                  >
                    {t('billing.change-plan')}
                  </LinkButton>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexFlow: 'row',
                    alignContent: 'normal',
                    margin: '4px 23px',
                    paddingTop: '6px',
                  }}
                >
                  <ul
                    style={{
                      color: '#879494',
                      paddingLeft: '25px',
                      marginBottom: '25px',
                    }}
                  >
                    {featuresOfCurrentPlan.slice(0, 4).map((feature, index) => (
                      <li key={index} style={{ maxWidth: '238px' }}>
                        <ListItemSpan>{feature}</ListItemSpan>
                      </li>
                    ))}
                  </ul>

                  <ul
                    style={{
                      color: '#879494',
                      paddingLeft: '25px',
                      marginBottom: '25px',
                    }}
                  >
                    {featuresOfCurrentPlan.slice(4, 8).map((feature, index) => (
                      <li key={index} style={{ maxWidth: '238px' }}>
                        <ListItemSpan>{feature}</ListItemSpan>
                      </li>
                    ))}
                  </ul>

                  <ul
                    style={{
                      color: '#879494',
                      paddingLeft: '25px',
                      marginBottom: '25px',
                    }}
                  >
                    {featuresOfCurrentPlan
                      .slice(8, featuresOfCurrentPlan.length)
                      .map((feature, index) => (
                        <li key={index} style={{ maxWidth: '238px' }}>
                          <ListItemSpan>{feature}</ListItemSpan>
                        </li>
                      ))}
                  </ul>
                </div>
              </StyledCard>
            </div>
            <div
              style={{
                margin: '4px 23px',
                marginBottom: '30px',
                borderBottom: '1px solid #DBEAE3',
              }}
            >
              <div
                style={{
                  //margin: '4px 23px',
                  marginBottom: '20px',
                }}
              >
                <TableItemSpan
                  fontSize="18px"
                  lineHeight="24px"
                  fontWeight="400"
                >
                  {t('organisation.entities')}
                </TableItemSpan>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexFlow: 'row',
                  alignContent: 'normal',
                  paddingBottom: '15px',
                  paddingTop: '15px',
                }}
              >
                <TableItemSpan
                  fontSize="14px"
                  lineHeight="42px"
                  fontWeight="400"
                >
                  {t('billing.additional-entities')}
                </TableItemSpan>
                <NumericInput
                  min={
                    subscriptionPlanPricingDetails?.minimumAdditionalEntities
                  }
                  addonAfter={
                    <PlusOutlined
                      style={{ color: '#41AAAF' }}
                      onClick={() =>
                        setAdditionalEntities(additionalEntities + 1)
                      }
                    />
                  }
                  addonBefore={
                    <MinusOutlined
                      disabled={
                        additionalEntities <=
                        subscriptionPlanPricingDetails?.minimumAdditionalEntities
                      }
                      style={{ color: '#41AAAF' }}
                      onClick={() =>
                        setAdditionalEntities(additionalEntities - 1)
                      }
                    />
                  }
                  controls={false}
                  value={additionalEntities}
                  onChange={(value) => setAdditionalEntities(value)}
                />
              </div>
              {subscriptionPlanPricingDetails?.name !=
                SubscriptionPlan.ENTERPRISE_PLAN && (
                <GrayCard style={{ margin: '0px 0px 30px' }}>
                  <Space style={{ columnGap: 16 }}>
                    <RocketIcon />
                    <TableItemSpan
                      fontSize="12px"
                      lineHeight="24px"
                      fontWeight="400"
                    >
                      <>
                        {`If you have more than ${additionalEntities} entities, we recommend upgrading to the `}
                        <Link
                          style={{
                            textDecoration: 'underline',
                            color: '#41AAAF',
                          }}
                          to="/pricing"
                          onClick={backToChangePlan}
                        >
                          <TableItemSpan
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="400"
                            fontColor="#41AAAF"
                          >
                            {getNextPlan(subscriptionPlanPricingDetails?.name)}
                          </TableItemSpan>
                        </Link>{' '}
                        {' to benefit from '}
                        <b>additional functionality</b>
                        {' and a higher number of included entities.'}
                      </>
                    </TableItemSpan>
                  </Space>
                </GrayCard>
              )}
            </div>
            <div
              style={{
                margin: '4px 23px',
              }}
            >
              <TableItemSpan fontSize="18px" lineHeight="24px" fontWeight="400">
                {t('billing.add-ons')}
              </TableItemSpan>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexFlow: 'row',
                  alignContent: 'normal',
                  paddingBottom: '15px',
                  paddingTop: '15px',
                }}
              >
                <TableItemSpan
                  fontSize="14px"
                  lineHeight="42px"
                  fontWeight="400"
                >
                  {t('billing.vat-Number-checker')}{' '}
                  <CardDescSpan>{`(${t('billing.per-entity')})`}</CardDescSpan>
                </TableItemSpan>
                <NumericInput
                  disabled={
                    subscriptionPlanPricingDetails?.name ==
                      SubscriptionPlan.SMALL_PLAN ||
                    subscriptionPlanPricingDetails?.name ==
                      SubscriptionPlan.MEDIUM_PLAN
                  }
                  addonAfter={
                    <PlusOutlined
                      style={{ color: '#41AAAF' }}
                      onClick={() =>
                        setVatNumberCheckerPerEntity(
                          vatNumberCheckerPerEntity + 1
                        )
                      }
                    />
                  }
                  addonBefore={
                    <MinusOutlined
                      style={{ color: '#41AAAF' }}
                      onClick={() =>
                        setVatNumberCheckerPerEntity(
                          vatNumberCheckerPerEntity - 1
                        )
                      }
                    />
                  }
                  controls={false}
                  value={vatNumberCheckerPerEntity}
                  onChange={(value) => setVatNumberCheckerPerEntity(value)}
                />
              </div>
            </div>
          </StyledCard>
          {!hideBillingDetails && (
            <StyledCard //@ts-ignore
              //width={'fit-content'}
              width="100%"
              style={{
                overflow: 'auto',
              }}
            >
              <BillingForm
                billingForm={billingForm}
                countries={countries}
                countriesExternalData={countriesExternalData}
                isCheckoutForm={true}
                selectedCurrency={selectedPlan?.selectedCurrency}
                changeCurrency={changeCurrency}
                calcVATPercentage={getVatPercentage}
                validateVatNumber={validateVatNumber}
                isVATNumberValid={isVATNumberValid}
                isLoading={isLoading}
                isEmptyVAT={isEmptyVAT}
                setIsEmptyVAT={setIsEmptyVAT}
                isEurCountry={isEurCountry}
                EUROPE_ISOS={EUROPE_ISOS}
              />
            </StyledCard>
          )}
        </Space>
      </div>
    );
  };

  return (
    <Spin spinning={isLoading} size="large">
      <div className="entity-overview-tab">
        <div
          //style={{ float: 'left', width: '60%', margin: '0 10%' }}
          style={{ width: 'calc(100vw - 450px)' }}
        >
          {renderCheckoutForm()}
        </div>

        <div
          style={{
            height: '100vh',
            position: 'fixed',
            float: 'right',
            right: 40,
            //width: '25%',
          }}
        >
          {renderPlanFeaturesCard()}
        </div>
      </div>
    </Spin>
  );
};
