import { call, put, select } from 'redux-saga/effects';
import {
  exceptionsActionFailure,
  exceptionsActionSuccess,
  fetchActivityLogsRequest,
  loadReportRequest,
} from '../actions';
import { ApiError } from 'store/api/types';
import { ExceptionsActionRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { exceptionsAction } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { getVatRunData } from '../selectors';
import { VatLoadRequestData } from '../models/requestData';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* saveExceptionsActionSaga(
  action: ExceptionsActionRequestAction
) {
  try {
    let err: ApiError | null;
    const { dataLogId, mappingId, exceptionsWrapper } = action.payload;
    const response = yield call(
      exceptionsAction,
      dataLogId,
      mappingId,
      exceptionsWrapper
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(exceptionsActionSuccess());
    const vatRunData = yield select(getVatRunData);
    const vatLoadRequestData: VatLoadRequestData = {
      connectionId: vatRunData.tokenId,
      fromDate: vatRunData.fromDate,
      toDate: vatRunData.toDate,
      currency: vatRunData.currency,
      mappingId,
      dataLogId,
    };

    yield put(loadReportRequest(vatLoadRequestData));
    yield put(fetchActivityLogsRequest(dataLogId));

    const message: MessageData = {
      title: 'Success!',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(exceptionsActionFailure());
  }
}
