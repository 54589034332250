import * as React from 'react';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { TaxReportCountry } from 'store/app/enums';
import { MaltaForm } from './forms/malta';
import { VatReportData } from 'store/vat/models';
import { Alert, Divider, Popover, Radio, Switch } from 'antd';
import { ReportCssWrapper } from './report-css-wrapper';
import { CyprusForm } from './forms/cyprus';
import { UAEForm } from './forms/uae';
import { IrelandForm } from './forms/ireland';
import { IsleOfManForm } from './forms/isleOfMan';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copyIcon.svg';
import { Space } from 'antd';
import { ExportButton, IconButton } from 'components/Custom/buttons';
import { MessageStates } from 'containers/MessageBox';
import { MessageData } from 'store/app/types';
import { turnMessageOn } from 'store/app/actions';
import { useDispatch } from 'react-redux';
import { AccountType } from 'store/api/enums';
import { User } from 'store/auth/models';
import { StyledCard } from 'components/Custom/card';
import { JerseyForm } from './forms/jersey';
import { NetherlandsForm } from './forms/netherlands';

interface VatReturnFormsProps {
  taxReportCountry: TaxReportCountry;
  data?: VatReportData;
  resetValues: boolean;
  resetFinished: () => void;
  setActiveKey: (key) => void;
  setFilteredBox: (key) => void;
  renderClientBox: () => void;
  renderContextMenu: () => void;
  currentUser: User;
  isAdjustedReturn?: boolean;
  switchTotals: boolean;
  setSwitchTotals: (value: boolean) => void;
  exporting: boolean;
}

export const VatReturnForms: React.FC<VatReturnFormsProps> = ({
  taxReportCountry,
  data,
  resetValues,
  resetFinished,
  setActiveKey,
  setFilteredBox,
  renderClientBox,
  renderContextMenu,
  currentUser,
  isAdjustedReturn,
  switchTotals,
  setSwitchTotals,
  exporting,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (resetValues) {
      const valueFields = document.querySelectorAll('.value-field-span');
      valueFields.forEach((valueField) => {
        valueField.textContent = '0.00';
      });
      resetFinished();
    }
  }, [resetValues]);

  const copyCurrentValue = (boxNumber: string) => {
    const subsectionValueSpan = document.getElementById(
      `subsection${boxNumber}-value-span`
    );
    if (subsectionValueSpan?.textContent) {
      navigator.clipboard.writeText(subsectionValueSpan.textContent);
    }

    const message: MessageData = {
      title: '',
      description: 'Copied to clipboard!',
      type: MessageStates.INFO,
    };
    dispatch(turnMessageOn(message));
  };

  const showTransactionByBox = (boxNumber: string) => {
    setActiveKey('2');
    const subsectionBoxNameCell = document.getElementById(
      `subsection${boxNumber}-box-name`
    );
    setFilteredBox(subsectionBoxNameCell?.textContent);
  };

  const renderValueCell = (boxNumber: string, withP = false) => {
    const id = withP
      ? `subsection${boxNumber}-value-p-span`
      : `subsection${boxNumber}-value-span`;
    return (
      <>
        <p id={id} className="value-field-span">
          0.00
        </p>
        <Space className="show-on-hover">
          <IconButton
            type="default"
            className="icon-button-hover"
            style={{
              height: '30px',
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center',
              padding: '14px 10px',
            }}
            icon={
              <EyeIcon className="hover-icon" width={'23px'} height={'23px'} />
            }
            onClick={() => showTransactionByBox(boxNumber)}
          ></IconButton>
          <IconButton
            type="default"
            className="icon-button-hover"
            style={{
              height: '30px',
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center',
              padding: '14px 10px',
            }}
            icon={
              <CopyIcon className="hover-icon" width={'16px'} height={'16px'} />
            }
            onClick={() => copyCurrentValue(boxNumber)}
          ></IconButton>
        </Space>
      </>
    );
  };

  const renderForm = () => {
    if (true) {
      switch (taxReportCountry) {
        case TaxReportCountry.MALTA:
          return (
            <ReportCssWrapper>
              <MaltaForm
                data={data}
                renderValueCell={renderValueCell}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.NETHERLANDS:
          return (
            <ReportCssWrapper>
              <NetherlandsForm
                data={data}
                renderValueCell={renderValueCell}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.CYPRUS:
          return (
            <ReportCssWrapper>
              <CyprusForm
                data={data}
                renderValueCell={renderValueCell}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.JERSEY:
          return (
            <ReportCssWrapper>
              <JerseyForm
                data={data}
                renderValueCell={renderValueCell}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.UAE:
          return (
            <ReportCssWrapper>
              <UAEForm
                data={data}
                renderValueCell={renderValueCell}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.ISLE_OF_MAN:
          return (
            <ReportCssWrapper>
              <IsleOfManForm
                data={data}
                renderValueCell={renderValueCell}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.IRELAND_DETAILED:
        case TaxReportCountry.IRELAND_STANDARD:
        case TaxReportCountry.IRELAND_RTD:
          return (
            <ReportCssWrapper>
              <IrelandForm
                data={data}
                renderValueCell={renderValueCell}
                taxReportCountry={taxReportCountry}
                finalTotals={switchTotals}
              />
            </ReportCssWrapper>
          );
        default:
          return (
            <Alert
              message="Error"
              description={`No return forms were found for ${taxReportCountry}`}
              type="error"
              showIcon
            />
          );
      }
    }
  };
  const renderCardTitle = () => {
    return (
      <>
        <Space align="center">
          {isAdjustedReturn && (
            <Radio.Group
              defaultValue={switchTotals}
              buttonStyle="solid"
              style={{ borderRadius: '10px' }}
              onChange={() => setSwitchTotals(!switchTotals)}
            >
              <Radio.Button value={false}>Original</Radio.Button>
              <Radio.Button value={true}>
                Final (Includes Adjustments)
              </Radio.Button>
            </Radio.Group>
          )}
          <Popover
            placement="topLeft"
            arrow={false}
            trigger="click"
            content={renderContextMenu()}
            title={''}
            overlayStyle={{ zIndex: 999 }}
          >
            <ExportButton icon={<IconExport />} loading={exporting}>
              <span>Export VAT Return</span>
            </ExportButton>
          </Popover>
        </Space>
        <Divider />
      </>
    );
  };
  return (
    <StyledCard //@ts-ignore
      style={{
        //width: showSideMenu ? 'calc(100vw - 550px)' : '100%',
        maxWidth: 1150,
        minWidth: 780,
        margin: 'auto',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '1100px',
          minWidth: '780px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div className="form-wrapper">
          {currentUser &&
            AccountType[currentUser.accountType] === AccountType.CLIENT &&
            renderClientBox()}
          <div
            style={{
              width: '100%',
              //display: 'flex',
              //justifyContent: 'space-between',
              //alignItems: 'center',
            }}
          >
            {renderCardTitle()}
          </div>
          <div id="vat-to-be-exported">{renderForm()}</div>
        </div>
      </div>
    </StyledCard>
  );
};
