import { call, put, select } from 'redux-saga/effects';
import {
  editOrganisationsFailure,
  fetchOrganisationsSuccess,
} from '../actions';
import { editOrganisation } from '../api';
import { ApiError, ApiResponse } from 'store/api/types';

import { checkResponse } from 'store/utils';
import { EditOrganisationParams, Organisation } from '../models';
import { EditOrganisationRequestAction } from '../types';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { turnMessageOn } from 'store/app/actions';
import { getOrgMappings, getOrgOrganisations } from '../selectors';
import { getViesInfos } from 'store/vat-checker/selectors';
import { ViesInfo } from 'store/vat-checker/models';
import { fetchViesInfoSuccess } from 'store/vat-checker/actions';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* editOrganisationsSaga(action: EditOrganisationRequestAction) {
  try {
    let err: ApiError | null;
    const params: EditOrganisationParams = action.payload.editOrganisation;
    const editResponse: ApiResponse = yield call(editOrganisation, params);

    err = checkResponse(editResponse?.responseMessage);
    if (err) throw err;

    const orgs: Organisation[] = yield select(getOrgOrganisations);
    const maps = yield select(getOrgMappings);
    const viesInfos: ViesInfo[] = yield select(getViesInfos);

    const idx = orgs.findIndex((o) => o.uuid === params.refId);
    if (idx) {
      const friendlyName = params.newName;

      orgs[idx] = { ...orgs[idx], friendlyName };
      yield put(fetchOrganisationsSuccess(orgs, maps));

      const idx2 = viesInfos.findIndex(
        (vi) => vi.connectionId === orgs[idx].connectionId
      );
      if (idx2) {
        yield put(
          fetchViesInfoSuccess([
            {
              ...viesInfos[idx2],
              organisationName: params.newName,
            },
          ])
        );
      }
    }
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(editOrganisationsFailure());
  }
}
