import { call, put } from 'redux-saga/effects';
import {
  fetchSubscriptionTypesFailure,
  fetchSubscriptionTypesSuccess,
} from '../actions';
import { ApiError } from 'store/api/types';
import { FetchSubscriptionTypesRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import { SubscriptionTypeResponse, fetchSubscriptionPlans } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchSubscriptionTypesSaga(
  action: FetchSubscriptionTypesRequestAction
) {
  try {
    let err: ApiError | null;
    //const { countryCode, taxNumber } = action.payload;

    const fetchSubscriptionTypesResponse: SubscriptionTypeResponse = yield call(
      fetchSubscriptionPlans
    );

    err = checkResponse(fetchSubscriptionTypesResponse?.responseMessage);
    if (err) throw err;

    yield put(
      fetchSubscriptionTypesSuccess(
        fetchSubscriptionTypesResponse?.data?.licenseTypes
      )
    );
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(fetchSubscriptionTypesFailure());
  }
}
