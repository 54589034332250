import { call, put } from 'redux-saga/effects';
import { getInvoicesFailure, getInvoicesSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { GetInvoicesRequestAction } from '../types';
import { checkResponse } from 'store/utils';
import { GetInvoicesResponse, getInvoices } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import { logoutRequest } from 'store/auth/actions';
import { renderErrorMessage } from 'utils/render-error-message';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* getInvoicesSaga(action: GetInvoicesRequestAction) {
  try {
    let err: ApiError | null;
    const { customerId } = action.payload;

    const getInvoicesResponse: GetInvoicesResponse = yield call(
      getInvoices,
      customerId
    );

    err = checkResponse(getInvoicesResponse?.responseMessage);
    if (err) throw err;

    yield put(getInvoicesSuccess(getInvoicesResponse?.data?.invoices));
  } catch (error: any) {
    const message: MessageData | null = renderErrorMessage(error);
    if (message) {
      yield put(turnMessageOn(message));
    } else {
      yield put(logoutRequest());
    }
    yield put(getInvoicesFailure());
  }
}
