//@ts-nocheck
import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

interface ScopeButtonProps extends ButtonProps {
  width?: string;
  height?: string;
  fontSize?: string;
  lineheight?: string;
  bg_color?: string;
  type?: string;
}
export const ScopeButton = styled(Button)<ScopeButtonProps>`
  width: ${(props) => (props.width ? props.width : '330px')};
  height: ${(props) => (props.height ? props.height : '45px')};
  //font-family: Inter;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
  font-weight: 600;
  line-height: ${(props) => (props.lineheight ? props.lineheight : '24px')};
  //text-align: center;
  border-radius: 50px;
  background-color: ${(props) =>
    props.bg_color && props.type === 'default'
      ? props.bg_color
      : props.type === 'dark-orange'
      ? '#FFBC3E'
      : 'none'};
  box-shadow: none;
  color: ${(props) =>
    props.type === 'default'
      ? '#41aaaf'
      : props.type === 'dark-orange'
      ? '#042A2A'
      : '#FFFFFF'} !important;
  border-color: ${(props) =>
    props.type == 'default'
      ? '#41aaaf'
      : props.type === 'dark-orange'
      ? '#FFBC3E'
      : '#64bbbd'} !important;
  ${(props) =>
    props.lineheight == '12px' &&
    `padding: 4px 12px;
  `}

  &:hover {
    .testpic {
      color: #042a2a;
      background: #fff5e2;
    }
    background-color: ${(props) =>
      props.type == 'default'
        ? '#41aaaf'
        : props.type === 'dark-orange'
        ? '#EDAC32'
        : '#ffbc3e'} !important;
    color: ${(props) =>
      props.type == 'default' ? '#FFFFFF' : '#042a2a'} !important;
    border-color: '#64bbbd' !important;
    box-shadow: none;
  }

  .testpic {
    color: #ffffff;
    font-size: 16px;
    width: 28px;
    height: 28px;
    background: #48b9bf;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
    margin-left: -30px;
  }

  ${(props) =>
    props.disabled &&
    `
  cursor: not-allowed !important;
  border-color: #cccccc !important;
  color: rgba(46, 46, 46, 0.25) !important;
  background-color: rgba(46, 46, 46, 0.04);
  box-shadow: none;
  
  &:hover {
    background-color: rgba(46, 46, 46, 0.04) !important;
    color: rgba(46, 46, 46, 0.25) !important;
    border-color: #cccccc !important;
    box-shadow: none;
  }
  `}
`;

export const SSOLoginButton = styled(Button)`
  min-width: ${(props) => (props.minwidth ? props.minwidth : '320px')};
  height: 45px;
  border-radius: 50px;
  background-color: #d2edef;
  color: #042a2a;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  box-shadow: none;
  padding-left: 8px;
  margin-right: ${(props) => (props.marginright ? props.marginright : '0')};
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: #d2edef;
  }
`;

export const LinkButton = styled(Button)`
    color: ${(props) => (props.color ? props.color : '#879494')};
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    > span {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
}`;

export const IconContextButton = styled(Button)`
    color: #042A2A;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 4px 2px !important;
    display: flex;
    width: 100%;
  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      color: #042A2A;
      background-color: #FFF5E2 !important;
    }
    `}
}`;

export const ContextButton = styled(Button)`
  border: 1px solid transparent;
  width: 33px;
  height: 25px;
  padding: 2px 2px;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : 'none')};
  border-radius: 5px;
    &:hover,
    &:active {
      border: 1px solid #41AAAF;
      background-color: #FFFFFF !important;
    }
}`;

export const IconButton = styled(Button)`
    border: 2px solid #DBEAE3;
    box-shadow: 0px 0px 10px 0px #0000000D;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: ${(props) => (props.color ? props.color : '#042A2A')};
    border-radius: 10px;

    &:hover {
      color: ${(props) => (props.disabled ? '' : '#042A2A')} !important;
      border: ${(props) =>
        props.disabled ? '' : '2px solid #41AAAF'} !important;
    }
}`;

export const ToggleButton = styled(Button)`
  .show-on-hover {
    display: none;
  }
  display: flex;
  background-color: #e9f6f7;
  width: 25px;
  min-width: 25px !important;
  padding-inline-start: 4px !important;
  height: 25px;
  color: #042a2a;
  border: 1px solid #dbeae3;
  margin-inline-start: -4px;
  box-shadow: none;
  transition: padding-right 0.5s;
  padding-left: 4px;
  &:hover {
    padding-inline-end: ${(props) =>
      props.paddinglft ? props.paddinglft : '82px'} !important;
    border-radius: 50px !important;
    padding-left: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    padding-top: 6px;
    background-color: #41aaaf !important;

    > .show-on-hover {
      display: block;
    }
  }
`;

export const EntityButton = styled.div`
  width: 147px;
  height: 147px;
  border-radius: 10px;
  border: 2px solid transparent;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    border: 2px solid #41aaaf;
  }
`;

export const RoundAddButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 25px;
  height: 30px;
  gap: 0px;
  border-radius: 50%;
  opacity: 0px;
  angle: -180 deg;
  background: #e9f6f7;
  border: 1px solid #dbeae3;
  color: #042a2a;
  ${(props) =>
    !props.disabled &&
    `
  &:hover {
    background: #e9f6f7 !important;
    border: 1px solid #41aaaf;
    color: #042a2a !important;
  }
  `}
`;

export const ExportButton = styled(Button)`
  border: 1px solid #DBEAE3;
  background-color: #FFFFFF !important;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border-radius: 10px;


  > span {  
    font-size: 12px;
    font-weight: 600;
    color:${(props) => (props.isGuide ? '#879494' : '#042A2A')} 
  }

  &:hover,
  &:active {
    border: 1px solid #41AAAF;
    //background-color: #FFFFFF !important;
  }
}`;

export const ContinueButton = styled(Button)`
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #058181;
  background-color: #D2EDEF;
  border-color: #D2EDEF;
  border-radius: 50px;
  width: ${(props) => (props.width ? props.width : '330px')};
  height: ${(props) => (props.height ? props.height : '45px')};
}`;

export const YellowButton = styled(ScopeButton)`
  color: ${(props) => (props.color ? props.color : '#FFFFFF')} !important;
  border-color: #ffbc3e !important;
  font-weight: 700;
  line-height: 20px;
`;

export const SelectButton = styled(ScopeButton)`
  color: ${(props) => (props.color ? props.color : '#FFFFFF')} !important;
  border: 2px solid
    ${(props) => (props.border_color ? props.border_color : '#FFFFFF')} !important;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    border-color: #41aaaf !important;
    background-color: #ffffff !important;
    color: #042a2a !important;
  }

  ${(props) =>
    props.selected &&
    `
    border-color: #41aaaf !important;
    background-color: #41aaaf !important;
    color: #FFFFFF !important;
  
  &:hover {
    border-color: #41aaaf !important;
    background-color: #41aaaf !important;
    color: #FFFFFF !important;
  }
  `}
`;

export const GrayButton = styled(ScopeButton)`
  color: ${(props) => (props.color ? props.color : '#FFFFFF')} !important;
  border-color: #879494 !important;
  background-color: #879494 !important;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  cursor: default !important;

  &:hover {
    border-color: #879494 !important;
    background-color: #879494 !important;
    color: ${(props) => (props.color ? props.color : '#FFFFFF')} !important;
  }
`;
