import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import LogoNormal from '../../assets/images/gt-logo-menu-normal.svg';
import LogoSmall from '../../assets/images/gt-logo-menu-small.svg';
import { SideMenu } from 'components/Custom/sideMenu';
import { Button, Row } from 'antd';
import { UserMenu } from 'components/Custom/userMenu';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MenuSize } from 'store/app/enums';
import { setMenuSize } from 'store/app/actions';
import { Link } from 'react-router-dom';
import Beta from 'assets/images/beta.png';
import { getAuthUser } from 'store/auth/selectors';

interface SidePanelProps {
  menuSize: MenuSize;
}

export const SidePanel: React.FC<SidePanelProps> = ({ menuSize }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getAuthUser);

  const redirectPage = (url: string) => {
    dispatch(push(url));
  };
  const onMenuButtonClick = () => {
    if (menuSize === MenuSize.NARROW) dispatch(setMenuSize(MenuSize.WIDE));
    if (menuSize === MenuSize.WIDE) dispatch(setMenuSize(MenuSize.NARROW));
  };
  const buttonLeft = menuSize === MenuSize.WIDE ? 191 : 53;
  const menuWidth = menuSize === MenuSize.WIDE ? 212 : 75;
  const ActiveLogo = menuSize === MenuSize.WIDE ? LogoNormal : LogoSmall;
  const logoWidth = menuSize === MenuSize.WIDE ? 142 : 53;
  const dashboardURL = '/main';
  return (
    <div className="side-panel" style={{ width: menuWidth }}>
      <Row justify={'center'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link to={dashboardURL}>
            <img src={ActiveLogo} width={logoWidth} />
          </Link>
          <img src={Beta} width={40} />
        </div>
        <SideMenu currentUser={currentUser} redirectPage={redirectPage} menuSize={menuSize} />
        <Button
          className="menu-size-button"
          icon={
            menuSize === MenuSize.WIDE ? (
              <LeftOutlined style={{ width: 10 }} />
            ) : (
              <RightOutlined style={{ width: 10 }} />
            )
          }
          style={{ position: 'absolute', top: 74, left: buttonLeft }}
          onClick={onMenuButtonClick}
        ></Button>
      </Row>
      <UserMenu menuSize={menuSize} />
    </div>
  );
};
