import { Card, CardProps } from 'antd';
import styled from 'styled-components';

interface StyledCardProps extends CardProps {
  nopadding?: boolean;
  fullsize?: boolean;
  width?: string;
  checked?: boolean;
  stopshadowbox?: boolean;
  height?: string;
  centercontent?: boolean;
  hideheader?: boolean;
  spreadcontent?: boolean;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  border: ${(props) => (props.borderless ? 'none' : '1px solid #dbeae3')};
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.stopShadowBox ? 'none' : '0px 0px 10px 0px #0000000d'};
  background-color: ${(props) => (props.bg_color ? props.bg_color : '#FFFFFF')};
  background: ${(props) => (props.bg ? props.bg : '')};

  .ant-switch-inner {
    background: #dbeae3 !important;
  }

  .ant-switch-handle::before {
    background: ${(props) => (props.checked ? '#41AAAF' : '#FFFFFF')}!important;
  }

  .switch-checked {
    .ant-switch-handle::before {
      background: #41aaaf !important;
    }
  }

  .ant-card-body {
    padding: ${(props) => (props.padding ? props.padding : '24px')}!important;
  }

  ${(props) =>
    props.fullsize &&
    `
      .ant-card-body {
        width:100%
      }
    `}
  ${(props) =>
    props.nopadding &&
    `
      .ant-card-body {
        padding:0 !important;
      }
    `}

    ${(props) =>
    props.centercontent &&
    `
      .ant-card-body {
        display: flex;
        justify-content: center;
      }
    `}
    
    ${(props) =>
    props.spreadcontent &&
    `
        .ant-card-body {
          display: flex;
          justify-content: space-between;
        }
      `}
    
    ${(props) =>
    props.hideheader &&
    `
        .ant-card-head {
          border-bottom: none;
          min-height: 20px !important;
        }
      `}

          ${(props) =>
    props.removeHeader &&
    `
        .ant-card-head {
          border-bottom: none;
          min-height: 0px !important;
        }
      `}
`;

export const GrayCard = styled(Card)`
  background-color: #f3f7f8;
  border-radius: 15px;
  padding: 0px 12px;

  ::after {
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    background-color: white;
    top: -1px;
    left: 93.5%;
    /*border-left: 8px solid;*/
    border-left-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    transform: translate(-50%, -50%) rotate(225deg);
    background-color: #f3f7f8;
  }
`;
